import React from 'react';
import NewWorkoutBlockGenerator from "fit/components/WorkoutBlockGenerator/WBG";

const CreateWorkoutBlock =()=>{
    return (
        <div>
            <NewWorkoutBlockGenerator/>
        </div>
    );
}

export default CreateWorkoutBlock;