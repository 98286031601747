import React from 'react';
import {useParams} from 'react-router-dom';
import ProfilePage from "fit/pages/shared/ProfilePage";

const ProfileRoute =()=>{
    let {userID} = useParams();
    console.log('PROFILE ROUTE',useParams());
    return (
        <ProfilePage userID={userID}/>
    )
}

export default ProfileRoute;