import React from 'react';
import {Grid} from '@mui/material';


class SoloFormWrapper extends React.Component{
    render(){
        return (
            <Grid container
                  spacing={0}
                  direction={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
            >
                <Grid item xs={1} md={4}>
                </Grid>
                <Grid item xs={10} md={4} style={{paddingTop: '4em'}}>
                    {this.props.children}
                </Grid>
                <Grid item xs={1} md={4}>
                </Grid>
            </Grid>
        );
    }
}


export default SoloFormWrapper;