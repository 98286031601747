import React from 'react';
import {Select, MenuItem, FormControl, InputLabel, FormHelperText} from '@mui/material';

const DynamicSelector=(props)=>{
	const {label, key, handleLiveFormChange, dataPending, value, helperText, options, variableName} = props;
	return(
		<FormControl>
			<Select
				key={key}
				name={variableName}
				onChange={handleLiveFormChange}
				disabled={dataPending}
				value={value}
				autoWidth={true}
				displayEmpty={true}
			>
			{
				Object.keys(options).map(key => (
					<MenuItem value={options[key][0]} key={key}>
						{options[key][1]}
					</MenuItem>
					)
				)
			}
			</Select>
			<FormHelperText>{label}</FormHelperText>
		</FormControl>
	);
}
export default DynamicSelector;