import React, {Suspense, useEffect, useState} from 'react';
import {IconButton} from "@mui/material";
import {connect} from 'react-redux';
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import {DrawerHeader} from "@jumbo/components/JumboLayout/style";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboSidebarTheme from "@jumbo/hooks/useJumboSidebarTheme";
import {SIDEBAR_STYLES, SIDEBAR_VIEWS} from "@jumbo/utils/constants/layout";
import Logo from "../../../../shared/Logo";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Zoom from "@mui/material/Zoom";
import Div from "@jumbo/shared/Div";
import SidebarSkeleton from "./SidebarSkeleton";
import {RoutePermissions} from "fit/system/PermissionsList";
import {PermissionMenuTranslator} from "fit/system/FitJumboTranslators";
import User from 'fit/system/User';
import {sidebarTheme as theme1} from "../../../../themes/sidebar/theme1";




const Sidebar = () => {

    const {sidebarOptions, setSidebarOptions} = useJumboLayoutSidebar();

    const user = new User();
    const deviceAccount = user.getDeviceAccount();
    const loggedIn = user.getLoggedInStatus();

    const {sidebarTheme, setSidebarTheme} = useJumboSidebarTheme();
    //const [loggedIn, setLoggedIn] = useState(user.getLoggedInStatus());
    //const [deviceAccount, setDeviceAccount] = useState(user.getDeviceAccount());
    const [shittyThemeSet, setShittyThemeSet] = useState(false);
    /*
    useEffect(()=>{
        const u =  new User();
        const loggedIn = u.getLoggedInStatus();
        const deviceAccount = u.getDeviceAccount();
        console.log('SIDEBAR', loggedIn, deviceAccount);
        if(loggedIn && deviceAccount === false && shittyThemeSet !== true){
            console.log('SET THE THEME BITCH');
            setSidebarTheme({
                ...sidebarTheme,
                overlay: {
                    // backgroundImage: `linear-gradient(${activeBgColorOption?.colors[0]}, ${activeBgColorOption?.colors[1]})`,
                    bgColor: ['#56a1d5','#212121'],
                    opacity: 0.85
                }
            })
            setShittyThemeSet(true);
        }
    }, loggedIn);
    */

    const newMenu = PermissionMenuTranslator(RoutePermissions);

    if(sidebarOptions.open && (loggedIn === false || (loggedIn && deviceAccount))){
        //Close the sidebar/menu
        setSidebarOptions({open: false});
    }


    return (
        <React.Fragment>
            <SidebarHeader/>
            <JumboScrollbar
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}
            >
                <Suspense
                    fallback={
                        <Div
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                alignContent: 'center',
                                px: 3
                            }}
                        >
                            <SidebarSkeleton/>
                        </Div>
                    }
                >
                    <JumboVerticalNavbar translate items={newMenu}/>
                </Suspense>
            </JumboScrollbar>
        </React.Fragment>
    );
};

const SidebarHeader = () => {
    const {sidebarOptions, setSidebarOptions} = useJumboLayoutSidebar();
    const {sidebarTheme, setSidebarTheme} = useJumboSidebarTheme();

    const isMiniAndClosed = React.useMemo(() => {
        return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
    }, [sidebarOptions.view, sidebarOptions.open]);

    return (
        <React.Fragment>
            {
                sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
                <DrawerHeader>
                    <Logo mini={isMiniAndClosed} mode={sidebarTheme.type}/>
                    {
                        sidebarOptions?.view !== SIDEBAR_VIEWS.MINI &&
                        <Zoom in={sidebarOptions?.open}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{ml: 0, mr: -1.5}}
                                onClick={() => setSidebarOptions({open: false})}
                            >
                                <MenuOpenIcon/>
                            </IconButton>
                        </Zoom>
                    }
                </DrawerHeader>
            }
        </React.Fragment>
    )
};

const mapStateToProps =(state)=>{
    return {FITUser : state.FITUser}
}

export default connect(mapStateToProps)(Sidebar);
