import React from 'react';
import {Link} from 'react-router-dom'
import {
	TextField,
	Button,
	CircularProgress,
	Collapse,
	InputAdornment,
	Tooltip,
	IconButton,
	Paper,
	Grid,
	Typography
} from '@mui/material';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import SecureConnect from 'fit/system/SecureConnect';
import {getStatusByCode, setPageTitle, isEmpty} from 'fit/system/UtilityFunctions';
import {PAPER_STYLE} from "fit/system/FITConstants";
import Alert from 'fit/components/Alert/'


class ResetPassword extends React.Component{
	state = {
		email: '',
		password: '',
		passwordMatch: '',
		submitDisabled: false, //Allow someone to press the button after it's already been pressed.
		validResetRequest: false, //Determines whether to display reset form or password form
		validToken: '', //Token that gets set from pass reset email
		formMessageHeadline: '',
		formMessage: '', //Form message taken from server response to update interface
		responseCode: '', //Response code from the server for returning messages/updating the forms
		passwordVisible: false, //make the password visible
	};
	getResetToken(){
		const p = this.props;
		return !isEmpty(p) && (p.resetKey != null && p.resetKey !== '') ? p.resetKey : false;
	}
	toggleVisibility(){
		const newSetting = !this.state.passwordVisible;
		this.setState({passwordVisible: newSetting});
	}

	componentDidMount(){
		setPageTitle('Reset Your Password');
		const resetToken = this.getResetToken();
		if(resetToken === false){
			return;
		}
		const url = `system.php?action=checkPasswordResetRequest&token=${resetToken}`;
		let sc = new SecureConnect(url);
		sc.setDisplayNotifications(false);
		sc.connect().then(json => {
			if (sc.getCompleted(json)) {
				//Token is valid - set passwordResetRequest
				this.setState({
					validResetRequest: true,
					validToken: resetToken,
					responseCode: sc.getResponseCode(json)
				})
			}
		});
	}
	requestPasswordReset(){
		let sc = new SecureConnect('system.php','post');
		sc.setAction('requestPasswordReset');
		sc.setFormData({email: this.state.email});
		sc.setDisplayNotifications(true);
		sc.setDisplaySuccessMessages(true);
		sc.connect().then(json =>{
			if(sc.getCompleted(json)){
				const response = sc.getResponse(json);
				//Set SuccessMessage
				this.setAlert(response.code,'Email Sent',response.msg,true);
			}
		});
	}
	setAlert(code,headline,message,submitDisabled = false){
		this.setState({
			responseCode: code,
			formMessageHeadline: headline,
			formMessage: message,
			submitDisabled: submitDisabled
		})
	}
	verifyPassword(){
		const passMatch = this.state.password === this.state.passwordMatch;
		if(!passMatch){
			this.setAlert(3,'Password mistyped?','Your new passwords don\'t match.',false);
			return;
		}

		let sc = new SecureConnect('system.php','post');
		sc.setAction('resetPassword');
		sc.setFormData({token: this.state.validToken, password: this.state.password});
		sc.connect().then(json =>{
			const response = sc.getResponse(json);
			let headline = 'Password Reset';
			let submitDisabled = true;
			let formMessage = '';
			if(sc.getCompleted(json)){
				//Password reset. Update the message
				const link = <Link to={`/login/`}>Log into your account</Link>;
				formMessage = <span>Success! You've updated your password. {link} now.</span>;
				//Update session storage - will tell sign-in to unset user information
				sessionStorage.setItem('resetPasswordComplete', 1);
			} else{
				headline = 'Whoops!';
				formMessage = response.msg;
				submitDisabled = false;
			}

			this.setAlert(response.code, headline, formMessage, submitDisabled);
		});

	}
	handleChange(e){
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		this.setState({[name]: value});
	}
	renderFormMessage(){
		//Create a static message for the user above the form
		//Success/error messages to provide more feedback for the user
		const status = getStatusByCode(this.state.responseCode);
		const {formMessageHeadline, formMessage} = this.state;
		const displayAlert = formMessage === '' ? false : true;
		return (
			<Alert
				headline={formMessageHeadline}
			    status={status}
			    message={formMessage}
			    visible={displayAlert}
			/>
		)
	}
	renderEmailForm(){
		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						label="Your Email"
						fullWidth
						name="email"
						onChange={this.handleChange.bind(this)}
						defaultValue={this.state.email}
						margin="normal"
						className="mt-1 my-sm-3"
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						fullWidth
						onClick={this.requestPasswordReset.bind(this)}
						variant="contained" color="primary"
						disabled={this.state.submitDisabled}
					>
						Request New Password
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Link to="/login/">
						Log In
					</Link>
				</Grid>
			</Grid>
		)
	}


	renderResetForm(){
		const s = this.state;
		const type = s.passwordVisible ? 'text' : 'password';
		const title = s.passwordVisible ? 'Hide Password' : 'Show Password';
		const icon = s.passwordVisible ? <VisibilityOff/> : <Visibility/>;
		const viewButton =
			<InputAdornment position={'end'}>
				<Tooltip title={title} enterDelay={300} placement={'bottom-start'}>
					<IconButton color={'primary'} onClick={this.toggleVisibility.bind(this)}>
						{icon}
					</IconButton>
				</Tooltip>
			</InputAdornment>;
		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						type={type}
						label="Your New Password"
						fullWidth
						name="password"
						value={this.state.password}
						onChange={this.handleChange.bind(this)}
						margin="normal"
						className="mt-1 my-sm-3"
						InputProps={{
							endAdornment: viewButton,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						type={type}
						label="Confirm Password"
						fullWidth
						name="passwordMatch"
						value={this.state.passwordMatch}
						onChange={this.handleChange.bind(this)}
						margin="normal"
						className="mt-1 my-sm-3"
						InputProps={{
							endAdornment: viewButton,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						fullWidth
						onClick={this.verifyPassword.bind(this)}
						variant="contained"
						color="primary"
					>
						Reset Password
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Link to="/login/">
						Log In
					</Link>
				</Grid>
			</Grid>
		)
	}
	render(){
		const {loader} = this.props;
		const s = this.state;
		const displayForm = s.validResetRequest === false ? this.renderEmailForm() : this.renderResetForm();
		const formMessage = this.renderFormMessage();
		const testStyle = {
			width: '100%',
			background: '#fff'
		}
		return (
			<Paper style={PAPER_STYLE}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant={'h1'}>Reset Your Password</Typography>
					</Grid>
					<Grid item xs={12}>
						{displayForm}
					</Grid>
				</Grid>
			</Paper>
		);

		{/*
		return (
			<div
				className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
				<div className="app-login-main-content">
					<div className="app-logo-content d-flex align-items-center justify-content-center">
						[no link to AIT]
					</div>
					<div className="app-login-content">
						<div className="app-login-header mb-4">
							<h1>Reset Your Password</h1>
						</div>
						<Collapse in={s.validResetRequest === true}>
							Your new password must meet the following requirements:
							<ul>
								<li>Minimum of <strong>12</strong> characters</li>
								<li><strong>1</strong> Upper case letter</li>
								<li><strong>1</strong> Lower case letter</li>
								<li><strong>1</strong> Number</li>
								<li><strong>1</strong> Special character</li>
							</ul>
						</Collapse>
						{formMessage}
						<div className="app-login-form">
							<form>
								{displayForm}
							</form>
						</div>
					</div>

				</div>
				{
					loader &&
					<div className="loader-view">
						{<CircularProgress/>}
					</div>
				}
			</div>
		);
		*/}
	}
}

export default ResetPassword;