import React from 'react';
import {Table, TableRow, TableCell} from "@mui/material";
import {List, ListItem, ListItemIcon, ListItemText, FormControl} from "@mui/material";
import {Checkbox, FormControlLabel} from "@mui/material";
import {Link, Redirect} from 'react-router-dom';
import {Tooltip, IconButton, Button} from '@mui/material';
import {Pending, Whatshot, PanoramaFishEye, CheckCircle, Edit, Info, Search, Warning, Add, PersonAdd, PersonAddDisabled, TimeToLeave, Stars, CreditCard, DoneOutline, Cancel, AccountBalance, AirplanemodeActive, Remove} from "@mui/icons-material";
import {Speaker, CameraAlt, LocalDining, AccountCircle, Hotel, Lock} from '@mui/icons-material'; //Customer Payment List, ActorShowList
import {getStatusColor, getSpacing, getStatusByCode, isEmpty, getStatusIcon, getCastPendingIcon} from 'fit/system/UtilityFunctions';
import Popover from "@mui/material/Popover";
import moment from "moment";
import User from 'fit/system/User';
import {PRIVATE_DEPT_ID, ACCOUNTING_DEPT_ID, ACTOR_DEPT_ID, DIRECTOR_LEVEL, USA} from "fit/system/FITConstants";
import {
	getUnspecifiedPaymentLabelStyle,
	getUnspecifiedScheduleLabelStyle,
	getUnspecifiedPaymentIcon,
	getPaymentWarningIcon,
	getProcessPaymentButton,
	getViewTransactionButton,
	getEditPaymentsButton,
} from './RowDisplayFunctions';
const toolTipDelay = 300; //ms before display tooltip
const toolTipPlacement = 'bottom-start';
const fadedOpacity = .55;

export const getRowComponent =(component)=>{
	const rowComponents = {
		'NoData' : NoData,
		'ActiveWorkoutAthleteSelectList': ActiveWorkoutAthleteSelectList,
		'DeptUserList' : DeptUserList,
		'DeviceList':DeviceList,
		'DeviceSelectionList' : DeviceSelectionList,
		'ExerciseList' : ExerciseList,
		'GodTierUserList' : GodTierUserList,
		'TeamList': TeamList,
		'UserHistoryList' : UserHistoryList,
		'WorkoutBlockList' : WorkoutBlockList,
		'WorkoutList' : WorkoutList,
	};
	return rowComponents[component]
};

export class NoData extends React.Component{
	render(){
		const {colSpan, cellText} = this.props;
		return (
			<TableRow hover>
				<TableCell
					colSpan={colSpan}
					align={'center'}
				>
					<em>{cellText}</em>
				</TableCell>
			</TableRow>
		);
	}
}

export class ActiveWorkoutAthleteSelectList extends React.Component{
	render(){
		const d = this.props.rowData;
		const rf = this.props.rowFunctions;
		return (
			<TableRow hover>
				<TableCell>
					<FormControlLabel
						control={
							<Checkbox
								onChange={rf.toggleAthlete.bind(this, d.userID)}
								color={'primary'}
							/>
						}
						label={`${d.firstName} ${d.lastName}`}
					/>
				</TableCell>
				<TableCell>{d.positionStatus}</TableCell>
				<TableCell>{d.yearStatus}</TableCell>
				<TableCell>{d.sexStatus}</TableCell>
			</TableRow>
		)
	}
}
export class DeptUserList extends React.Component{
	render(){
		const d = this.props.rowData;
		const userLink = getProfileLink(d.user_ID);
		const active = parseInt(d.active) === 1 && parseInt(d.deptActive) === 1;
		const opacity = active ? 1: fadedOpacity;
		const fadeStyle = {opacity};
		return (
			<TableRow hover>
				<TableCell><Link to={userLink}>{d.user_ID}</Link></TableCell>
				<TableCell style={fadeStyle}><Link to={userLink}>{d.firstName} {d.lastName}</Link></TableCell>
				<TableCell style={fadeStyle}>{d.email}</TableCell>
				<TableCell style={fadeStyle}>{d.phone}</TableCell>
				<TableCell style={fadeStyle}>{d.lastLogin}</TableCell>
			</TableRow>
		);
	}
}
export class DeviceList extends React.Component{
	render(){
		const d = this.props.rowData;
		const assigned = parseInt(d.deviceAssigned) === 1;
		const iconStyle ={position: 'relative', top: getSpacing('iconAlignment')};
		const successStyle = {color: getStatusColor('success')};
		const icon = assigned ? <CheckCircle style={successStyle}/> : <PanoramaFishEye/>;
		//const {selectDevice, prepPullWorkout, prepPushWorkout} = this.props;
		const assignedText = assigned ? 'Active' : 'Ready';
		const ttMessage = assigned ? 'Deactivate Workout' : 'Activate Workout';
		const dateLink = assigned ? <Link to={`/strength-and-conditioning/schedule/${d.dateScheduledISO}/`}>{d.dateScheduled}</Link> : '-';
		const teamLink = assigned ? <Link to={`/strength-and-conditioning/teams/${d.teamID}/`}>{d.teamName}</Link> : '-';
		const workoutLink = assigned ? <Link to={`/strength-and-conditioning/workouts/${d.workoutID}/`}>{d.blockTitle}: Day {d.dayIndex+1}</Link> : '-';
		const ownerLink = assigned ? <Link to={`/profiles/${d.ownerID}/`}>{d.ownerName}</Link> : '-';
		return (
			<TableRow hover>
				<TableCell>
					<Link to={`/profiles/${d.userID}/`}>{d.firstName} {d.lastName}</Link>
				</TableCell>
				<TableCell>
					<Tooltip title={ttMessage} placement={'bottom'}>
						<IconButton>
							{icon}
						</IconButton>
					</Tooltip>
					{assignedText}
				</TableCell>
				<TableCell>
					{dateLink}
				</TableCell>
				<TableCell>
					{teamLink}
				</TableCell>
				<TableCell>
					{workoutLink}
				</TableCell>
				<TableCell>
					{ownerLink}
				</TableCell>

			</TableRow>
		)
	}
}
export class DeviceSelectionList extends React.Component{
	render(){
		const {assigned, blockTitle, dayIndex, firstName, lastName, teamName, selectedDeviceIDs, userID, ownerName, ownerID} = this.props;
		const {toggleSelectedDevice} = this.props;
		const iconStyle = {position: 'relative', top: getSpacing('iconAlignment')}
		const successStyle = {color: getStatusColor('success'),...iconStyle}
		const icon = assigned ? <CheckCircle style={successStyle} /> : <PanoramaFishEye style={iconStyle}/>
		const deviceStatus = assigned ? 'Active' : 'Ready';
		const workoutName = assigned ? `${blockTitle} Day: ${dayIndex+1}` : '-';
		const teamText = assigned ? teamName : '-';
		const coachName = assigned ? <Link to={`/profiles/${ownerID}/`}>{ownerName}</Link> : '-';
		return (
			<TableRow>
				<TableCell>
					<Checkbox onChange={toggleSelectedDevice} checked={selectedDeviceIDs.includes(userID)}/>
				</TableCell>
				<TableCell><Link to={`/profiles/${userID}/`}>{firstName} {lastName}</Link></TableCell>
				<TableCell>{icon} {deviceStatus}</TableCell>
				<TableCell>{teamText}</TableCell>
				<TableCell>{workoutName}</TableCell>
				<TableCell>{coachName}</TableCell>
			</TableRow>
		)
	}
}
export class ExerciseList extends React.Component{
	render(){
		const d = this.props.rowData;
		const {loadExercise} = this.props.rowFunctions;
		const iconStyle = {marginTop: getSpacing('iconAlignment'), position: 'relative'};
		const successStyle ={...iconStyle, color: getStatusColor('success')};
		const noActionStyle ={...iconStyle, color: getStatusColor('noAction')};
		const statusMessage = parseInt(d.active) === 1 ? 'Active' :'Inactive';
		const statusIcon = parseInt(d.active) === 1 ? <CheckCircle style={successStyle}/> : <PanoramaFishEye style={noActionStyle}/>
		const recordData = parseInt(d.recordWeightDefault) === 1;
		const recordDataMessage = recordData ? 'Recording Athlete Data' : 'Not Capturing Data';
		const recordIcon = recordData ? <CheckCircle style={successStyle}/> : <PanoramaFishEye style={noActionStyle}/>
		const {name, shortName, exerciseStyle, measurementType, measurementUnit} = d;
		return (
			<TableRow hover>
				<TableCell>
					<Tooltip title={`Edit ${name}`} placement={'bottom'}>
						<IconButton
							color={'primary'}
							onClick={()=>loadExercise(d)}
						><Edit/></IconButton>
					</Tooltip>
					{name}
				</TableCell>
				<TableCell>{shortName}</TableCell>
				<TableCell>
					<Tooltip title={statusMessage} placement={'bottom'}>{statusIcon}</Tooltip>
				</TableCell>
				<TableCell>
					<Tooltip title={recordDataMessage} placement={'bottom'}>{recordIcon}</Tooltip>
				</TableCell>
				<TableCell>{exerciseStyle}</TableCell>
				<TableCell>{measurementType}</TableCell>
				<TableCell>{measurementUnit}</TableCell>
			</TableRow>
		);
	}
}

export class GodTierUserList extends React.Component{
	render(){
		const d = this.props.rowData;
		let actionCell = null;
		if(User.checkCoreSuperUser()){
			actionCell =
				<TableCell>
					<Tooltip title={'Remove Super User Status'} placement={toolTipPlacement} enterDelay={toolTipDelay}>
						<IconButton
							onClick={()=>this.props.rowFunctions.displayRemovalConfirmation(d.user_ID, d.name)}
						>
							<Cancel style={{color: getStatusColor('error')}}/>
						</IconButton>
					</Tooltip>
				</TableCell>
		}
		return (
			<TableRow>

				<TableCell><Link to={`/profile/${d.user_ID}/`}>{d.name}</Link></TableCell>
				<TableCell>{d.email}</TableCell>
				<TableCell>{d.phone}</TableCell>
				<TableCell>{d.lastLogin}</TableCell>
				{/*
					Action Cell for Removing other super users
					Limited to Core Super Users: Hardcoded in User
				*/}
				{actionCell}
			</TableRow>
		)
	}
}
export class TeamList extends React.Component{
	render(){
		const d = this.props.rowData;
		return (
			<TableRow hover>
				<TableCell><Link to={`/profiles/${d.userID}/`}>{d.firstName} {d.lastName}</Link></TableCell>
				<TableCell>{d.organizationShortLabel} {d.teamName}</TableCell>
				<TableCell>{d.positionStatus}</TableCell>
				<TableCell>{d.sexStatus}</TableCell>
				<TableCell>{d.yearStatus}</TableCell>
			</TableRow>
		)
	}
}
export class UserHistoryList extends React.Component{
	renderNavigationLinks(apiAction, key, value){
		//Render the Link/Key/Setting for a specific column
		//depends on apiAction and how the record is recorded
		const actions = [
			{key: 'getActorTrophies', primary: 'userID'},
			{key: 'getTroupe', primary: 'troupeID'},
			{key: 'getZip', primary: 'zip'},
			{key: 'updateThemeColor', primary: 'themeColorID (basic labels)'},
			{key: 'getCastingConflict', primary: 'conflictID'},
			{key: 'processCastingConflict', primary: 'conflictID'},
			{key: 'getNonCompeteSignature', primary: 'userID'},
			{key: 'getShowPayments', primary: 'userID'},
			{key: 'getLeadAssignments', primary: 'userID'},
			{key: 'getProfile', primary: 'userID'},
			{key: 'getTroupeCastable', primary: 'troupeID'},
			{key: 'getFullTroupeList', primary: 'troupeID'},
			{key: 'toggleSalesCommissions', primary: 'userID', secondary: '_toggle'},
			{key: 'toggleDD', primary: 'userID', secondary: '_toggle'},
			{key: 'toggleDetectiveRole', primary: 'userID', secondary: '_toggle'},
			{key: 'toggleShowCaptain', primary: 'userID', secondary: '_toggle'},
			{key: 'togglePhoneConfirmation', primary: 'userID', secondary: '_toggle'},
			{key: 'togglePrincessRole', primary: 'userID', secondary: '_toggle'},
			{key: 'toggleW2', primary: 'userID', secondary: '_toggle'},
			{key: 'toggleW9', primary: 'userID', secondary: '_toggle'},
			{key: 'updateUserCommissionSchedule', primary: 'userID', secondary: 'scheduleID (basic_label)'},
			{key: 'verifyRoutingNumber'},
			{key: 'createLeadAssignment', primary: 'companyID', secondary: '_toggle'},
			{key: 'createUser', primary: 'userID'},
			{key: 'editLeadAssignment', primary: 'companyID', secondary: '_toggle'},
			{key: 'editUserDetails', primary: 'userID'},
			{key: 'editUserDeptPermissions', primary: 'userID'},
			{key: 'editUserTroupeAssignments', primary: 'userID'},
			{key: 'updateShowCountPayRates', primary: 'userID'},
			{key: 'getActorDocument', primary: 'userID'},
			{key: 'getActiveActorPayments', primary: 'userID'},
			{key: 'getActorPaymentDetails', primary: 'userID', secondary: 'showID'},
			{key: 'getPublicVenue', primary: 'venueID'},
			{key: 'processActorDocument', primary: 'userID'},
			{key: 'processActorPay', primary: 'userID'},
			{key: 'getThemeReport', primary: 'troupeID', secondary: 'companyID'},
			{key: 'getTravelPay', primary: 'troupeID', secondary: 'payPeriodID'},
			{key: 'getTravelLogistics', primary: 'showID'},
			{key: 'getTroupeProfitabilityReport', primary: 'troupeID'},
			{key: 'toggleTravelSubmitted', primary: 'showID', secondary: '_toggle'},
			{key: 'createTroupe', primary: 'troupeID'},
			{key: 'editTroupe', primary: 'troupeID'},
			{key: 'setCarRental', primary: 'showID'},
			{key: 'setFlight', primary: 'showID'},
			{key: 'setHotelRental', primary: 'showID'},
			{key: 'submitActorTravelPay', primary: 'showID'},
			{key: 'getInternalSurveyForm', primary: 'surveyID'},
			{key: 'getSurvey', primary: 'surveyID'},
			{key: 'getSurveyResponse', primary: 'surveyResponseID'},
			{key: 'toggleQuestionSetting', primary: 'questionID', secondary: '_toggle'},
			{key: 'toggleSurveyActive', primary: 'surveyID', secondary: '_toggle'},
			{key: 'createSurvey', primary: 'surveyID'},
			{key: 'editSurveyName', primary: 'surveyID'},
			{key: 'createQuestion', primary: 'surveyID', secondary: 'questionID'},
			{key: 'removeQuestion', primary: 'surveyID', secondary: 'questionID'},
			{key: 'submitActorSurvey', primary: 'surveyID', secondary: 'troupeID'},
			{key: 'submitInternalSurvey', primary: 'surveyID', secondary: 'troupeID'},
			{key: 'takeLead', primary: 'customerID'},
			{key: 'createCustomer', primary: 'customerID'},
			{key: 'createCustomerNote', primary: 'customerID', secondary: 'showID'},
			{key: 'editCustomer', primary: 'customerID'},
			{key: 'previewEmail', primary: 'customerID'},
			{key: 'sendEmail', primary: 'customerID'},
			{key: 'submitAtShowLead', primary: 'customerID'},
			{key: 'getCustomer', primary: 'customerID'},
			{key: 'getCustomerNotes', primary: 'customerID'},
			{key: 'getEmailForm', primary: 'customerID'},
			{key: 'getNewCustomer', primary: 'customerID'},
			{key: 'markCustomerDead', primary: 'customerID'},
			{key: 'markCustomerDonationRequest', primary: 'customerID'},
			{key: 'markCustomerPublicLead', primary: 'customerID'},
			{key: 'markCustomerSpam', primary: 'customerID'},
			{key: 'markCustomerValidLead', primary: 'customerID'},
			{key: 'markNotificationCompleted', primary: 'customerID', secondary: 'notificationID'},
			{key: 'removeCustomerHeat', primary: 'customerID'},
			{key: 'replacePrimary', primary: 'customerID', secondary: 'userID'},
			{key: 'addSecondaryRep', primary: 'customerID', secondary: 'userID'},
			{key: 'removeSecondaryRep', primary: 'customerID', secondary: 'userID'},
			{key: 'getDirectorHours', primary: 'troupeID'},
			{key: 'getHistoricalHours', primary: 'payPeriodID'},
			{key: 'getTemporaryDirector', primary: 'userID'},
			{key: 'editHourType', primary: 'id (hour type ID)'},
			{key: 'requestAdditionalHours', primary: 'userID'},
			{key: 'processAdditionalHoursRequest', primary: 'requestID'},
			{key: 'getCommissionsTable', primary: 'userID'},
			{key: 'getCompanyPointPackageCosts', primary: 'companyID'},
			{key: 'getCoPointsAdditionalTable', primary: 'userID'},
			{key: 'getCoPointsHistory', primary: 'userID'},
			{key: 'getCoPointsTable', primary: 'userID'},
			{key: 'getPendingEntry', primary: 'showID'},
			{key: 'getPointsAdditionalByID', primary: 'pointsID'},
			{key: 'getShowPointsLog', primary: 'showID'},
			{key: 'getShowTypeCosts', primary: 'companyID'},
			{key: 'applyCompanyFixedCost', primary: 'showTypeID', secondary: 'costLabelID'},
			{key: 'assignCompanyCost', primary: 'companyID', secondary: 'costLabelID'},
			{key: 'reviewPointEntry', primary: 'showID'},
			{key: 'submitAdditionalPoints', primary: 'userID'},
			{key: 'updateRepFlatCommission', primary: 'showTypeID'},
			{key: 'createActorPaymentDispute', primary: 'userID'},
			{key: 'createShowPayment', primary: 'showID'},
			{key: 'deleteShowPayment', primary: 'showID'},
			{key: 'directorDepositSubmission', primary: 'troupeID'},
			{key: 'editPaymentSchedule', primary: 'showID', secondary: 'scheduleTypeID'},
			{key: 'editPhotoSales', primary: 'showID'},
			{key: 'editShowPayment', primary: 'showID', secondary: 'paymentID'},
			{key: 'getActorPaymentDispute', primary: 'disputeID'},
			{key: 'getActorPaymentDisputeList', primary: 'userID'},
			{key: 'getActorShowPaymentDetails', primary: 'userID', secondary: 'showID'},
			{key: 'getDirectorDepositSubmission', primary: 'depositID'},
			{key: 'getDirectorPaymentDetails', primary: 'userID', secondary: 'payPeriodID'},
			{key: 'getDirectorPaymentList', primary: 'userID'},
			{key: 'getEditPaymentDetails', primary: 'showID'},
			{key: 'getPayInFullFormData', primary: 'showID'},
			{key: 'getPaymentFormData', primary: 'paymentID'},
			{key: 'getPhotoShowSales', primary: 'showID'},
			{key: 'getShowPaymentList', primary: 'showID'},
			{key: 'getShowRefund', primary: 'refundID'},
			{key: 'logCreditCardEntry', primary: 'customerID', secondary: 'showID'},
			{key: 'markPaid', primary: 'showID', secondary: 'paymentID'},
			{key: 'processShowRefund', primary: 'showID', secondary: 'refundID'},
			{key: 'requestRefund', primary: 'showID', secondary: 'paymentID'},
			{key: 'resolveActorPaymentDispute', primary: 'disputeID', secondary: '_toggle'},
			{key: 'submitAutomaticPayment', primary: 'showID', secondary: 'paymentID'},
			{key: 'submitManualPayment', primary: 'customerID', secondary: 'showID', tertiary: 'paymentID'},
			{key: 'submitPhotoSales', primary: 'showID'},
			{key: 'getShow', primary: 'showID'},
			{key: 'getShowCasting', primary: 'showID'},
			{key: 'getShowKarmaSignature', primary: 'showID'},
			{key: 'getShowForEditing', primary: 'showID'},
			{key: 'getShowReviewData', primary: 'showID'},
			{key: 'getShowReviewLog', primary: 'showID'},
			{key: 'markShowItemReviewed', primary: 'showID', secondary: 'itemID'},
			{key: 'getCastRequestList', primary: 'showID'},
			{key: 'getPastShows', primary: 'userID'},
			{key: 'getShowSurveyResponse', primary: 'showID'},
			{key: 'getSignedContract', primary: 'showID'},
			{key: 'getTravelRequestForm', primary: 'showID'},
			{key: 'requestCasting', primary: 'showID'},
			{key: 'removeCastingRequest', primary: 'showID'},
			{key: 'castActor', primary: 'showID', secondary: 'userID'},
			{key: 'castActorDirectly', primary: 'showID', secondary: 'userID'},
			{key: 'removeActor', primary: 'showID', secondary: 'userID'},
			{key: 'setActorRole', primary: 'showID', secondary: 'userID'},
			{key: 'removeActorRole', primary: 'showID', secondary: 'userID'},
			{key: 'setShowCaptain', primary: 'showID', secondary: 'userID'},
			{key: 'setDriver', primary: 'showID', secondary: 'userID'},
			{key: 'toggleActorOpen', primary: 'showID', secondary: '_toggle'},
			{key: 'toggleDriverOverride', primary: 'showID', secondary: '_toggle'},
			{key: 'updateDriverCount', primary: 'showID'},
			{key: 'actorUpdateShow', primary: 'showID'},
			{key: 'applyFlatShowPay', primary: 'showID'},
			{key: 'createRehearsal', primary: 'showID'},
			{key: 'createShow', primary: 'showID'},
			{key: 'editShow', primary: 'showID'},
			{key: 'showPriceChangedUpdatePayments', primary: 'showID'},
			{key: 'signShowKarma', primary: 'showID'},
			{key: 'submitTravelRequest', primary: 'showID'},
			{key: 'editUserDeptPermissions', primary: 'userID'},
			{key: 'editUserTroupeAssignments', primary: 'userID'},
			{key: 'toggleGodTierUser', primary: 'userID', secondary: '_toggle'},
			//REMOTE Actions
			//Requests
			{key: 'getContract', primary: 'customerID', secondary: 'showID'},
			{key: 'getQuestionnaire', primary: 'customerID', secondary: 'showID'},
			{key: 'getShowPayment', primary: 'customerID', secondary: 'showID', tertiary: 'paymentID'},
			{key: 'getShowSurvey', primary: 'showID'},

			//Submissions
			{key: 'formstackSubmissionDirectDeposit', primary: 'userID'},
			{key: 'formstackSubmissionW9', primary: 'userID'},
			{key: 'newCustomer', primary: 'customerID'},
			{key: 'submitContract', primary: 'customerID', secondary: 'showID'},
			{key: 'submitQuestionnaire', primary: 'customerID', secondary: 'showID'},
			{key: 'submitPayment', primary: 'customerID', secondary: 'showID', tertiary: 'paymentID'},
			{key: 'submitSurvey', primary: 'showID'},


		];
		const filtered = actions.filter(item => item.key === apiAction);
		if(isEmpty(filtered) || filtered[0][key] === null){
			//Nothing found - return value
			return ''
		}
		const columnKey = filtered[0][key];
		if(parseInt(value) === 0 && filtered[0][key] == null){
			//Nothing there-
			return '';
		}
		switch(columnKey){
			case 'customerID':
				return parseInt(value) > 0 ?
					<Link to={`/customer/${value}/`}>
						<Button	color={'primary'}>Customer #{value}</Button>
					</Link>
					: '';
			case 'paymentID':
				return parseInt(value) > 0 ?
					<Button color={'primary'} onClick={(e)=>{e.preventDefault(); this.props.rowFunctions.viewPayment(value)}}>Payment #{value}</Button>
					: '';
			case 'showID':
				return parseInt(value) > 0 ?
					<Link to={`/show/${value}/`}>
						<Button color={'primary'}>Show #{value}</Button>
					</Link>
					: '';
			case 'userID':
				return parseInt(value) > 0 ?
					<Link to={`/profile/${value}/`}>
						<Button color={'primary'}>User #{value}</Button>
					</Link>
					: '';
			case 'venueID':
				return parseInt(value) > 0 ?
					<Link to={`/finance/public-venues/${value}/`}>
						<Button color={'primary'}>Venue #{value}</Button>
					</Link>
					: '';
			case '_toggle':
				return parseInt(value) === 1 ? 'On/Active' : 'Off/Disabled';
			default:
				return value;
		}
	}
	createStatusIcon(status, title){
		const color = status ? getStatusColor('success') : getStatusColor('error');
		const style = {color};
		const icon = status ? <CheckCircle style={style}/> : <Cancel style={style}/>;
		return (
			<Tooltip title={title} placement={toolTipPlacement} enterDelay={toolTipDelay}>
				{icon}
			</Tooltip>
		);
	}
	checkStatus(value){
		return parseInt(value) === 1;
	}
	render(){
		const d = this.props.rowData;
		//Determine Statues
		const completed = this.checkStatus(d.completed);
		const validated = this.checkStatus(d.validated);
		const permitted = this.checkStatus(d.permitted);
		//Create Text
		const completedText = completed ? 'Action Completed' :'Action Failed';
		const validatedText = validated ? 'Input Validated' : 'Validation Failed';
		const permittedText = permitted ? 'User Permitted' : 'Permission Check Failed';
		//Build the status icons
		const permittedStatus = this.createStatusIcon(permitted, permittedText);
		const validatedStatus = this.createStatusIcon(validated, validatedText);
		const completedStatus = this.createStatusIcon(completed, completedText);



		//Build columns
		const primary = this.renderNavigationLinks(d.apiAction, 'primary', d.primary_ID);
		const secondary = this.renderNavigationLinks(d.apiAction, 'secondary', d.secondary_ID);
		const tertiary = this.renderNavigationLinks(d.apiAction, 'tertiary', d.tertiary_ID);
		//Set Address
		const address = d.address.length > 15 ? `${d.address.substr(0,15)}...` : d.address;
		const remoteUser = d.user_ID === '_';
		const name = !remoteUser ? <Link to={`/profile/${d.user_ID}/`}>{d.name}</Link> : <strong>{d.name}</strong>;
		const cellStyle={paddingRight: 0};
		const iconCell={width: '8% !important', paddingRight: 0};
		const actionCell={width: '13%', paddingRight: 0};
		const dateCell={width: '10%', paddingRight: 0};
		return (
			<TableRow hover>
				<TableCell style={iconCell}>
					<Tooltip title={'View Event Details'} placement={toolTipPlacement} enterDelay={toolTipDelay}>
						<IconButton
							color={'primary'}
							onClick={()=>this.props.rowFunctions.viewEvent(d.eventID)}
						><Search/>
						</IconButton>
					</Tooltip>
				</TableCell>
				<TableCell style={cellStyle}>
					{name}
					<br/>{address}
				</TableCell>
				<TableCell style={actionCell}>{d.action}</TableCell>
				<TableCell style={dateCell}>{d.dateTime}</TableCell>
				<TableCell style={iconCell}>{permittedStatus}</TableCell>
				<TableCell style={iconCell}>{validatedStatus}</TableCell>
				<TableCell style={iconCell}>{completedStatus}</TableCell>
				<TableCell style={cellStyle}>{primary}</TableCell>
				<TableCell style={cellStyle}>{secondary}</TableCell>
				<TableCell style={cellStyle}>{tertiary}</TableCell>
			</TableRow>
		)
	}
}
export class WorkoutBlockList extends React.Component{
	renderToolTipIcon(icon, message){
		return (
			<Tooltip title={message} position={'bottom'}>
				{icon}
			</Tooltip>
		)
	}
	render(){
		const d = this.props.rowData;
		const {error, warning, status} = d;
		const inactiveStyle ={color: getStatusColor('error')};
		const defaultStyle = {position: 'relative', top: getSpacing('iconAlignment')};
		const successStyle = {color: getStatusColor('success'), ...defaultStyle};
		const errorStyle = {color: getStatusColor('error'), ...defaultStyle};
		const warningStyle = {color: getStatusColor('warning'), ...defaultStyle}
		const errorIcon = error === 1 ? this.renderToolTipIcon(getStatusIcon('error', errorStyle), 'Has Errors') : null;
		const warningIcon = warning === 1 ? this.renderToolTipIcon(getStatusIcon('warning', warningStyle), 'Has Warnings') : null;
		const successIcon = error !== 1 && warning !== 1 ? this.renderToolTipIcon(getStatusIcon('success', successStyle), 'Validated') : null;
		const statuses = {
			0: 'Inactive',
			1: 'Active',
			2: 'Completed'
		}
		const statusText = status === 0? <span style={inactiveStyle}>{statuses[status]}</span> : statuses[status];
		return(
			<TableRow hover>
				<TableCell>
					{errorIcon}{warningIcon}{successIcon} {statusText}
				</TableCell>
				<TableCell>
					<Link to={`/strength-and-conditioning/workout-blocks/${d.workoutBlockID}/`}>
						{d.title}
					</Link>
				</TableCell>
				<TableCell>
					<Link to={`/profiles/${d.userID}/`}>
						{d.firstName} {d.lastName}
					</Link>
				</TableCell>
				<TableCell>
					<Link to={`/strength-and-conditioning/teams/${d.teamID}/`}>{d.shortLabel} {d.label}</Link>
				</TableCell>
				<TableCell>{d.daysPerWeek}</TableCell>
				<TableCell>{d.dayValuesStrings}</TableCell>
				<TableCell>{d.startDate}</TableCell>
				<TableCell>{d.endDate}</TableCell>
			</TableRow>
		)
	}

}
export class WorkoutList extends React.Component{
	render(){
		const iconStyle = {position: 'relative', top: getSpacing('iconAlignment')};
		const buttonStyle = {position: 'relative', bottom: '3px'}
		const d = this.props.rowData;
		//console.log(d);
		const {prepPushWorkout, prepPullWorkout} = this.props.rowFunctions;
		const workoutError = parseInt(d.error) === 1;
		const workoutWarning = parseInt(d.warning) === 1;
		const workoutAssigned = parseInt(d.assignedDevice) === 1;
		const completed = d.completed === 1;
		let message;
		let mainIcon;
		if(completed){
			mainIcon = <CheckCircle style={{color: getStatusColor('success'),...iconStyle}} />;
			message = 'Workout Completed';
		} else if(workoutError){
			mainIcon = <Cancel style={{color: getStatusColor('error'),...iconStyle}}/>;
			message= 'Workout Has Errors/Warnings';
		} else if(workoutWarning){
			mainIcon = <Warning style={{color: getStatusColor('warning'), ...iconStyle}}/>;
			message = 'Workout Has Warnings';
		} else{
			mainIcon = 	<Pending style={iconStyle}/>;
			message = 'Workout Validated & Pending'
		}
		const iconText = completed ? 'Completed' : 'Pending'
		//Eventually have the DateScheduled Link take user to Analytics for the workout. Show report for that day's workout
		const blockErrorIcon = parseInt(d.hasErrors) === 1 ? <Tooltip title={'Workout Block Has Errors'} placement={'bottom'}><Cancel style={{color: getStatusColor('error'),...iconStyle}}/></Tooltip> : null;
		const blockWarningIcon = parseInt(d.hasWarnings) === 1 ? <Tooltip title={'Workout Block Has Warnings'} placement={'bottom'}><Warning style={{color: getStatusColor('error'),...iconStyle}}/></Tooltip> : null;
		const deviceIcon = workoutAssigned ? <IconButton style={buttonStyle} onClick={()=>prepPullWorkout(d.workoutID)}><CheckCircle/></IconButton>: <IconButton style={buttonStyle} onClick={()=>prepPushWorkout(d.workoutID)}><PanoramaFishEye/></IconButton>;
		const deviceTooltipMessage = workoutAssigned ? 'Deactivate Workout' : 'Activate Workout';
		const deviceText = workoutAssigned ? 'Active' : 'Ready';

		return (
			<TableRow hover>
				<TableCell>
					<Tooltip title={message} placement={'bottom'}>
						{mainIcon}
					</Tooltip>
					 {iconText}
				</TableCell>
				<TableCell>
					<Tooltip title={deviceTooltipMessage} placement={'bottom'}>
						{deviceIcon}
					</Tooltip>
					 {deviceText}
				</TableCell>
				<TableCell><Link to={`/strength-and-conditioning/schedule/${d.dateScheduled}/`}>{d.dayScheduled} {d.dateScheduled}</Link></TableCell>
				<TableCell><Link to={`/strength-and-conditioning/workouts/${d.workoutID}/`}>{blockErrorIcon} {blockWarningIcon} {d.workoutBlockTitle}: Day {d.dayIndex+1}</Link></TableCell>
				<TableCell><Link to={`/strength-and-conditioning/teams/${d.teamID}/`}>{d.teamName}</Link></TableCell>
				<TableCell><Link to={`/profiles/${d.userID}/`}>{d.firstName} {d.lastName}</Link></TableCell>
			</TableRow>
		);
	}
}

/***********/
export class CustomerNotificationList extends React.Component{
	render(){
		const d = this.props.rowData; //shorten
		const showID = d.show_ID !== null ? <Link to={`/show/${d.show_ID}/`}>{d.show_ID}</Link> : '';
		return (
			<TableRow hover>
				<TableCell align="center"><Link to={`/customer/${d.customer_ID}`}>{d.customer_ID}</Link></TableCell>
				<TableCell><Link to={`/customer/${d.customer_ID}`}>{`${d.firstName} ${d.lastName}`}</Link></TableCell>
				<TableCell>{d.email}<br/>{d.phone}</TableCell>
				<TableCell align="center">{showID}</TableCell>
				<TableCell>{d.shortHand}</TableCell>
				<TableCell>{d.typeLabel}</TableCell>
				<TableCell>{d.notificationDate}</TableCell>
				<TableCell>{d.timezone}</TableCell>
				<TableCell>{`${d.repFirstName} ${d.repLastName}`}</TableCell>
			</TableRow>
		);
	}
}
export class PrivateCustomerList extends React.Component{
	render(){
		const d = this.props.rowData; //shorten
		const heatColor = getStatusColor('error');
		const heat = d.hot > 0 ?
			<Tooltip title={'Spicy Customer!'} enterDelay={toolTipDelay} placement={toolTipPlacement}>
				<Whatshot style={{color: heatColor, position: 'relative', top: getSpacing('iconAlignment')}}/></Tooltip> : null;
		return (
			<TableRow hover>
				<TableCell align="center"><Link to={`/customer/${d.customer_ID}/`}>{d.customer_ID}</Link>{heat}</TableCell>
				<TableCell><Link to={`/customer/${d.customer_ID}/`}>{`${d.firstName} ${d.lastName}`}</Link></TableCell>
				<TableCell>{d.email}</TableCell>
				<TableCell>{d.phone}</TableCell>
				<TableCell>
					{`${d.repFirstName} ${d.repLastName}`}<br/>
					{d.leadTaken}
				</TableCell>
				<TableCell>{d.nextNotificationDate}</TableCell>
				<TableCell>{d.nextNoteType}</TableCell>
			</TableRow>
		);
	}
}



const getProfileLink =(userID)=>{
	//Create the link to a user profile - used by troupe directory and deptUserList
	return `/profile/${userID}/`;
};



export default TeamList;