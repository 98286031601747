import React from 'react';
import Stack from "@mui/material/Stack";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import AuthUserDropdown from "../../../../shared/widgets/AuthUserDropdown";
import {IconButton, Slide, useMediaQuery, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import JumboIconButton from "@jumbo/components/JumboIconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Logo from "../../../../shared/Logo";
import {SIDEBAR_STYLES, SIDEBAR_VARIANTS} from "@jumbo/utils/constants";
import {connect} from "react-redux";
import User from 'fit/system/User';
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";

const Header = () => {
    let {sidebarOptions, setSidebarOptions} = useJumboLayoutSidebar();
    const {headerTheme} = useJumboHeaderTheme();
    const showDropdownSearch = false; //useMediaQuery('(max-width:575px)');
    let user = new User();
    const deviceAccount = user.getDeviceAccount();
    const authorized = user.getLoggedInStatus();
    const userInfo = user.getUserInfo();
    const validDevice = authorized && deviceAccount;
    return (
        <React.Fragment>
            {
                (
                    sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                    || sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY
                    || (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT && !sidebarOptions.open)
                ) &&
                (
                    authorized && !deviceAccount
                ) &&

                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{
                        ml: sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ? -2 : 0,
                        mr: 3,
                        }}
                        onClick={() => setSidebarOptions({open: !sidebarOptions.open})}
                    >
                    {
                        sidebarOptions?.open ? <MenuOpenIcon/> : <MenuIcon/>
                    }
                    </IconButton>

            }
            {/*******************Device Account Name ('Rack 01')*************************/}
            {
                validDevice ?
                    <Typography variant={'h1'} color={'primary'}>{userInfo.firstName} {userInfo.lastName}</Typography> : null
            }

            {
                sidebarOptions?.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
                <Logo sx={{mr: 3}} mode={headerTheme.type ?? "light"}/>
            }
            <Stack direction="row" alignItems="center" spacing={1.25} sx={{ml: "auto"}}>
                {
                    authorized ? <AuthUserDropdown/> : null
                }

            </Stack>
        </React.Fragment>
    );
};

const mapStateToProps=(state)=>{
    return {FITUser: state.FITUser};
}

export default connect(mapStateToProps)(Header);
