import React from 'react';
import {SwapVert, Edit, DeleteForever} from "@mui/icons-material";
import {Icon, IconButton, Tooltip, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider} from "@mui/material";
import {WBGStyles} from './WBGStyles';


const BlockExerciseCell =({exercise, deleteFn})=>{
    {/*<li style={WBGStyles.groupListItem}>*/}


    return (
        <React.Fragment>
            <ListItem
                style={{width: '100%'}}
                secondaryAction={
                    <Tooltip title={'Delete Exercise'} placement={'bottom'}>
                        <IconButton
                            edge="end"
                            aria-label="Delete Exercise"
                            onClick={deleteFn}
                        >
                            <DeleteForever />
                        </IconButton>
                    </Tooltip>
                }
                disablePadding
            >
                <ListItemButton onClick={()=>alert('clicked')} dense>
                    <ListItemIcon>
                        <Tooltip title={'Change Order'} placement={'bottom'}>
                            <SwapVert/>
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText id={1} primary={exercise.exerciseName} />
                </ListItemButton>
            </ListItem>
            <Divider/>
        </React.Fragment>
    )
}
export default BlockExerciseCell;


/*

const BlockExerciseGroupItem =(props)=>{

    const groupIndex = 1;
    const hasChildren = groupIndex > 0;

    const groupStyle = {
        display: 'grid',
        gap: 0,
        gridTemplateColumns: 'repeat(3, 1fr)',
        justifyItems: 'left',
        border: '1px sold #aaa'
    }
    const buttonCell = {
        width: '25%'
    }
    const borderColor = hasChildren ? WBGStyles.superSetStyles[groupIndex] : 'transparent';
    const groupCell = {
        borderLeft: `3px solid ${borderColor}`
    }
    const labelCell={
        width: '50%'
    }

    let innerButton = null;
    if(hasChildren){
        innerButton =
            <IconButton>
                <SwapVert/>
            </IconButton>
    }

    return (
        <List>


            <BlockExerciseCell/>
            <BlockExerciseCell/>
            <BlockExerciseCell/>
            <BlockExerciseCell/>
        </List>
    )


    return (
        <li style={WBGStyles.groupListItem}>

            <div style={groupStyle}>
                <div style={buttonCell}>
                    <IconButton>
                        <SwapVert/>
                    </IconButton>
                </div>
                <div style={groupCell}>
                    {innerButton}
                </div>
                <div style={labelCell}>
                    <List>


                    <BlockExerciseCell/>
                    <BlockExerciseCell/>
                    <BlockExerciseCell/>
                    <BlockExerciseCell/>
                    </List>
                </div>
            </div>


        </li>

    );
}


*/

