import React from 'react';
import Login from 'fit/components/System/Login';
import SoloFormWrapper from "fit/components/System/SoloFormWrapper";


const LoginRoute =()=>{
    return (
        <SoloFormWrapper>
            <Login/>
        </SoloFormWrapper>
    )
}
export default LoginRoute;