import React, {memo} from "react";
import {isEmpty} from "fit/system/UtilityFunctions";
import {Button, Tooltip, useTheme} from "@mui/material";
import InputMask from "react-input-mask";
import {Add, ArrowBack, ArrowForward, DeleteForever, Toc} from "@mui/icons-material";


const ExerciseCell = memo(function ExerciseCell({dayIndex, exercise, exerciseID, addSetFunction, updateSetFunction, deleteSetFunction, copySetFunction, blockCompleted, workoutCompleted, errorColor, previousDayIndex, futureDayIndex, exerciseUnitClass, WBGStyles, checkExDexFn}){
    const fastRender = true;
    const disabled = parseInt(workoutCompleted) === 1 || blockCompleted;
    const exists = !isEmpty(exercise);
    const percentageEnabled = exists ? exercise.percentageEnabled : false;
    const sets = exists ? exercise.sets : [];

    //Styling
    const theme = useTheme();
    const selectedColor = theme.palette.secondary.main;
    let selectedDayColumnStyle = structuredClone(WBGStyles.selectedDayColumnStyle);
    selectedDayColumnStyle.borderColor = selectedColor;
    let selectedDayColumnLastExercise = structuredClone(WBGStyles.selectedDayColumnLastExercise);
    selectedDayColumnLastExercise.borderColor = selectedColor;



    const {fastRenderInputStyle, singleDayColumnStyle} = WBGStyles;
    const {percentageStyle, inCellStyle, noPercentageInputStyle, labelHeadStylePercent, labelHeadMainStyle, labelHeadStyle} = WBGStyles;

    const errorStyle = {background: errorColor, color: '#fff'}
    const deleteButtonOpacity = disabled ? '50%' : '100%';
    let deleteButton = {position: 'absolute', right: 0, bottom: '-1px', zIndex: 100, cursor: 'pointer', opacity: deleteButtonOpacity} //top: cellPadding, bottom: cellPadding,
    let labelHeader = null;


    //Rep Settings
    const eachLabel = exercise.eachEnabled ? ' (Each)' : '';
    const repData = exerciseUnitClass.getExerciseUnitData(exercise.repUnitID);
    const {maskPattern, inputLabel, placeholderText} = repData;
    //console.log('DAYINDEX',dayIndex,'RENDERING EXERCISE', exercise);
    //console.log('REP DATA', exercise.exerciseName, exerciseID, exercise.repTypeID, exercise.repUnitID, repData);

    if(sets.length > 0){
        if(percentageEnabled) {
            labelHeader = <div style={labelHeadMainStyle}>
                <div style={labelHeadStylePercent}>Sets</div>
                <div style={labelHeadStylePercent}>%Max</div>
                <div style={labelHeadStylePercent}>{inputLabel}{eachLabel}</div>
            </div>
        } else{
            labelHeader = <div style={labelHeadMainStyle}>
                <div style={labelHeadStyle}>Sets</div>
                <div style={labelHeadStyle}>{inputLabel}{eachLabel}</div>
            </div>
        }
    }

    let inputStyling = percentageEnabled ? percentageStyle : noPercentageInputStyle;
    inputStyling = fastRender ? {...inputStyling, ...fastRenderInputStyle} : inputStyling;
    const errorStyling = {...inputStyling, ...errorStyle};
    //Rep Types (Count, Time, Distance) - Distance allow for decimal ? Count standard integer, time use the label to determine masking
    //Values & Labels
    const deleteTip = disabled ? '' : 'Delete Set';


    //Copy Buttons
    const noPreviousDay = sets.length === 0 || previousDayIndex == null;
    const noFutureDay = sets.length === 0 || futureDayIndex == null;
    const copyPreviousTip = noPreviousDay ? 'Can\'t Copy To Previous Day' : 'Copy To Previous Day';
    const copyFutureTip = noFutureDay ? 'Can\'t Copy To Next Day' : 'Copy To Next Day';
    const addSetTip = disabled ? 'Can\'t Add Set' : 'Add Set';



    return (
        <div style={singleDayColumnStyle} key={dayIndex} className={'exerciseCell'}>
            {labelHeader}
            {//OUTPUT ALL SETS
                sets.map( (set,key) =>{
                    const repValue = set.reps == null ? '' : set.reps;
                    const repError = !exerciseUnitClass.validateExerciseInput(repValue, repData);
                    const setError = set.setCount == null || set.setCount === '' || parseInt(set.setCount) < 1;
                    if(repError){
                        deleteButton.color = '#FFF'; //background will be red. Create contrast
                    }
                    let percentageError = false;
                    const setStyle = setError ? errorStyling : inputStyling;
                    const repStyle = repError ? errorStyling : inputStyling;
                    const percentageValue = percentageEnabled && set.percentage != null ? set.percentage : 0;
                    let percentageInput = null;
                    if(percentageEnabled){
                        percentageError = percentageEnabled && (percentageValue <= 0 || percentageValue === '');
                        const percentageStyle = percentageError ? errorStyling : inputStyling;
                        percentageInput =
                            <InputMask
                                mask={'999'}
                                maskChar={''}
                                size={'small'}
                                label={'Max %'}
                                placeholder={'%'}
                                name={`percentage_${dayIndex}_${exerciseID}_${set.setKey}`}
                                value={percentageValue}
                                disabled={disabled}
                                onChange={(e)=>updateSetFunction(e, 'percentage', dayIndex, exerciseID, set.setKey)}
                                style={percentageStyle}
                                variant={'standard'}
                                error={percentageValue <= 0}
                                inputMode={'decimal'}
                            >
                            </InputMask>
                    }

                    {/*********************************************************
                     ************************************************************
                     * RETURN HERE
                     ************************************************************
                     ************************************************************/}
                    return (
                        <div key={key}>
                            <InputMask
                                mask={'999'}
                                maskChar={''}
                                size={'small'}
                                label={'Sets'}
                                variant={'standard'}
                                style={setStyle}
                                placeholder={'# sets'}
                                name={`sets_${dayIndex}_${exerciseID}_${set.setKey}`}
                                value={set.setCount}
                                error={set.setCount <= 0}
                                disabled={disabled}
                                onChange={(e)=>updateSetFunction(e,'setCount',dayIndex, exerciseID, set.setKey)}
                                inputMode={'decimal'}
                            >
                            </InputMask>
                            {percentageInput}
                            <div style={{display: 'inline', position: 'relative'}}>
                                <InputMask
                                    mask={maskPattern}
                                    maskChar={''}
                                    size={'small'}
                                    name={`reps_${dayIndex}_${exerciseID}_${set.setKey}`}
                                    label={inputLabel}
                                    placeholder={placeholderText}
                                    value={repValue}
                                    error={repValue <= 0}
                                    variant={'standard'}
                                    style={repStyle}
                                    disabled={disabled}
                                    onChange={(e)=>updateSetFunction(e, 'reps', dayIndex, exerciseID, set.setKey)}
                                    inputMode={'decimal'}
                                >
                                </InputMask>
                                <Tooltip title={deleteTip} placement={'bottom'}>
                                    <DeleteForever
                                        style={deleteButton}
                                        onClick={disabled ? null : ()=>deleteSetFunction(dayIndex, exerciseID, set.setKey)}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    )
                })
            }
            <Tooltip title={copyPreviousTip} placement={'left'}>
                <span>
                <Button
                    style={inCellStyle}
                    size={'small'}
                    variant={'outlined'}
                    color={'primary'}
                    disabled={noPreviousDay}
                    onClick={()=>copySetFunction(previousDayIndex, exerciseID, sets)}
                ><ArrowBack/> <Toc/>
                </Button>
                </span>
            </Tooltip>
            <Tooltip title={addSetTip} placement={'top'}>
                <span>
                    <Button
                        size={'small'}
                        disableRipple={true}
                        disabled={disabled}
                        color={'primary'}
                        variant={'outlined'}
                        style={inCellStyle}
                        onClick={()=>addSetFunction(dayIndex, exerciseID)}
                    >
                        <Add/>Set
                    </Button>
                </span>
            </Tooltip>
            <Tooltip title={copyFutureTip} placement={'right'}>
                <span>
                <Button
                    style={inCellStyle}
                    size={'small'}
                    variant={'outlined'}
                    color={'primary'}
                    disabled={noFutureDay}
                    onClick={()=>copySetFunction(futureDayIndex, exerciseID, sets)}
                ><Toc/> <ArrowForward/></Button>
                </span>
            </Tooltip>
        </div>
    )
} ,(prevProps, currentProps)=>{
    const {dayIndex, exerciseID, exercise} = currentProps;
    const reRender = currentProps.checkExDexFn(dayIndex, exerciseID, exercise); //checkExDex(dayIndex, exerciseID, exercise);
    return reRender;
});

export default ExerciseCell;