import SecureConnect from 'fit/system/SecureConnect';
import ClientStore from './ClientStore'; //AIT
//import ColorThemes from "./ColorThemes"; //AIT
//import {DefaultTheme} from "./ColorThemes"; //AIT
import {PERM_SET, PERM_TEAM, PERM_DEPT, ASSISTANT_COACH_LEVEL, COACH_LEVEL, ATHLETE_LEVEL, COACHING_ASSISTANT_LEVEL} from "./FITConstants";
import {isEmpty} from "./UtilityFunctions";

//_AIT_COLOR_THEMES NEED TO BE FIXED
import {
	updateUser,
	updateUserDetails,
	setPending,
	setRedirectURL,
	setDarkTheme,
	setThemeColor,
	TestIncrement,
} from 'app/redux/actions';

export class User{
	constructor(logConsole = false){
		this.checkType = '';
		this.checkID = 0;
		this.strict = false;
		this.logConsole = logConsole;
		this.redirectPath = '';
		this.redirectComplete = true;
	}
	logout(connect = true){
		this.setClientStorePermissions(false,{});
		let sc = new SecureConnect('system.php?action=logout');
		sc.setDisplaySuccessMessages(false);
		sc.terminateSessionTimestamp();
		if(connect) {
			sc.connect();
		}
		//this.setThemeColor(DefaultTheme); __AIT__DELETE
		//Terminate storage
		Object.keys(localStorage).forEach(key=> {
			localStorage.removeItem(key);
		});
		Object.keys(sessionStorage).forEach(key =>{
			sessionStorage.removeItem(key);
		});
		this.setAuthorizedStatus(false);
		this.setAppState({});
	}
	setClientStorePermissions(authorized, json = {}){
		//Establish person's authorization & permissions for system access
		//Set the store to match server permissions
		this.setAuthorizedStatus(authorized);
		this.setAppState(json);
		//Send to Redux/State
		if(this.logConsole){
			console.log(`User.js::setClientStorePermissions(${authorized})`,authorized, 'dispatching updateUser');
		}
		ClientStore.dispatch(updateUser(authorized, json));
		if(json.userInfo != null && json.userInfo.themeColor != null){
			const themeColor = json.userInfo.themeColor;
			this.setThemeColor(themeColor);
		}
		if(authorized === false){
			//Disconnected - reset theme
			//this.setThemeColor(DefaultTheme); __AIT__DELETE
		}
	}
	setClientUserDetails(data){
		if(this.logConsole){
			console.log('User.js.setClientUserDetails()');
		}
		//When the user updates there details (name, phone, email, mailing address)
		//Update the interface state
		ClientStore.dispatch(updateUserDetails(data));
	}
	setThemeColor(colorCode){
		if(colorCode === false || colorCode == null || colorCode === ''){
			//Die early
			return;
		}
		const statusColors = {} //__AIT__REFACTOR :: ColorThemes[colorCode].palette.statuses || {};
		const body = document.body.classList;
		const themes = []; //__AIT__REFACTOR :: Object.keys(ColorThemes);
		if(colorCode === 'dark-theme'){
			ClientStore.dispatch(setDarkTheme(statusColors));
		} else{
			ClientStore.dispatch(setThemeColor(colorCode, statusColors));
		}
		//Remove the themes
		themes.forEach(theme=>{
			body.remove(theme);
		});
		//Add theme
		body.add(colorCode);
	}
	getAuthString(){
		return 'previouslyAuthorizedUser';
	}
	getAppStateString(){
		return 'appStatePermissions';
	}
	TestIncrement(){
		ClientStore.dispatch(TestIncrement());
	}
	setAuthorizedStatus(loggedIn){
		const statusValue = loggedIn ? 1 : 0;
		localStorage.setItem(this.getAuthString(), statusValue);
		if(this.logConsole) {
			console.log('User.js.setAuthorizedStatus()', statusValue);
		}
	}
	setAppState(json){
		localStorage.setItem(this.getAppStateString(), JSON.stringify(json));
	}
	getPersistStatusCheck(){
		return parseInt(localStorage.getItem('pendingPersistStatusCheck'));
	}
	setPersistStatusCheck(pendingValue){
		const storedValue = pendingValue ? 1:0;
		localStorage.setItem('pendingPersistStatusCheck', storedValue);
	}
	checkAuthorizedStatus(){
		const localStorageAuthorizationValue = localStorage.getItem(this.getAuthString());
		if(this.logConsole) {
			console.log('User.js.checkAuthorizedStatus()', localStorageAuthorizationValue);
		}
		return localStorageAuthorizationValue != null && parseInt(localStorageAuthorizationValue) === 1;
	}
	persistClientState(url = ''){
		//persists the client's state on refresh
		//Refreshing the page will nullify the user account
		//pulls appState from localstorage status and updatesUser/App
		const locallyAuthorized = this.checkAuthorizedStatus();
		const loggedInStatus = this.getLoggedInStatus();
		const userData = locallyAuthorized ? JSON.parse(localStorage.getItem(this.getAppStateString())) : {};
		if(this.logConsole){
			console.log('------------------------User.js.persistClientState()------------------------');
			console.log('Locally Authorized', locallyAuthorized);
			console.log('LOGGED IN??', loggedInStatus);
			console.log('USER DATA', userData);
			console.log('URL =>? ',url);
			console.log('-----------------------/User.js.persistClientState()------------------------');
		}
		//Set to AppState
		if(userData.deptPermissions != null && !isEmpty(userData.deptPermissions) && userData.userInfo != null && !isEmpty(userData.userInfo)){
			this.setClientStorePermissions(locallyAuthorized, userData);
			if(url !== ''){
				//Redirect the user to the proper address
				//this.setRedirectURL(url);
			}
		} else{
			//Data likely corrupted. Force user to log back in.
			this.logout();
		}
	}
	updateSessionPending(pendingValue){
		const store = ClientStore.getState();
		if(store.FITUser != null && store.FITUser.pending < pendingValue){
			ClientStore.dispatch(setPending(pendingValue));
		}
	}
	setRedirect(url, complete = false){
		this.redirectComplete = complete;
		this.redirectPath = url;
	}
	getRedirect(){
		return {
			redirectPath: this.redirectPath,
			redirectComplete: this.redirectComplete
		}
	}
	setRedirectURL(url = false){
		const store = ClientStore.getState();
		if(store.FITUser != null){
			url = url !== false && url != null ? url : '';
			ClientStore.dispatch(setRedirectURL(url));
		}
	}
	checkCoreSuperUser(){
		let coreTest = false;
		const store = ClientStore.getState();
		if(store.FITUser != null){
			//Compare user ID to the list of core super users
			const {account} = store.FITUser;
			coreTest = account.coreSuSet.includes(parseInt(account.userInfo.user_ID));
		}
		return coreTest;
	}
	async checkSession(){
		let sc = new SecureConnect('system.php?action=authUser','get');
		sc.setDisplaySuccessMessages(false);
		sc.setDisplayNotifications(false);
		this.updateSessionPending(1);
		sc.connect().then(jsonResponse =>{
			//1b. Check completed (user logged in)
			if(sc.getCompleted(jsonResponse)){
				//1c. Assign team assignments, permissions, user settings to redux
				//1d. Return true;
				return true;
			} else{
				//No longer pending status for the session
				this.updateSessionPending(2); //was completed
				return false;
			}
		});
	}
	async checkLoginStatus(){
		//Called on Refresh/Initial Page Load
		//1. Determine if user previously logged in
		if(this.logConsole) {
			console.log('User.js.CheckinLoginStatus(): checking login status');
		}
		if(!this.checkAuthorizedStatus()) {
			//Failed - not logged in.
			return false;
		}
		if(this.logConsole) {
			console.log('User.js.CheckinLoginStatus(): local auth true');
			console.log('User.js.CheckinLoginStatus(): Connecting to system to load Permissions');
		}
		//1a. Pull credentials from the system
		const sc = new SecureConnect('system.php?action=authUser','get');
		sc.setDisplaySuccessMessages(false);
		sc.setDisplayNotifications(false);
		sc.connect().then(jsonResponse =>{
			//1b. Check completed (user logged in)
			if(sc.getCompleted(jsonResponse)){
				//1c. Assign team assignments, permissions, user settings to redux
				if(this.logConsole) {
					console.log('User.js',jsonResponse);
				}
				//1d. Return true;
				return true;
			} else{
				//Not valid - logout
				if(this.logConsole){
					console.log('User.js','*** INVALID USER');
					console.log('User.js',jsonResponse);
				}
			}
		});
	}
	getURL(){
		return 'localhost:3000';
	}
	getPermittedFormOptions(editedUserSettings, type, userID){
		//Return a list of permissions that are editable by the viewer of the user
		//Run through depts/teams (based on "groupLabel")
		//Return instances (deptID's/teamID's) when the user's permissions are higher than the editedUserSettings
		//Editor's managerial permissions - Default = dept Permissions
		let permittedSet = []; //Settings (depts/teams) Editor is allowed to modify
		let deptMinLevel = 1; //Starting Required Level = ASM+
		if(this.getUserID() === parseInt(userID)){
			//SElF USER - DISPLAY ALL DEPT SETTINGS - NOT JUST EDITABLE ONES
			deptMinLevel = 0; //Standard user
		}
		let editorPermissions = [];
		let setType = 'depts';
		let sectionKey = 'section_ID';
		let sortKey = 'section';
		editorPermissions = this.getManagerDepts(deptMinLevel);
		//Exception for directors - they need to have actor dept available
		if(this.isCoach() && editorPermissions.filter(item =>(parseInt(item.dept_ID) === 6)).length < 1){
			let basicUserPermissions = this.getManagerDepts(0);
			let actorDeptPermission = basicUserPermissions.filter(item =>(parseInt(item.dept_ID) === 6))[0];
			//Set as Assistant manager - get the User selector
			actorDeptPermission.managerLevel = 1;
			editorPermissions.push(actorDeptPermission);
		}
		let permKeys = this.getPermissionKeys(setType);
		//Create list of the user's settings
		let keys = []; //Object.keys(editedUserSettings);
		if(editedUserSettings != null){
			keys = Object.keys(editedUserSettings);
		}
		editorPermissions.forEach(set=> {
			//Run through the list of editor's available permissions to assign
			//Get the key label for dept_ID or team_ID
			const keyID = permKeys.groupKey;
			//Assign deptID or teamID to "groupID"
			const groupID = parseInt(set[keyID]);
			//convert to int
			set[sectionKey] = parseInt(set[sectionKey]);
			set['managerLevel'] = parseInt(set['managerLevel']);
			//Find instances of the viewer's dept/team within the user's permissions settings
			let ag = null;
			const activeGroup = keys.filter(k => {
				if(parseInt(editedUserSettings[k][keyID]) === groupID){
					ag = editedUserSettings[k];
					return true;
				} else{
					return false;
				}
			});
			//Found an instance - This dept/team is active
			const active = activeGroup.length > 0 ? 1: 0;
			//Set the value of the current user's permissions
			const settingValue = active === 1 ? parseInt(ag.managerLevel) : 0;
			let final = {sectionID: groupID, data: {...set, setType, active, settingValue}};
			final = {...set, setType, active, settingValue};
			permittedSet.push(final);
		});
		permittedSet.sort((a,b)=>(a[sortKey] > b[sortKey] ? 1:-1));
		return permittedSet;
	}
	getPermissionKeys(type){
		let keys={groupKey: 'dept_ID', levelKey: 'managerLevel'};
		if(type.toLowerCase().includes('teams')){
			keys={groupKey: 'section_ID', levelKey: 'managerLevel'};
		}
		return keys;
	}
	getUserID(){
		//Return the userID for the user
		//Use this to run checks to make sure the person can't edit their own permissions
		const userData = this.getUserInfo();
		return userData.userID != null ? parseInt(userData.userID) : null;
	}
	getManagerDepts(level = 1, strict = false){
		//Return a list of depts the user is a manager of
		//used when creating a new user
		return this.getManagerDirectorListings('permissions','managerLevel',level, strict);
	}
	getAthleticTeams(level = ASSISTANT_COACH_LEVEL, strict){
		//_AIT_REMOVE
		//Return a list of teams in which the user is a director
		//Used when creating a new actor or assigning teams to existing actor
		let teams = this.getManagerDirectorListings(PERM_TEAM,'managerLevel',level, strict);
		return teams.sort((a,b)=>(a['team'] > b['team'] ? 1:-1));
	}
	getTeamList(){
		return this.getAthleticTeams(0,false);
	}
	getManagerDirectorListings(permissionsGroup, levelKey, level = ASSISTANT_COACH_LEVEL, strict = false){
		//Return a list of dept/teams where the user is a manager or coach/athlete
		//permissionsGroup = 'permissions' / 'teams';
		const requiredLevel = parseInt(level);
		let managerSettings = [];
		const userData = this.getPermissions();
		if(!userData.hasOwnProperty(permissionsGroup)){
			return managerSettings; //Return empty array - not a director/manager
		}
		const specificPermissions = userData[permissionsGroup];
		const keys = Object.keys(specificPermissions);
		keys.forEach(k =>{
			const permissionSet = specificPermissions[k];
			const setLevel = parseInt(permissionSet[levelKey]);
			if((strict && requiredLevel === setLevel) || (!strict && requiredLevel <= setLevel)){
				managerSettings.push(permissionSet);
			}
		});
		return managerSettings;
	}
	__checkTeamLevel(level, strict = false){
		//_AIT_REMOVE
		level = parseInt(level);
		const set = this.getTeams();
		for(let k of Object.keys(set)){
			const team = set[k];
			//IF Not strict and required level less than assigned level -OR- Strict && levels match
			if((!strict && level <= parseInt(team.managerLevel)) || (strict && level === parseInt(team.managerLevel))){
				//Access permitted. End loop.
				return true;
			}
		}
		//Not Permitted
		return false;
	}
	checkTeamAccess(teamID){
		return this.checkTeamLevel(teamID, 0);
	}
	checkTeamLevel(teamID, level, strict = false){
		this.setTypes('team',teamID, level, strict);
		return this.checkLevel();
	}
	checkDeptAccess(deptID){
		return this.checkUserLevel(deptID,0);
	}
	checkDeptLevel(deptID, level = 0, strict = false){
		//Alias of userLevel
		this.setTypes('dept', parseInt(deptID), parseInt(level), strict);
		return this.checkLevel();
	}
	setTypes(checkType, checkID, requiredLevel, strict){
		this.checkType = checkType;
		this.checkID = parseInt(checkID);
		this.requiredLevel = parseInt(requiredLevel);
		this.strict = !!parseInt(strict);
	}
	isCoach(level = ASSISTANT_COACH_LEVEL, strict = false){
		//Must be a member of the Athletic Team Dept
		//Must have a team which they're a coach
		const hasTeams = this.checkTeamLevel(level, strict);
		return hasTeams;
	}
	checkLevel(){
		//Check the permissions from redux store
		const systemPermissions = this.getPermissions();
		const type = this.checkType === 'dept' ? PERM_DEPT: PERM_TEAM;
		const levelKey = 'managerLevel';
		const withinPermittedGroup = systemPermissions[type] != null && systemPermissions[type][this.checkID] != null;
		if(withinPermittedGroup){
			//User is assigned to dept or team. Now determine if the user has the permissions for the section
			const setUserLevel = parseInt(systemPermissions[type][this.checkID][levelKey]);
			if((this.strict && this.requiredLevel === setUserLevel) || (!this.strict && this.requiredLevel <= setUserLevel)){
				return true;
			}
		}
		return false;
	}
	getForcedPasswordReset() {
		const userData = this.getUserData();
		//return false if no userdata, otherwise return setting
		return userData.account.userInfo == null ? false : parseInt(userData.account.userInfo.resetPass) === 1;
	}
	getUserData(){
		const store = ClientStore.getState();
		return store.FITUser;
	}
	getSessionToken() {
		const {account} = this.getUserData();
		return account.userInfo != null ? account.userInfo.token : '';
	}
	getPermissions(type = null){
		const clientStore = this.getFitUserStore();
		const permissions = clientStore.account[PERM_SET];
		if(permissions != null && !isEmpty(permissions)) {
			if(type != null){
				return permissions[type];
			} else{
				return permissions;
			}
		} else{
			return [];
		}
	}
	getLoggedInStatus(){
		//Returns whether the user is authorized
		//Return store authorized;
		const store = this.getFitUserStore();
		return store.authorized != null ? store.authorized : false;
	}
	getUserInfo(){
		const store = this.getFitUserStore();
		return (this.getLoggedInStatus() && store.account.userInfo != null ? store.account.userInfo : {});
	}
	getDeviceAccount(){
		//Returns whether the user logged in is a device account
		//return store.account.userInfo.deviceAccount
		const store = this.getFitUserStore();
		return (this.getLoggedInStatus() && store.account.userInfo.deviceAccount != null) ? store.account.userInfo.deviceAccount : false;
	}
	getDepts(){
		//Return a list of dept permissions
		return this.getPermissions(PERM_DEPT);
	}
	getTeams(){
		//Return a list of teams
		return this.getPermissions(PERM_TEAM);
	}
	getSettings(){
		//Return the user's settings
		const store = ClientStore.getState();
		return store.settings;
	}
	getStatusColors(){
		return {
			success: '#3bcc00',
			info: '#00aacc',
			warning: '#e59900',
			error: '#e53a36',
			noAction: '#d9d9d9',
		}
		/*
		const store = ClientStore.getState();
		return store.settings.statusColors;

		 */
	}
	getStore(){
		return ClientStore.getState();
	}
	getRouter(){
		return ClientStore.getState().router;
	}
	getFitUserStore(){
		const FITUser = this.getStore().FITUser;
		return FITUser != null ? FITUser : {};
	}
}

export default User;
