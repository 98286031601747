import React from 'react';
import {useParams} from 'react-router-dom';
import Schedule from "fit/pages/strength/Schedule";
//import WorkoutCalendar from "fit/pages/strength/WorkoutCalendar";

const ScheduleRoute =()=>{
    let props = useParams();
    //console.log('PROPS', props);
    return (
        <Schedule date={props.dateISO}/>
    )
}

export default ScheduleRoute;