import React from "react";
import {connect} from 'react-redux';
import {Navigate} from 'react-router-dom';
import {Typography, Grid, Paper} from "@mui/material";
import Alert from "fit/components/Alert";
import {PAPER_STYLE, LOGIN_URL} from 'fit/system/FITConstants';
import User from 'fit/system/User';
import {STRENGTH_AND_CONDITIONING_DEPT_ID, COACHING_ASSISTANT_LEVEL, ACTIVE_WORKOUT_URL} from "fit/system/FITConstants";


class DeniedRoute extends React.Component{
    render(){
        let user = new User();
        const authorized = user.getLoggedInStatus();
        const deviceAccount = user.getDeviceAccount();

        if(authorized === false){
            return (
                <Navigate to={LOGIN_URL}/>
            );
        } else if(deviceAccount && user.checkDeptLevel(STRENGTH_AND_CONDITIONING_DEPT_ID, COACHING_ASSISTANT_LEVEL)){
            return (
                <Navigate to={ACTIVE_WORKOUT_URL}/>
            );
        }

        return(
            <div>
                <Typography variant={'h1'}>Unknown Error Occurred</Typography>
                <Paper style={PAPER_STYLE}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert
                                visible={true}
                                status={'error'}
                                headline={'Error'}
                                message={"The resource you're trying to access either does not exist or is unavailable."}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'body1'}>
                                Looks like you took a wrong turn. Use the menu to find what you're looking for.
                                <br/>If this is a repetitive error, please contact an administrator.
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {FITUser: state.FITUser};
};
export default connect(mapStateToProps)(DeniedRoute);