import React from "react";
import {ADMIN_DEPT_ID, STRENGTH_AND_CONDITIONING_DEPT_ID, COACHING_STAFF_DEPT_ID, ATHLETE_DEPT_ID, MY_PROFILE_URL} from "./FITConstants";
import {PRODUCTION_STATUS, ASSISTANT_COACH_LEVEL, COACHING_ASSISTANT_LEVEL, COACH_LEVEL, LOGIN_URL, ACTIVE_WORKOUT_URL} from "./FITConstants";
import {PeopleAlt, Devices, FitnessCenter, BarChart, AddBox, Adb} from "@mui/icons-material";
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';



//STRENGTH ROUTS
import ActiveWorkoutRoute from 'fit/routes/shared/ActiveWorkoutRoute';
import AnalyticsRoute from "fit/routes/strength/AnalyticsRoute";
import DevicesRoute from "fit/routes/strength/DevicesRoute";
import ExerciseListRoute from "fit/routes/strength/ExerciseListRoute";
import ScheduleRoute from "fit/routes/strength/ScheduleRoute";
import CreateWorkoutBlockRoute from 'fit/routes/strength/CreateWorkoutBlockRoute';
import TeamsRoute from "fit/routes/shared/TeamsRoute";
import TodaysWorkoutsListRoute from 'fit/routes/strength/TodaysWorkoutsListRoute';
import WorkoutBlockEditorRoute from "fit/routes/strength/WorkoutBlockEditorRoute";
import WorkoutBlocksListRoute from 'fit/routes/strength/WorkoutBlocksListRoute';
import WorkoutBlocksRoute from "fit/routes/strength/WorkoutBlocksListRoute";
import WorkoutsListRoute from "fit/routes/strength/WorkoutsListRoute";

import Home from "../../app/pages/home";
//System Routes
import DeniedRoute from 'fit/routes/system/DeniedRoute';
import LoginRoute from "fit/routes/system/LoginRoute";
import ResetPasswordRoute from "fit/routes/system/ResetPasswordRoute";
import MyProfileRoute from "fit/routes/shared/MyProfileRoute"; //GET RID OF THIS EVENTUALLY
import ProfileRoute from "fit/routes/shared/ProfileRoute";
import SwitchUserRoute from "../routes/system/SwitchUserRoute";


/*PAGE/Routes try to get this working
import Page from "@jumbo/shared/Page";
import Teams from "fit/routes/strength/Teams";
*/
/*
//NO longer necessary - no blank files
import SecureConnect from './SecureConnect';
const sc = new SecureConnect();
const apiURL = sc.getURL();
*/
const size = 20;

export const DeniedPermissionRoute = {
	path: '*',
	element:  DeniedRoute,
}

//Logged In Permissions for Authorized Users
export const RoutePermissions = [
	{
		label: 'Admin',
		deptID: ADMIN_DEPT_ID,
		routes: []
	},
	{
		label: 'Strength & Conditioning',
		deptID: STRENGTH_AND_CONDITIONING_DEPT_ID,
		routes: [

			{
				label: 'Dev Test Page',
				menuDisplay: PRODUCTION_STATUS === false,
				managerLevel: 0,
				strictLevel: false,
				path: '/',
				element:  <Home/>,
				icon: <Adb sx={{fontSize: size}}/>
			},
			{
				label: 'Devices',
				menuDisplay: true,
				managerLevel: ASSISTANT_COACH_LEVEL,
				strictLevel: false,
				path: '/strength-and-conditioning/devices/',
				element:  <DevicesRoute/>,
				icon: <Devices sx={{fontSize: size}}/>
			},
			{
				label: 'Exercises',
				menuDisplay: true,
				managerLevel: ASSISTANT_COACH_LEVEL,
				strictLevel: false,
				path: '/strength-and-conditioning/exercises/',
				element:  <ExerciseListRoute/>,
				icon: <AddBox sx={{fontSize: size}}/>
			},
			{
				label: 'Schedule',
				menuDisplay: true,
				managerLevel: ASSISTANT_COACH_LEVEL,
				strictLevel: false,
				path: '/strength-and-conditioning/schedule/',
				element:  <ScheduleRoute/>,
				icon: <CalendarViewMonthIcon sx={{fontSize: size}}/>
			},
			{
				label: 'Schedule : Specific Date',
				menuDisplay: false,
				managerLevel: ASSISTANT_COACH_LEVEL,
				strictLevel: false,
				path: '/strength-and-conditioning/schedule/:dateISO/',
				element:  <ScheduleRoute/>,
				icon: <CalendarViewMonthIcon sx={{fontSize: size}}/>
			},
			{
				label: 'Teams & Athletes',
				menuDisplay: true,
				managerLevel: ASSISTANT_COACH_LEVEL,
				strictLevel: false,
				path: '/strength-and-conditioning/teams/',
				element:  <TeamsRoute/>,
				icon: <PeopleAlt sx={{fontSize: size}}/>
			},
			{
				label: 'Specific Team',
				menuDisplay: false,
				managerLevel: ASSISTANT_COACH_LEVEL,
				strictLevel: false,
				path: '/strength-and-conditioning/teams/:teamID/',
				element:  <TeamsRoute/>,
				icon: <PeopleAlt sx={{fontSize: size}}/>
			},
			{
				label: 'Workouts',
				menuDisplay: true,
				managerLevel: ASSISTANT_COACH_LEVEL,
				strictLevel: false,
				icon: <FitnessCenter sx={{fontSize: size}}/>,
				subRoutes: [
					{
						menuDisplay: true,
						path: "/strength-and-conditioning/create-workout-block/",
						managerLevel: ASSISTANT_COACH_LEVEL,
						strictLevel: false,
						element: <CreateWorkoutBlockRoute/>,
						label: "Create Block",
					},
					{
						menuDisplay: true,
						path: "/strength-and-conditioning/workout-blocks/",
						managerLevel: ASSISTANT_COACH_LEVEL,
						strictLevel: false,
						element: <WorkoutBlocksListRoute/>,
						label: "Workout Blocks",
					},
					{
						menuDisplay: false,
						path: "/strength-and-conditioning/workout-blocks/:workoutBlockID/",
						managerLevel: ASSISTANT_COACH_LEVEL,
						strictLevel: false,
						element:  <WorkoutBlockEditorRoute/> ,
						label: "Specific Block",
					},
					{
						menuDisplay: true,
						path: "/strength-and-conditioning/workouts/",
						managerLevel: ASSISTANT_COACH_LEVEL,
						strictLevel: false,
						element:  <WorkoutsListRoute/>,
						label: "All Workouts",
					},
					{
						//LOADING WORKOUT BLOCK EDITOR FOR A SPECIFIC WORKOUTID
						menuDisplay: false,
						path: "/strength-and-conditioning/workouts/:workoutID/",
						managerLevel: ASSISTANT_COACH_LEVEL,
						strictLevel: false,
						element: <WorkoutBlockEditorRoute/>,
						label: "Specific Workout",
					},
					{
						label: 'Today\'s Workouts',
						menuDisplay: true,
						managerLevel: ASSISTANT_COACH_LEVEL,
						strictLevel: false,
						path: '/strength-and-conditioning/workouts/today/',
						element:  <TodaysWorkoutsListRoute/>,
						icon: null
					},
					{
						label: 'Active Workout',
						menuDisplay: true,
						managerLevel: ASSISTANT_COACH_LEVEL,
						strictLevel: false,
						path: ACTIVE_WORKOUT_URL,
						element:  <ActiveWorkoutRoute/>,
						icon: null
					}
				]
			},
			{
				label: 'Analytics',
				menuDisplay: true,
				managerLevel: ASSISTANT_COACH_LEVEL,
				strictLevel: false,
				path: '/strength-and-conditioning/analytics/',
				element:  <AnalyticsRoute/>,
				icon: <BarChart sx={{fontSize: size}}/>
			},
		]
	},
	{
		label: 'Coaching Staff',
		deptID: COACHING_STAFF_DEPT_ID,
		routes: [
			{
				label: 'Team',
				menuDisplay: true,
				managerLevel: ASSISTANT_COACH_LEVEL,
				strictLevel: false,
				path: '/coaching-staff/team/',
				element:  <TeamsRoute/>,
				icon: <PeopleAlt sx={{fontSize: size}}/>
			},
			{
				label: 'Schedule',
				menuDisplay: true,
				managerLevel: ASSISTANT_COACH_LEVEL,
				strictLevel: false,
				path: '/coaching-staff/schedule/',
				element:  <ScheduleRoute/>,
				icon: <CalendarViewMonthIcon sx={{fontSize: size}}/>
			},
			{
				label: 'Analytics',
				menuDisplay: true,
				managerLevel: ASSISTANT_COACH_LEVEL,
				strictLevel: false,
				path: '/coaching-staff/analytics/',
				element:  <AnalyticsRoute/>,
				icon: <BarChart sx={{fontSize: size}}/>
			},
		]
	}
];
//Shared Permissions (Likely) Across All Departments
export const SharedPermissions = [
	{
		label: 'My Profile',
		deptIDs: [
			{
				deptID: ADMIN_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			},
			{
				deptID: STRENGTH_AND_CONDITIONING_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			},
			{
				deptID: COACHING_STAFF_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			},
			{
				deptID: ATHLETE_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			}
		],
		menuDisplay: false,
		path: '/my-profile/',
		element:  <MyProfileRoute/>
	},
	{
		label: 'Profiles',
		deptIDs: [
			{
				deptID: ADMIN_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			},
			{
				deptID: STRENGTH_AND_CONDITIONING_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			},
			{
				deptID: COACHING_STAFF_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			},
			{
				deptID: ATHLETE_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			}
		],
		menuDisplay: false,
		path: '/profiles/:userID/',
		element:  <ProfileRoute/>
	},
	{
		label: 'Devices',
		deptIDs: [
			{
				deptID: ADMIN_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			},
			{
				deptID: STRENGTH_AND_CONDITIONING_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			},
			{
				deptID: COACHING_STAFF_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			}
		],
		menuDisplay: false,
		path: '/devices/:userID/',
		element:  <ProfileRoute/>
	},
	{
		label: 'Forced Reset',
		deptIDs:[
			{
				deptID: ADMIN_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			},
			{
				deptID: STRENGTH_AND_CONDITIONING_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			},
			{
				deptID: COACHING_STAFF_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			},
			{
				deptID: ATHLETE_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			}
		],
		menuDisplay: false,
		path: '/forced-reset-password/',
		element:  <ResetPasswordRoute/>
	},
	{
		label: 'Switch User',
		deptIDs: [
			{
				deptID: ADMIN_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			},
			{
				deptID: STRENGTH_AND_CONDITIONING_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			},
			{
				deptID: COACHING_STAFF_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			},
			{
				deptID: ATHLETE_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			}
		],
		menuDisplay: false,
		path: '/switch-user/',
		element:  <SwitchUserRoute/>
	},
	{
		label: 'login',
		deptIDs: null,
		menuDisplay: false,
		path: LOGIN_URL,
		element: <LoginRoute/>
	}
];
//Permissions limited to Devices
export const DevicePermissions = [
	{
		label: 'Strength & Conditioning',
		deptID: STRENGTH_AND_CONDITIONING_DEPT_ID,
		routes: [
			{
				label: 'ActiveWorkout',
				menuDisplay: false,
				managerLevel: COACHING_ASSISTANT_LEVEL,
				strictLevel: false,
				path: ACTIVE_WORKOUT_URL,
				element:  <ActiveWorkoutRoute/>,
				icon: null
			},
			{
				label: 'My Profile',
				menuDisplay: false,
				managerLevel: COACHING_ASSISTANT_LEVEL,
				strictLevel: false,
				path: MY_PROFILE_URL,
				element:  <MyProfileRoute/>,
				icon: null
			},
			{
				//LOGIN ROUTE WILL TAKE PERSON TO PROFILE WHEN SUCCESSFULLY LOGGED IN
				path: LOGIN_URL,
				element:  <LoginRoute/>,
			}
		]
	}
]
//Routes only available when logged out
export const LoggedOutPermissions = [
	{
		path: LOGIN_URL,
		element:  <LoginRoute/>,
	},
	{
		path: '/reset-password/',
		element:  <ResetPasswordRoute/>,
	},
	{
		path: '/reset-password/:resetKey/',
		element:  <ResetPasswordRoute/>,
	}
];



export default RoutePermissions;
/*
export const __old__RoutePermissions = [
	{
		label: 'Admin',
		deptID: ADMIN_DEPT_ID,
		routes: [
			{
				label: 'God Tier Users',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				uri: '/admin/god-tier-users/',
				component:import('ait/routes/admin/SuperUserDirectory'),
				iconClasses: 'zmdi-flash',
			},
			{
				label: 'Users',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				uri: '/admin/users/',
				component:import('ait/routes/shared/UserList'),
				iconClasses: 'zmdi-account',
			},
			{
				label: 'User Log',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				uri: '/admin/user-history/',
				component:import('ait/routes/admin/UserHistory'),
				iconClasses: 'zmdi-zoom-in',
			},
		]
	},
	{
		label: 'Private',
		deptID: PRIVATE_DEPT_ID,
		routes: [
			{
				label: 'Company Points',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				uri: '/private-sales/company-points/',
				component:import('ait/routes/private/CompanyPoints'),
				iconClasses: 'zmdi-plus-square',
			},
			{
				label: 'Customers',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				uri: '/private-sales/customer-list/',
				component:import('ait/routes/private/CustomerList'),
				iconClasses: 'zmdi-account',
			},
			{
				label: 'Customer Notifications',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				uri: '/private-sales/customer-notifications/',
				component: import('ait/routes/private/NotificationList'),
				iconClasses: 'zmdi-email',
			},
			{
				label: 'Private Sales Show List',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				uri: '/private-sales/show-list/',
				component:import('ait/routes/private/ShowList'),
				iconClasses: showIcon,
			},
			{
				label: 'Lead Tracking',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				uri: '/private-sales/lead-tracking/',
				component: import('ait/routes/private/LeadTracking'),
				iconClasses: 'zmdi-view-dashboard',
			},
			{
				label: 'New Phone Lead',
				menuDisplay: false,
				subMenu: false,
				managerLevel: 0,
				uri: '/private-sales/new-phone-lead/',
				component:import('ait/routes/private/NewPhoneLead'),
				iconClasses: showIcon,
			},
			{
				label: 'Manager',
				menuDisplay: true,
				subMenu: true,
				managerLevel: 1,
				uri: '',
				iconClasses: 'zmdi-lock',
				children: [
					{
						label: 'Blank Contract',
						external: true,
						menuDisplay: true,
						subMenu: false,
						managerLevel: 1,
						uri: `${apiURL}privatesales.php?action=getBlankContract`,
						component: import('ait/routes/private/CompanyPoints'),
						iconClasses: 'zmdi-file-text',
					},
					{
						label: 'Sales Rep Report',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 1,
						uri: '/private-sales/sales-rep-report/',
						component: import('ait/routes/private/ManagerRepReport'),
						iconClasses: '',
					},
					{
						label: 'Users',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 1,
						uri: '/private-sales/users/',
						component:import('ait/routes/shared/UserList'),
						iconClasses: '',
					},
				]
			},
		],
	},
	{
		label: 'Production',
		deptID: PRODUCTION_DEPT_ID,
		routes: [
			{
				label: 'Casting Conflicts',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				uri: '/production/actor-casting-conflicts/',
				component:import('ait/routes/production/ActorCastingConflicts'),
				iconClasses: 'zmdi-check-square',
			},
			{
				label: 'Co Point Management',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 2,
				uri: '/production/co-point-management/',
				component:import('ait/routes/production/CompanyPointsManagement'),
				iconClasses: 'zmdi-plus-square',
			},
			{
				label: 'Director Hours Review',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				uri: '/finance/director-pay-review/',
				component:import('ait/routes/finance/DirectorHourlyPayReview'),
				iconClasses: 'zmdi-time',
			},
			{
				label: 'Pay Disputes',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				uri: '/production/payment-disputes/',
				component:import('ait/routes/production/PaymentDisputes'),
				iconClasses: 'zmdi-money',
			},
			{
				label: 'Show Management',
				menuDisplay: true,
				subMenu: true,
				managerLevel: 0,
				uri: '',
				iconClasses: showIcon,
				children: [
					{
						label: 'Blackout Dates',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						uri: '/production/blackout-dates/',
						component:import('ait/routes/production/BlackoutDates'),
						iconClasses: '',
					},
					{
						label: 'Show Calendar',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						uri: '/production/show-calendar/',
						component:import('ait/routes/production/ShowCalendar'),
						iconClasses: '',
					},
					{
						label: 'Show List',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						uri: '/production/show-list/',
						component:import('ait/routes/production/ShowList'),
						iconClasses: '',
					},
					{
						label: 'Travel Show List',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						uri: '/production/travel-show-list/',
						component:import('ait/routes/production/TravelShowList'),
						iconClasses: '',
					},
					{
						label: 'Shows Projected',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						uri: '/production/shows-projected/',
						component:import('ait/routes/production/ShowsProjected'),
						iconClasses: '',
					},

				]
			},
			{
				label: 'Surveys',
				menuDisplay: true,
				subMenu: true,
				managerLevel: 0,
				uri: '',
				iconClasses: 'zmdi-comment-more',
				children: [
					{
						label: 'Survey List',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						uri: '/production/survey-list/',
						component:import('ait/routes/production/SurveyList'),
						iconClasses: 'zmdi-check-square',
					},
					{
						label: 'Actor Results',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						uri: '/production/survey-actors/',
						component:import('ait/routes/production/SurveyActors'),
						iconClasses: '',
					},
					{
						label: 'Show Results',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						uri: '/production/survey-private-shows/',
						component:import('ait/routes/production/SurveyPrivateShows'),
						iconClasses: '',
					}
				]
			},
			{
				label: 'Survey Results',
				menuDisplay: false,
				subMenu: false,
				managerLevel: 0,
				directorLevel: 0,
				uri: '/production/survey/:surveyID/',
				component:import('ait/routes/production/SurveySpecific'),
				iconClasses: 'zmdi-comment-more',
			},
			{
				label: 'Travel Shows',
				menuDisplay: true,
				subMenu: true,
				managerLevel: 0,
				uri: '',
				iconClasses: 'zmdi-airplane',
				children: [
					{
						label: 'Actor Travel Pay',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						uri: '/production/actor-travel-pay/',
						component:import('ait/routes/production/ActorTravelPay'),
						iconClasses: '',
					},
					{
						label: 'Travel Show List',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						uri: '/production/travel-show-list/',
						component:import('ait/routes/production/TravelShowList'),
						iconClasses: '',
					}
				]
			},
			{
				label: 'Troupes',
				menuDisplay: true,
				subMenu: true,
				managerLevel: 0,
				iconClasses: 'zmdi-account-box',
				children: [
					{
						label: 'Manage Troupes',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						uri: '/production/troupe-manager/',
						component:import('ait/routes/production/TroupeManager'),
						iconClasses: '',
					},
					{
						label: 'Troupe Directory',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						uri: '/production/troupe-list/',
						component:import('ait/routes/production/TroupeDirectory'),
						iconClasses: '',
					},
					{
						label: 'Troupe Profitability',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						uri: '/production/troupe-profitability/',
						component:import('ait/routes/production/TroupeProfitability'),
						iconClasses: '',
					},
					{
						label: 'Themes Report',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						uri: '/production/troupe-themes/',
						component:import('ait/routes/production/TroupeThemeReport'),
						iconClasses: '',
					}
				]
			}
		]
	},
	{
		label: 'Finance',
		deptID: ACCOUNTING_DEPT_ID,
		routes: [
			{
				label: 'Show Payments',
				menuDisplay: true,
				subMenu: true,
				managerLevel: 0,
				uri: '',
				iconClasses: 'zmdi-money',
				children: [
					{
						label: 'Payment Calendar',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						uri: '/finance/payment-calendar/',
						component: import('ait/routes/finance/PaymentCalendar'),
						iconClasses: '',
					},
					{
						label: 'Show Payment List',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						uri: '/finance/payment-list/',
						component: import('ait/routes/finance/PaymentList'),
						iconClasses: '',
					},
					{
						label: 'Refunds',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						uri: '/finance/show-refunds/',
						component: import('ait/routes/finance/ShowRefunds'),
						iconClasses: '',
					},
				]
			},
			{
				label: 'Show List',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				uri: '/finance/show-list/',
				component:import('ait/routes/production/ShowList'),
				iconClasses: showIcon,
			},
			{
				label: 'Troupe Directory',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				uri: '/finance/troupe-list/',
				component:import('ait/routes/production/TroupeDirectory'),
				iconClasses: 'zmdi-account-box',
			},
			{
				label: 'Actor Pay',
				menuDisplay: true,
				subMenu: true,
				managerLevel: 0,
				uri: '',
				iconClasses: 'zmdi zmdi-money-box',
				children: [
					{
						label: 'Payment Ledger',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						uri: '/finance/ledger/',
						component:import('ait/routes/finance/ActorPayLedger'),
						iconClasses: '',
					},
					/*
					//Disabled 2020-10-19 Using Formstack per Scott/Mae
					{
						label: 'Actor Docs: W9/DD',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 1,
						uri: '/finance/actor-payment-documents/',
						component:import('ait/routes/finance/ActorPaymentDocuments'),
						iconClasses: '',
					}

				]
			},
			{
				label: 'Deposit Submissions',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				uri: '/finance/director-deposit-submissions/',
				component:import('ait/routes/finance/DirectorDepositSubmissions'),
				iconClasses: 'zmdi-assignment-check',
			},
			{
				label: 'Photographer Sales',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				uri: '/finance/photographer-sales/',
				component:import('ait/routes/finance/PhotographerSales'),
				iconClasses: 'zmdi-camera',
			},
			{
				label: 'Public Venues',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				uri: '/finance/public-venues/',
				component:import('ait/routes/finance/PublicVenues'),
				iconClasses: 'zmdi-cutlery',
			},
			{
				label: 'Public Venue Profile',
				menuDisplay: false,
				subMenu: false,
				managerLevel: 0,
				uri: '/finance/public-venues/:venueID/',
				component:import('ait/routes/finance/PubVenueProfile'),
				iconClasses: 'zmdi-cutlery',
			},
		]
	},
	{
		label: 'Tech',
		deptID: TECH_DEPT_ID,
		routes: [
			{
				label: 'No Show Email List',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				directorLevel: 0,
				uri: '/tech/no-shows-email-list/',
				component: import('ait/routes/tech/CustomerNoShows'),
				iconClasses: 'zmdi-email',
			}
		]
	},
	{
		label: 'Acting',
		deptID: ACTOR_DEPT_ID,
		routes: [
			{
				label: 'Actor Setup',
				menuDisplay: false,
				subMenu: false,
				managerLevel: 0,
				directorLevel: 0,
				uri: '/acting/setup/',
				component:import('ait/routes/acting/ActorSetup'),
				iconClasses: showIcon,
			},
			{
				label: 'Show Hub',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				directorLevel: 0,
				uri: '/acting/',
				component:import('ait/routes/acting/ActorHome'),
				iconClasses: showIcon,
			},
			{
				label: 'My Profile',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				directorLevel: 0,
				uri: '/my-profile/',
				component:import('ait/routes/shared/MyProfile/'),
				iconClasses: 'zmdi-account-box',
			},
			{
				label: 'Survey',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				directorLevel: 0,
				uri: '/acting/actor-survey/',
				component:import('ait/routes/acting/SurveyForm'),
				iconClasses: 'zmdi-comment-more',
			},
			{
				label: 'Actor Show Details',
				menuDisplay: false,
				subMenu: false,
				managerLevel: 0,
				directorLevel: 0,
				uri: '/acting/show/:showID/',
				component:import('ait/routes/acting/ShowDetails'),
				iconClasses: 'zmdi-comment-more',
			},

			{
				label: 'Director Interfaces',
				menuDisplay: true,
				subMenu: true,
				managerLevel: 0,
				directorLevel: 2,
				uri: '',
				component:import('ait/routes/acting/SurveyForm'),
				iconClasses: 'zmdi-lock',
				children: [
					{
						label: 'Show Calendar',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						directorLevel: 2,
						directorStrict: false,
						uri: '/acting/director/show-calendar/',
						component:import('ait/routes/acting/ShowCalendar'),
						iconClasses: '',
					},
					{
						label: 'Submit Deposit',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						directorLevel: 2,
						directorStrict: true,
						uri: '/acting/director/submit-deposit/',
						component:import('ait/routes/acting/SubmitDeposit'),
						iconClasses: '',
					},
					{
						label: 'Submit Hours',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						directorLevel: 2,
						directorStrict: true, //LIMITED TO DIRECTORS (NOT REGIONAL DIRECTORS ONLY)
						uri: '/acting/director/submit-hours/',
						component:import('ait/routes/acting/SubmitHours'),
						iconClasses: '',
					},
					{
						label: 'Troupe Directory',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						directorLevel: 2,
						uri: '/acting/director/troupe-list/',
						component:import('ait/routes/production/TroupeDirectory'),
						iconClasses: '',
					},
				]
			}
		]
	}
];
*/
/*
//Routes shared by multiple departments
export const __old__SharedPermissions =[
	{
		label: 'Customer',
		deptIDs: [[2,0],[3,0],[4,0]],
		menuDisplay: false,
		subMenu: false,
		exactMatch: false,
		uri: '/customer/:customerID/',
		component: import('ait/routes/shared/Customer')
	},
	{
		label: 'Payment',
		deptIDs: [[2,0],[4,0]],
		menuDisplay: false,
		subMenu: false,
		exactMatch: false,
		uri: '/payments/:paymentID/',
		component: import('ait/routes/shared/Payment')
	},
	{
		label: 'Payment (FULL)',
		deptIDs: [[2,0],[4,0]],
		menuDisplay: false,
		subMenu: false,
		exactMatch: false,
		uri: '/payments/full/:showID/',
		component: import('ait/routes/shared/Payment')
	},
	{
		label: 'My Profile',
		deptIDs: [[2,0],[3,0],[4,0],[6,0]],
		menuDisplay: false,
		subMenu: false,
		managerLevel: 0,
		uri: '/my-profile/',
		component:import('ait/routes/shared/MyProfile/'),
		iconClasses: 'zmdi-account-box',
	},
	{
		label: 'User Profile',
		deptIDs: [[2,1],[3,0],[4,0],[6,0]],
		menuDisplay: false,
		subMenu: false,
		exactMatch: false,
		uri: '/profile/:userID/',
		component: import('ait/routes/shared/User')
	},
	{
		label: 'Show',
		deptIDs: [[2,0],[3,0],[4,0]],
		menuDisplay: false,
		subMenu: false,
		exactMatch: false,
		managerLevel: 0,
		uri: '/show/:showID/',
		component: import('ait/routes/shared/Show')
	},
	{
		label: 'Resources',
		deptIDs: [[2,0],[3,0],[4,0],[6,0]],
		menuDisplay: true,
		subMenu: false,
		uri: '/resources/',
		iconClasses: 'zmdi-globe-alt',
		component: import('ait/routes/shared/Resources')
	},
	{
		label: 'Settings',
		deptIDs: [[2,0],[3,0],[4,0],[6,0]],
		menuDisplay: false,
		subMenu: false,
		uri: '/settings/',
		iconClasses: 'zmdi-globe-alt',
		component: import('ait/routes/shared/Settings')
	}

];

 */