import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Paper, Grid, Typography} from '@mui/material'
import DynamicDataTable from "fit/components/DynamicDataTable";
import {PAPER_STYLE} from "fit/system/FITConstants";
import {Add} from '@mui/icons-material';
import {setPageTitle} from "fit/system/UtilityFunctions";



class WorkoutBlocksList extends React.Component{
    state={
        loadData: true
    }
    setDataLoaded(){
        this.setState({loadData: false});
    }
    render(){
        setPageTitle('Workout Blocks');
        return(
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h1'}>Workout Blocks</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Link to={'/strength-and-conditioning/create-workout-block/'}>
                        <Button
                            color={'primary'}
                            variant={'contained'}
                        ><Add/> Create Block</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={PAPER_STYLE}>
                        <DynamicDataTable
                            title={'Workout Blocks Table'}
                            formatURL={'testapi.php?action=listWorkoutBlocks'}
                            loadData={this.state.loadData}
                            dataLoaded={this.setDataLoaded.bind(this)}
                        />
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default WorkoutBlocksList;