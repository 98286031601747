import React from 'react';
import {useParams} from 'react-router-dom';
import TeamsPage from "fit/pages/shared/TeamsPage";

const TeamsRoute =()=>{
    let props = useParams();
    return (
        <TeamsPage teamID={props.teamID}/>
    )
}

export default TeamsRoute;