import React from 'react';
import Avatar from "@mui/material/Avatar";
import {ListItemIcon, ListItemText, ThemeProvider, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import {useNavigate} from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";

import User from 'fit/system/User';
import {connect} from 'react-redux';
import {AccountCircle, VideoLabel} from '@mui/icons-material';
import {LOGIN_URL, MY_PROFILE_URL} from "fit/system/FITConstants";

const AuthUserDropdown = () => {
    const navigate = useNavigate();
    const {theme} = useJumboTheme();
    //console.log('THEME', theme.palette.primary.main);
    let user = new User();
    const deviceAccount = user.getDeviceAccount();
    const userInfo = user.getUserInfo();
    const userName = `${userInfo.firstName} ${userInfo.lastName}`;
    const iconSettings = {width: 60, height: 60, mb: 2}
    const buttonSettings = {cursor: 'pointer', width: 48, height: 48, color: theme.palette.primary.main}
    const icon = deviceAccount ? <VideoLabel sx={iconSettings}/> : <AccountCircle sx={iconSettings}/>;
    const button = deviceAccount ? <VideoLabel sx={buttonSettings}/> : <AccountCircle sx={buttonSettings}/>;
    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover triggerButton={button}>
                <Div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: theme => theme.spacing(2.5),
                }}>
                    {icon}
                    <Typography variant={"h5"}>{userName}</Typography>
                    <Typography variant={"body1"} color="text.secondary">{userInfo.email}</Typography>
                </Div>
                <Divider/>
                <nav>
                    <List disablePadding sx={{pb: 1}}>
                        <ListItemButton>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <PersonOutlineIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Profile" sx={{my: 0}}
                                onClick={()=> navigate(MY_PROFILE_URL)}
                            />
                        </ListItemButton>
                        {/*
                            <ListItemButton>
                                <ListItemIcon sx={{minWidth: 36}}>
                                    <RepeatOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    onClick={() => navigate("/switch-user/")}
                                    primary="Switch User"
                                    sx={{my: 0}}
                                />
                            </ListItemButton>
                        */}
                        <ListItemButton
                            onClick={()=>{
                                user.logout();
                                navigate(LOGIN_URL);
                            }}>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <LogoutIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary="Logout"
                                sx={{my: 0}}
                            />
                        </ListItemButton>
                    </List>
                </nav>
            </JumboDdPopover>
        </ThemeProvider>
    );
};

const mapStateToProps=(state)=>{
    return {FITUser: state.FITUser};
}

export default connect(mapStateToProps)(AuthUserDropdown);
