import React from 'react';
import {Grid, Paper, TextField, Tooltip} from "@mui/material";
import {List, ListItemIcon, ListItemText, ListItemButton} from "@mui/material";
import {Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import {AddCircleOutline, ExpandMore, ArrowBack} from '@mui/icons-material';
import SecureConnect from "fit/system/SecureConnect";
import {isEmpty} from 'fit/system/UtilityFunctions';
import ExerciseEditModal from "fit/components/Exercise/ExerciseEditModal";
import ApplyExerciseModal from 'fit/components/Exercise/ApplyExerciseModal';

const initExerciseModalSetup =()=>{
    return {
        display: false,
        active:  0,
        allowPercentage: false,
        deptTeamID: null,
        eachEnabled: false,
        exerciseID: null,
        applyToGroup: false,
        groupIndex: 0,
        exerciseStyle: '',
        exerciseStyleID: null,
        measurementUnitID: null,
        measurementTypeID: null,
        measurementUnits: '',
        muscleGroup: '',
        muscleGroupID: null,
        name: '',
        organizationID: null,
        recordSingleWeight: false,
        recordWeightDefault: true,
        shortName: '',
        unitType: ''
    }
}

class ExerciseSelector extends React.Component{
    state ={
        filterSet: '',
        exerciseTextInput: '',
        filteredList: [], //List of exercises get filtered by the textField
        addExerciseModalData: {
            display: false,
            exerciseName: '',
            shortName: '',
            exerciseStyleID: '',
            measurementTypeID: null,
            measurementUnitID: null,
            recordWeightDefault: true,
            unitLabelID: null,
            appendageIsolated: false,
            recordTotalWeight: true,
        },
        displaySelectedExerciseModal: false,
        selectedExerciseID: null, //When someone clicks to add a specific exercise, they get to review/add shit
        selectedExerciseData: {
            active:  0,
            allowPercentage: false,
            deptTeamID: null,
            eachEnabled: false,
            exerciseID: null,
            applyToGroup: false,
            groupIndex: 0,
            exerciseStyle: '',
            exerciseStyleID: null,
            measurementUnitID: null,
            measurementTypeID: null,
            measurementUnits: '',
            muscleGroup: '',
            muscleGroupID: null,
            name: '',
            organizationID: null,
            recordSingleWeight: false,
            recordWeightDefault: true,
            shortName: '',
            unitType: ''
        },
        setupExerciseModalData: {
            display: false,
            //Same as selectedExerciseData, but this data gets changed. Use the other data to revert if necessary
        },

        //server variables for creating exercises
        exerciseList: [],
        exerciseStyles: [],
        exerciseUnits: [],
    }
    componentDidMount() {
        this.loadExerciseData();
    }
    loadExerciseData(){
        let sc = new SecureConnect('testapi.php?action=getExerciseVariables&includeExerciseList=1');
        sc.setDisplaySuccessMessages(false);
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                const {exerciseStyles, exerciseUnits, exerciseList} = sc.getData(json);
                this.setState({
                    exerciseList,
                    exerciseStyles,
                    exerciseUnits,
                })
                console.log('EXERCISE STYLES', exerciseStyles);
            }
        });
    }
    setupExercise(exerciseData){
        let modalData = {
            display: true,
            applyToGroup: false,
            groupIndex: 0,
            ...exerciseData
        }
        this.setState({
            selectedExerciseData: exerciseData,
            setupExerciseModalData: modalData
        });
    }
    initExerciseSetup(){
        const modal = initExerciseModalSetup();
        this.setState({setupExerciseModalData: modal});
    }
    applyExercise(exObj){
        //Add exercise to WBG
        this.props.addExerciseFn(exObj, false);
        this.initExerciseSetup();
    }
    displayAddExerciseModal(displayModal = true){
        let {addExerciseModalData} = this.state;
        addExerciseModalData.display = displayModal;
        this.setState({addExerciseModalData});
    }
    handleChange(e){
        const {value, name} = e.target;
        this.filterExercises(value);
        this.setState({[name]: value});
    }
    handleExerciseModalChange(e){
        const {value, name} = e.target;
        this.filterExercises(value);
        let {addExerciseModalData} = this.state;
        addExerciseModalData[name] = value;
        this.setState({addExerciseModalData});
    }
    handleMeasurementType(e){
        const {value, name} = e.target;
        this.filterExercises(value);
        let {addExerciseModalData} = this.state;
        addExerciseModalData[name] = value;
        addExerciseModalData.measurementUnitID = '';
        console.log('MEASUREMENT TYPE NAME', name);
        this.setState({addExerciseModalData}, this.filterMeasurementTypes(value));
    }
    filterExercises(value){
        const el = this.state.exerciseList;
        let filteredList = [];
        if(value.length >= 2) {
            value = value.toLowerCase();
            filteredList = el.filter(item => item.name.toLowerCase().indexOf(value) >= 0 || item.shortName.toLowerCase().indexOf(value) >= 0);
            //console.log('FILTERED LIST', filteredList);
        }
        this.setState({filteredList});
    }
    createExerciseButton(exerciseItem, key){
        //const style= {color: getStatusColor('success')};
        const displayModal = true;
        const fn = displayModal ? ()=>this.setupExercise(exerciseItem) : ()=>this.props.addExerciseFn(exerciseItem);
        return (
            <ListItemButton key={key} onClick={fn} disabled={this.props.disabled}>
                    <ListItemIcon>
                        <Tooltip title={'Set Up Exercise'} placement={'bottom'}>
                            <ArrowBack/>
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText>
                        {exerciseItem.name}
                        <br/><small>{exerciseItem.shortName}</small>
                    </ListItemText>
            </ListItemButton>
        )
    }
    renderStyleLists(styleID){
        if(isEmpty(this.state.exerciseList)){
            return;
        }
        const filtered = this.state.exerciseList.filter(item=>parseInt(item.exerciseStyleID) === parseInt(styleID));
        let exercises = [];
        filtered.forEach((ex,k)=>{
            exercises.push(this.createExerciseButton(ex, k))
        });
        return exercises;
    }
    render(){
        const parentDisabled = this.props.disabled;
        const s = this.state;
        const smd = s.setupExerciseModalData;
        let list = [];
        const fl = s.filteredList;
        let addExerciseButton = null;
        if(s.exerciseTextInput.length >= 3){
            addExerciseButton =
                <ListItemButton onClick={this.displayAddExerciseModal.bind(this, true)}>
                    <ListItemIcon>
                        <AddCircleOutline/>
                    </ListItemIcon>
                    <ListItemText>
                        Add New Exercise
                    </ListItemText>
                </ListItemButton>

        }
        if(fl.length <= 5){
            fl.forEach((item,k) =>{
                list.push(this.createExerciseButton(item, k))
            })
        }

        //Build out Collapsible Accordions
        let accordionList = [];
        const exStyles = this.state.exerciseStyles;
        if(!isEmpty(exStyles)){
            exStyles.forEach((style, key) =>{
                const {lv_ID, label} = style;
                const exerciseList = this.renderStyleLists(lv_ID);
                const disabled = parentDisabled || isEmpty(exerciseList);
                accordionList.push(
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />} disabled={disabled}>
                            {label}
                        </AccordionSummary>
                        <AccordionDetails>
                            {exerciseList}
                        </AccordionDetails>
                    </Accordion>
                );
            })
        }

        return (
            <div>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Paper>
                        <TextField
                            fullWidth
                            name={'exerciseTextInput'}
                            onChange={this.handleChange.bind(this)}
                            value={s.exerciseTextInput}
                            label={parentDisabled === false ? 'Exercise Name': 'Click On A Day First'}
                            placeholder={'Type here to filter exercises'}
                            disabled={parentDisabled}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <List>
                        {addExerciseButton}
                        {list}
                    </List>
                </Grid>
                <Grid item xs={12}>
                    {accordionList}
                </Grid>
            </Grid>
            <ExerciseEditModal
                display={s.addExerciseModalData.display}
                exerciseID={null}
                name={''}
                shortName={''}
                exerciseStyleID={null}
                measurementTypeID={null}
                repTypeID={null}
                repUnitID={null}
                measurementUnitID={null}
                recordWeightDefault={1}
                recordSingleWeight={0}
                eachEnabled={0}
                active={1}
                exercisePreviouslyExecuted={0}

                exerciseStyles={s.exerciseStyles}
                exerciseUnits={s.exerciseUnits}

                closeFunction={()=>this.displayAddExerciseModal(false)}
                loadDataFunction={()=>this.loadExerciseData()}
            />

                {/****************APPLY EXERCISE MODAL*****************/}
                <ApplyExerciseModal
                    display={smd.display}
                    exerciseData={smd}
                    applyExercise={(data)=>this.applyExercise(data)}
                    closeExercise={()=>this.initExerciseSetup(initExerciseModalSetup())}
                    exerciseUnits={s.exerciseUnits}
                />
            </div>
        )
    }
}

export default ExerciseSelector;