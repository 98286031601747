import React from 'react';
import {connect} from 'react-redux'
import {setPageTitle} from "fit/system/UtilityFunctions";
import {Grid, Paper, Typography} from "@mui/material";
import {PAPER_STYLE} from "fit/system/FITConstants";

const Analytics=()=>{

    setPageTitle('Analytics');
    return (
        <div>
            <Typography variant={'h1'}>
                Analytics
            </Typography>
            <Paper style={PAPER_STYLE}>
                <Typography variant={'h3'}>Game Plan</Typography>
                    Eventually Analytics will go here.
                    <ul>
                        <li>Traverse Workouts: See RPE's Data for individual workouts</li>
                        <li>Traverse Workout Blocks: see data for blocks as a whole</li>
                        <li>View Team Stats</li>
                        <li>Drill Down to individual athletes and view their progress</li>
                        <li>Compare individual athlete progress based on sex, year, team as a whole</li>
                        <li>Other ideas/requests will come up. Flesh this out later on.</li>
                    </ul>

            </Paper>
        </div>
    );
}

const mapStateToProps=(state)=>{
    return {FITUser: state.FITUser};
}

export default connect(mapStateToProps)(Analytics);