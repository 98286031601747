import React from "react";
import {useState, useEffect} from "react";
import {Button, Grid, InputAdornment, MenuItem, TextField, Typography, Slide} from "@mui/material";
import {Add, Cancel, CheckCircle} from "@mui/icons-material";
import Modal from "fit/components/Dialogs/Modal";
import {getStatusColor} from "fit/system/UtilityFunctions";
import {BUTTON_INPUT_HEIGHT_MATCH} from "fit/system/FITConstants";
import {isEmpty} from "fit/system/UtilityFunctions";
import ExerciseUnits from "fit/system/ExerciseUnits";


const ApplyExerciseModal =(props)=>{
    //Variables
    const [exerciseData, setExerciseData] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    useEffect(()=>{
        if(!isEmpty(props.exerciseData) && props.exerciseData.display === true && dataLoaded === false){
            const data = structuredClone(props.exerciseData);
            data.percentageEnabled = data.allowPercentage === true;
            delete (data.display);
            setExerciseData(data);
            setDataLoaded(true);
        }
    },[props.exerciseData]);
    //Data Types for Selectors
    const eu = new ExerciseUnits(props.exerciseUnits);
    const repTypes = eu.getRepTypes();
    const closeModal=()=>{
        setDataLoaded(false);
        //setExerciseData({});
        props.closeExercise();
    }
    const toggleSetting=(name, value)=>{
        exerciseData[name] = !value;
        setExerciseData({...exerciseData});
    }
    const handleSelectorSetting=(e)=>{
        const {name, value} = e.target;
        exerciseData[name] = parseInt(value);
        setExerciseData({...exerciseData});
    }
    const submitExercise=()=>{
        const data = exerciseData;
        setDataLoaded(false);
        props.applyExercise(data);
    }

    const {name, recordWeightDefault, percentageEnabled, applyToGroup, groupIndex} = exerciseData;
    const recordWeightEnabled = recordWeightDefault === true;
    const toggledOn = <CheckCircle style={{color: getStatusColor('success')}}/>;
    const toggledOff = <Cancel style={{color: getStatusColor('error')}}/>;
    //REP SETTINGS
    const hasRepTypes = !isEmpty(repTypes);
    const repSet = exerciseData.repTypeID != null && parseInt(exerciseData.repTypeID) > 0;
    const repUnits = eu.getRepUnits(exerciseData.repTypeID);
    let repSetting = 'Unspecified';
    if(hasRepTypes){
        const repData = eu.getRepSetting(exerciseData.repTypeID);
        repSetting = repData.name;
    }
    //Measurement Settings
    const measurementTypes = eu.getMeasurementTypes();
    const measurementTypeID = exerciseData.measurementTypeID;
    const hasUnits = !isEmpty(measurementTypes);
    const measurementSet = parseInt(measurementTypeID) > 0;
    const measurementUnits = eu.getMeasurementUnits(measurementTypeID);
    return (
        <Modal
            open={props.display}
            title={'Exercise Setup'}
            closeFunction={()=>closeModal()}
            modalActions={
                <React.Fragment>
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        onClick={()=>submitExercise()}
                    ><Add/> Add Exercise</Button>
                    <Button
                        color={'secondary'}
                        variant={'contained'}
                        onClick={()=>closeModal()}
                    ><Cancel/> Cancel</Button>
                </React.Fragment>
            }
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h2'}>
                        {name}
                    </Typography>
                    <TextField
                        fullWidth
                        label={'Exercise Name'}
                        value={name}
                        inputProps={{readOnly: true}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={'Repetition Type'}
                        value={repSetting}
                        inputProps={{readOnly: true}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        select
                        label={'Repetition Units'}
                        name={'repUnitID'}
                        value={exerciseData.repUnitID}
                        onChange={(e) => handleSelectorSetting(e)}
                    >
                        {
                            repSet === false || hasRepTypes === false ?
                                <MenuItem value={0}>Select Measurement Type</MenuItem>
                                :
                                repUnits.map((item,k) =>{
                                    return <MenuItem value={item.id} key={k}>{item.name}</MenuItem>
                                })
                        }
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={'Measurement Unit Type'}
                        value={exerciseData.measurementType}
                        inputProps={{readOnly: true}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        select
                        label={'Measurement Units'}
                        name={'measurementUnitID'}
                        value={exerciseData.measurementUnitID}
                        onChange={(e) => handleSelectorSetting(e)}
                    >
                        {
                            measurementSet === false || hasUnits === false ?
                                <MenuItem value={0}>Select Measurement Type</MenuItem>
                                :
                                measurementUnits.map((item,k) =>{
                                    return <MenuItem value={item.id} key={k}>{item.name}</MenuItem>
                                })
                        }
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={'Log Athlete Data'}
                        value={`Logging ${recordWeightEnabled ? 'Enabled' : 'Disabled'}`}
                        inputProps={{
                            readOnly: true,
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment>{recordWeightEnabled ? toggledOn : toggledOff}</InputAdornment>
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        style={BUTTON_INPUT_HEIGHT_MATCH}
                        color={recordWeightEnabled ? 'secondary' : 'primary'}
                        onClick={() => toggleSetting('recordWeightDefault', recordWeightEnabled)}
                        variant={recordWeightEnabled ? 'outlined' : 'contained'}
                    >
                        {recordWeightEnabled ? 'Disable' : 'Enable'} Athlete Logging
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={'% Max Calculations'}
                        value={`Max Percentages ${percentageEnabled ? 'Enabled': 'Disabled'}`}
                        inputProps={{
                            readOnly: true,
                        }}
                        InputProps={{endAdornment: <InputAdornment>{percentageEnabled ? toggledOn : toggledOff}</InputAdornment>}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        style={BUTTON_INPUT_HEIGHT_MATCH}
                        color={percentageEnabled ? 'secondary' : 'primary'}
                        onClick={() => toggleSetting('percentageEnabled', percentageEnabled)}
                        disabled={exerciseData.allowPercentage === false}
                        variant={percentageEnabled ? 'outlined' : 'contained'}
                    >
                        {percentageEnabled ? 'Disable': 'Enable'} Percentage Calculations
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={'Superset Exercise / Circuit Set'}
                        value={applyToGroup ? 'Grouped With Exercises' : 'Not Grouped With Exercises'}
                        InputProps={{endAdornment: <InputAdornment>{applyToGroup ? toggledOn : toggledOff}</InputAdornment>}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        style={BUTTON_INPUT_HEIGHT_MATCH}
                        color={applyToGroup ? 'secondary' : 'primary'}
                        onClick={() => toggleSetting('applyToGroup', applyToGroup)}
                        variant={applyToGroup ? 'outlined' : 'contained'}
                    >
                        {applyToGroup ? 'Disable' : 'Enable'} Supersetting
                    </Button>
                </Grid>
            </Grid>
        </Modal>
    );
}

export default ApplyExerciseModal;