import React from 'react';
import {Grid, Typography, Paper, Tabs, Tab} from "@mui/material";
import Box from '@mui/material/Box';
import {connect} from 'react-redux'
import {PAPER_STYLE} from "fit/system/FITConstants";



class ProfilePage extends React.Component{
    state = {
        tabValue: 0
    }
    componentDidMount() {
        const {self, userID} = this.props;
    }
    setTabValue(value){
        this.setState({tabValue: value});
    }


    getTabs(){

    }
    render(){
        const {self, userID} = this.props;
        const title = self ? 'My Profile' : 'User Profile'
        const s = this.state;
        return(
            <div>
                <Typography variant={'h1'}>{title}</Typography>
                <Paper style={PAPER_STYLE}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant={'h3'}>Items to go here</Typography>
                            <ul>
                                <li>Reset Password (user's only)</li>
                                <li>Update User Profile</li>
                                <li>Maxes : Athletes</li>
                                <li>Analytics : Athletes</li>
                            </ul>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={s.tabValue} onChange={()=>this.setTabValue}>
                                <Tab label={'Overview'}/>
                                <Tab label={'Account'}/>
                            </Tabs>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>

            </div>
        )
    }
}

const mapStateToProps=(state)=>{
    return {FITUser: state.FITUser};
}

export default connect(mapStateToProps)(ProfilePage);