import User from './User';
import {enqueueSnackbar} from 'notistack';
import {isEmpty, getConfigColor, getStatusColor} from "./UtilityFunctions";
import {API_ENDPOINT, SESSION_TOKEN_KEY, SESSION_TIME_STRING, SNACKBAR_DEFAULT_PROPS, PERM_SET} from "./FITConstants";
/*
	-PROTOTYPE FOR MAKING SECURE CONNECTIONS TO THE SERVER
	-Receive url, method, formAction, outputNotifications, outputSuccessMessages
		-OutputNotifications: true/false (hide all messages - default should be true)
		-OutputSuccessMessages: true/false (allow for hiding of success messages - e.g. displaying tables)
	-Receive response from server
		-0. Check permission is allowed. If denied. Shut the whole thing down
		-1. Check any userdata provided. If so, update user interface
		-2. Display notifications depending on notifications setup
		-3. Output data to component/state as necessary
*/
const serverFailedMessage = '#RJS0N-E01: Please Contact Carol In HR.';

export class SecureConnect {
	constructor(endpoint, method = 'get'){
		this.url = API_ENDPOINT;
		this.endpoint = endpoint;
		this.method = method;
		this.displayNotifications = true;
		this.displaySuccessMessages = true;
		this.receiveFile = false;
		this.logConsole = false;
		this.action = '';
		this.headers = {};
		this.denyAccessCode = 401; //Response code that will automatically log a user out
		this.formData = {};
		this.tokenKey = SESSION_TOKEN_KEY;
		this.updateSessionTime = true;
		//console.log('created connection class');console.log(url);
	}
	setLogConsole(bool){
		this.logConsole = bool;
	}
	setAction(action){
		//Used for Posting to an endpoint
		this.action = action;
	}
	setDisplayNotifications(bool){
		//Determines whether notifications should be displayed
		this.displayNotifications = bool;
	}
	setDisplaySuccessMessages(bool){
		//Determines whether success notifications should be displayed
		this.displaySuccessMessages = Boolean(bool);
		if(bool) {
			//Automatically display notifications because success messages are getting displayed
			this.displayNotifications = Boolean(bool);
		}
	}
	setUpdateSessionTime(bool){
		this.updateSessionTime = Boolean(bool);
	}
	setFormData(formData){
		this.formData = formData;
	}
	setReceiveFile(bool){
		this.receiveFile = Boolean(bool);
	}
	getBodyString(){
		let user = new User();
		const token = user.getSessionToken();
		let bodyString = null;
		this.headers = {};
		if(Object.keys(this.formData).length > 0){
			this.formData[this.tokenKey] = token;
			this.formData['action'] = this.action;
			bodyString = '?' + Object.keys(this.formData).map(e =>
				`&${e}=${this.formData[e]}`
			).join('');
			let data = this.formData;
			data.action = this.action;
			bodyString = new URLSearchParams(this.formData);
			if(this.method === 'post') {
				this.headers = {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'};
			}
		}
		/*
		 let formDataValues = `?action=${tf.action}` + Object.keys(this.state.tableInputs).map(e =>
		 `&${e}=${this.state.tableInputs[e]}`
		 ).join('');

		*/
		return bodyString;
	}
	async transmit(){
		let data = this.connect().then(json =>{
			this.manageResponse(json);
			this.manageUserData(json);
		});
		return data;
	}
	async connect() {
		let user = new User();
		if(this.logConsole){
			console.log('connecting');
		}
		let body = this.getBodyString();
		let url = this.getEndPoint();
		if(this.method === 'get' && body != null){
			//When parameters are passed via formData rather than url string
			url = `${url}?${body}`;
			body = null;
		}
		if(this.method === 'get' && url.includes(`&${this.tokenKey}=`) === false){
			//Add in token for _GET requests
			url = `${url}&${this.tokenKey}=${user.getSessionToken()}`
		}

		try {
			if(this.logConsole) {
				console.log('SECURE CONNECT: CONNECTING TO :: ', url);
			}
			if(this.receiveFile === false) {
				let data = await (await fetch(url, {
					body: body,
					method: this.method,
					headers: this.headers
				}))
					.json()
					.then(data => this.manageUserData(data))
					.then(data => {
						this.manageServerResponse(data);
						return data;
					});
				//Set the sessionTimeStamp for handling idling of the user
				this.setSessionTimeStamp();
				return data;
			} else{
				//RECEIVING THE FILE
				fetch(url, {
					body: body,
					method: this.method,
					headers: this.headers
				}).then(res => res.blob())
					.then(blob =>{
						console.log(blob)
					})
			}
		} catch (e) {
			if(this.logConsole) {
				console.log('-----------------ERROR-----------------');
				console.log('%c' + e, 'font-size: 14px; color:red; font-weight: bold;');
				console.log('-----------------ERROR-----------------');
			}
			if(url.includes('system.php?action=authUser') === false){
				this.createSnackbarNotification(4, 'Server Connection Error', serverFailedMessage);
			}
			return this.clientSideError();
		}
	}
	manageServerResponse(json) {
		const response = json.response;
		const outputCodeLists = [2, 3, 4, 401];
		const serverResponseCode = parseInt(response.code);
		//0. If logged out/session terminated: set login form as interface
		const consoleList = [
			{style: 'font-size: 14px; color: red; font-weight: bold; background: #ffcccc;'}, //Error
			{style: 'font-size: 14px; color: white; font-weight: normal; background: #00CD1D;'}, //Success
			{style: 'font-size: 14px; color: white; font-weight: bold; background: #00CCFF;'}, //INFO
			{style: 'font-size: 14px; color: #FF5500; font-weight: bold; background: #FF0;'}, //WARNING
			{style: 'font-size: 14px; color: red; font-weight: bold; background: #ffcccc;'}, //ERROR
		];
		if (this.logConsole) {
			console.log('---------------SecureConnect.js.managerServerResponse()------------------');
			console.log(response);
			console.log('--------------/SecureConnect.js.managerServerResponse()------------------');
		}



		//Handle responses & errors
		//1. IF error connecting to server, display error (regardless of displayNotifications)
		//2. Based on code determine which kind of message to display
		//-Code 401 = Unauthorized Access Handle Separately
		//If. Code = 1 = success message. Display message
		if(this.displayNotifications && (
				(this.displaySuccessMessages && serverResponseCode === 1) ||
				(serverResponseCode !== 1 && outputCodeLists.filter(code => (code === serverResponseCode)))
			)){
				const style = consoleList[serverResponseCode].style;
				if(this.logConsole) {
					console.log('%c' + response.msg, style);
				}
				this.createSnackbarNotification(serverResponseCode, response.codeText, response.msg);
		}
	}
	manageUserData(json){
		const user = new User(this.logConsole);
		if(this.getResponseCode(json) === this.denyAccessCode){
			//Access Denied. Log the user out.
			user.setClientStorePermissions(false, {}); //Log the user out
			this.terminateSessionTimestamp();
			return;
		}
		if(json.userData){ //userData provided - update permissions
			const authorized = json.userData[PERM_SET] != null && !isEmpty(json.userData[PERM_SET]);

			if(this.logConsole) {
				console.log('=================SecureConnect.js=====================');
				console.log('SC AUTHORIZATION', authorized, json);
				console.info('USER DATA FOUND');
				console.log(json.userData);
				console.log('Setting User.JS clientStorePermissions()');
				console.log('================/SecureConnect.js=====================');
			}
			//update user data in state
			user.setClientStorePermissions(authorized, json.userData);
			delete json.userData;
		}
		return json;
	}
	setSessionTimeStamp(){
		if(this.updateSessionTime) {
			const now = Math.floor(Date.now() / 1000);
			localStorage.setItem(SESSION_TIME_STRING, now);
		}
	}
	terminateSessionTimestamp(){
		localStorage.removeItem(SESSION_TIME_STRING);
	}
	getURL(){
		return this.url;
	}
	getEndPoint(){
		return `${this.url}${this.endpoint}`;
	}
	getCompleted(json){
		//return bool if action was completed
		if(this.logConsole) {
			console.log(json);
		}
		return this.checkResponse(json) && json.response.completed === 1;
	}
	clientSideError(){
		//return a generic error if client cannot contact the server
		//console.log('SECURECONNECT.JS :: RETURNING DEFAULT ERROR');
		return {
			completed: 0,
			code: 0,
			codeText: 'Error',
			msg: '#CSJS4506 Unable to contact server.',
			msgTypeClass: 'alert-error',
			rowsAffected: 0
		};
	}
	createSnackbarNotification(code, codeText, message){
		const codeAssignments = {
			0: 'warning',
			1: 'success',
			2: 'info',
			3: 'warning',
			4: 'error',
			401: 'error'
		};

		const color = getStatusColor(codeAssignments[code]);

		//Assign color parameter to the SnackBar
		const variant = codeAssignments[code] != null ? codeAssignments[code] : 'default';
		const props = {
			...SNACKBAR_DEFAULT_PROPS,
			variant,
			style:{background: color}
		}
		enqueueSnackbar(message, props);
	}
	getResponse(json){
		//Return the response section of the server JSON response
		if(this.checkResponse(json)) {
			return json.response;
		} else{
			console.error('Connection: invalid response. Sorry. Blame the malformed response.');
			return this.clientSideError();
		}
	}
	getData(json){
		//Return the data section of the server JSON response
		if('data' in json) {
			return json.data.dataSet;
		} else{
			//Nothing to return empty set (nothing)
			return {}; //null;
		}
	}
	getTableStructure(json){
		if('data' in json){
			return json.data.tableStructure;
		} else{
			return {};
		}
	}
	getResponseCode(json){
		//Return responseCode or errorCode (4)
		return this.checkResponse(json) ? parseInt(json.response.code) : 4;
	}
	getResponseMessage(json){
		return json.response != null && json.response.msg != null ? json.response.msg : serverFailedMessage;
	}
	checkResponse(json){
		return json != null && json.response != null && json.response.code != null && json.response.completed != null;
	}
	getSnackBarProps(){
		return {
			anchorOrigin: {

			}
		}
	}
}

export default SecureConnect;