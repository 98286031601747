//__AIT__DELETE THIS - OLD THEME STYLING. NO LONGER APPLICABLE
export function toggleCollapsedNav(isNavCollapsed) {
    return {type: 'toggle_collapse_menu', isNavCollapsed};
}

export function setDrawerType(drawerType) {
    return {type: 'drawer_type', drawerType};
}

export function updateWindowWidth(width) {
    return {type: 'window-width', width};
}

export function setThemeColor(color, statusColors = {}) {
    return {type: 'theme_color', color, statusColors};
}

export function setDarkTheme(statusColors = {}) {
    return {type: 'dark_theme', statusColors};
}

export function changeDirection() {
    return {type: 'change-direction'};
}

export function changeNavigationStyle(layoutType) {
    return {
        type: 'change-navigation-style',
        payload: layoutType
    };
}

export function setHorizontalMenuPosition(navigationPosition) {
    return {
        type: 'horizontal_menu_position',
        payload: navigationPosition
    };
}

export function switchLanguage(locale) {
    return {
        type: 'switch-language',
        payload: locale
    };
}
