import React from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
//import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";


class Modal extends React.Component{
	render(){
		const size = this.props.size != null ? this.props.size : 'md';
		return (
			<Dialog
				open={this.props.open}
				onClose={this.props.closeFunction}
				fullWidth={true}
				maxWidth={size}
			>
				<DialogTitle onClose={this.props.closeFunction}>
					{this.props.title}
				</DialogTitle>
				<DialogContent>
					{this.props.children}
				</DialogContent>
				<DialogActions>
					{this.props.modalActions}
				</DialogActions>
			</Dialog>
		);
	}
}

export default Modal;