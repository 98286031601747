import React from "react";
import DynamicSelector from './DynamicSelector';
import * as DynamicRows from './DynamicRows'; //Row Elements
import {
	Box,
	TextField,
	Table,
	TableHead,
	TableRow,
	TableSortLabel,
	TableBody,
	TableCell,
	TablePagination,
	Button,
	MenuItem,
	Collapse,
	Typography
} from "@mui/material";
import {Tooltip, Grid, IconButton, InputAdornment, Fade, CircularProgress, LinearProgress} from '@mui/material';
import {getSpacing, convertDateStrToDateObj, isEmpty, paperStyles} from 'fit/system/UtilityFunctions'
import {Backspace, Search, Reorder, ArrowDownward} from '@mui/icons-material';
//import Nprogress from 'nprogress';
import {SecureConnect} from 'fit/system/SecureConnect';
import moment from 'moment';

//Date Picker Stuff
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers";
import {FormControl, FormHelperText} from "@mui/material";
import {hashString} from "fit/system/UtilityFunctions";


const SEARCH_NAME = 'searchCriteria';

class DynamicDataTable extends React.Component{
	state = {
		tableFormat: {
			tableHeaders: []/*{show_ID:"Show ID",lastName:"Customer Name",email:"Email",phone:"Phone"}*/,
			thClass: '',
			rowComponent: '',
			paginationEnabled: false,
			searchEnabled: false,
			sortEnabled: false,
			searchTips: [],
			selectors: [],
			dateRangeEnabled: false,
			startDateMinimum: null,
			endDateMaximum: null,
			method: 'get',
			tableID: '',
			action: '',
			url: '',
			defaultSort: '',
			defaultDirection: '',
		},
		presetVariables: {
			/* List of Preset Variables: (e.g. customerID to get a pre-filtered list of show payments or shows for that customer) */
		},
		styling: {
			paper: true
		},
		tableInputs: {
			pageInput: 1, //The is the pagination input
			rowsPerPage: this.getRowsPerPage(),
			sort: '',
			direction: '',
			searchCriteria: ''
		},
		tableRequests: {
			dataPending: true, //Is interface waiting on server
			initialTableLoad: true, //determines text to display on DynamicRowNoData Component: 'loading data' vs. 'no data'
			tableFormatLoaded: false
		},
		tableData: [/*Table Data stored here*/],
		//pagination options
		//Simple pagination won't count pages/or rows
		//set to false when pageData returned from the server
		useSimplePagination: false,
		simplePagination: {
			moreRowsAvailable: true,
			buttonText: 'Retrieve More Rows'
		},
		manageDataClientSide: false, //gets set to true when all data has been retrieved.
		pageData: {
			currentPage: 1,
			totalPages: 10,
			rowsPerPage: 25,
			totalRows: 250
		},
		requestHash: ''
	};
	checkMakeServerRequests(){
		return this.props.formatURL != null && this.props.formatURL !== '';
	}
	componentDidUpdate(prevProps) {
		//Check if new client side data has been loaded to update the table
		if(this.props.loadData === true) {
			if(this.checkMakeServerRequests() && this.state.tableRequests.dataPending === false) {
				//Need to make a request to the server for more data
				this.getData();
			} else{
				//Update the table
				this.manageClientDataBehaviors();
			}
		} else {
			let updateTableData = false;
			const prev = prevProps.presetVariables;
			const cur = this.props.presetVariables;
			if (!isEmpty(prev)) {
				const keys = Object.keys(prev);
				keys.forEach(label => {
					if (prev[label] !== cur[label]) {
						//props changed, update the table
						updateTableData = true;
					}
				})
			}
			if (updateTableData) {
				//PresetVariables changed, update the table.
				if (this.checkMakeServerRequests()) {
					//Reset the page to first page which will then get the table data.
					this.setPage(1);
				} else {
					this.manageClientDataBehaviors();
				}
			}
		}
	}
	componentDidMount(){
		//1. Load rowsPerPage from localStorage
		const rowsPerPage = this.getRowsPerPage();
		//2. Check props passed: is urlLoader or a copy of tableFormat
		if(this.checkMakeServerRequests()){
			//  A. If urlLoader - go to the endPoint, get the tableFormat
			let url = this.props.formatURL;
			const requestStructureString = '&requestingTableStructure=1';
			url = this.state.tableRequests.tableFormatLoaded === false ? `${url}${requestStructureString}` : url;
			const presets = this.props.presetVariables;
			if(!isEmpty(presets)){
				const keys = Object.keys(presets);
				keys.forEach(k =>{
					url+=`&${k}=${presets[k]}`
				});
			}
			const sc = new SecureConnect(url, this.state.tableFormat.method);
			sc.setDisplayNotifications(true);
			sc.setLogConsole(false);
			sc.setDisplaySuccessMessages(false);
			sc.connect().then(json => {
				const data = sc.getTableStructure(json);
				if(data != null && Object.keys(data).length) {
					let modifiedInputs = this.state.tableInputs;
					modifiedInputs.sort = data.defaultSort;
					modifiedInputs.direction = data.defaultDirection;
					modifiedInputs.rowsPerPage = rowsPerPage; //setRowsPerPage
					//DateRangeEnabled
					if(data.dateRangeEnabled != null && data.dateRangeEnabled === true){
						modifiedInputs.startDateRange = '';
						modifiedInputs.endDateRange = '';
					}
					//Assign selectors to tableInputs
					if(!isEmpty(data.selectors)){
						data.selectors.forEach(set => {
							modifiedInputs[set.variableName] = set.options[0][0];
						})
					}
					let tableRequests = this.state.tableRequests;
					tableRequests.dataPending = false;
					tableRequests.tableFormatLoaded = true;
					this.setState({
						tableFormat: data,
						tableInputs: modifiedInputs,
						tableRequests
					}, ()=>{
						if(!isEmpty(sc.getData(json))){
							this.processServerData(json);
						}
					});
					//data already retrieved. nothing else to do.
					//Minimize further api calls
				}
			});
		} else{ //JSON
			//  B. if TableFormat, Load the tableFormat into the system and carry on as usual
			this.manageClientDataBehaviors();
		}
	};
	setDataLoaded(){
		if(this.props.dataLoaded != null){
			//Data loaded function set
			this.props.dataLoaded();
		}
	}
	manageClientDataBehaviors(){
		if(this.checkMakeServerRequests()){
			//end. everything is server side
			return false;
		}
		//INIT Settings
		const f = this.props.tableFormat;
		let modifiedInputs = this.state.tableInputs;
		modifiedInputs.sort = f.defaultSort;
		modifiedInputs.direction = f.defaultDirection;
		modifiedInputs.rowsPerPage = this.getRowsPerPage();
		let tableRequests = this.state.tableRequests;
		tableRequests.tableFormatLoaded = true;
		tableRequests.dataPending = false;
		//Initialize the tableData - Perhaps this was loaded through props?
		let tableData = {};
		if(f.paginationEnabled === false && this.props.tableData != null && Object.keys(this.props.tableData).length >= 0){
			//Pagination is disabled (Receiving a complete dataset), table data received
			//Table Data sent through props
			tableData = this.props.tableData;
			//Automatically set the data loaded as its passed via props
			this.setDataLoaded();
		}
		//Set the state of the table
		this.setState({
			tableFormat: f,
			tableInputs: modifiedInputs,
			tableData,
			tableRequests
		},
		()=>{
			//Either contact the server if server side.
			console.log('STATE', this.state);
			this.getData();
		});
	}
	getFormID(){
		return `form-${this.state.tableFormat.tableID}`;
	}
	setSort(e){
		const s = this.state;
		//1. Set the sort based on the tableHeader clicked
		if(s.tableRequests.dataPending || !s.tableFormat.sortEnabled){return;}
		const sort = e.currentTarget.dataset.sortType;
		let direction = 'up';
		let sortClassDirection = 'sortedUp';
		if(s.tableInputs.sort === sort){
			//2. change direction of current tableheader (same th clicked)
			//direction = s.tableInputs.direction === 'up' ? 'down' : 'up';
			if(s.tableInputs.direction === 'up'){
				direction = 'down';
				sortClassDirection = 'sortedDown';
			}
		}
		//3. update state
		let updateTableInputs = s.tableInputs;
		updateTableInputs.sort = sort;
		updateTableInputs.direction = direction;
		updateTableInputs.pageInput = 1; //Reset the page
		this.setState({tableInputs: updateTableInputs});
		//4. Modify the data/view
		if(s.tableFormat.paginationEnabled && s.manageDataClientSide === false){
			//4. Run getData()
			this.getData();
		} else{ //Pagination Disabled - Sort data using javascript
			//4. Sort Table Data based on column ????
			this.browserSort(sort, direction);
		}
		//5. Set The Class
		const headers = document.querySelectorAll(`#${s.tableFormat.tableID} th`);
		//A. Initialize All headers
		headers.forEach(header => {
			header.classList.remove('sortedUp','sortedDown');
			}
		);
		//B. Set Header Class for the current sort
		e.currentTarget.classList.add(sortClassDirection);

	};
	browserSort(sortColumn, direction){
		const td = this.state.tableData;
		if(Object.keys(td).length === 0){
			//Nothing to sort - prevent further sorting
			return false;
		}
		const isDate = sortColumn.toLowerCase().includes('date');
		const sorted = td.sort(function(r1, r2){
			//Set default sort value based on sorting order
			let returned = direction === 'up' ? 1 : -1;
			//If not a date, sort via standard value. Otherwise convert to js date obj
			let v1 = !isDate ? r1[sortColumn] : convertDateStrToDateObj(r1[sortColumn]);
			let v2 = !isDate ? r2[sortColumn] : convertDateStrToDateObj(r2[sortColumn]);
			if(v1 > v2){
				return returned;
			}
			return returned*-1;
		});
		this.setState({tableData : sorted});
	}
	setPageInput(e){
		//Gets called onblur of pageInput: set the page to the input value
		if(e != null){
			this.setPage(e.currentTarget.value);
		}
	}
	setPreviousPage(){
		//decrement page by 1 - cannot go beyond page 1
		let pageInput = parseInt(this.state.tableInputs.pageInput);
		const page  = pageInput > 1 ? pageInput-1 : 1;
		this.setPage(page);
	}
	setNextPage(){
		//Increment Page by 1 - cannot exceed max pages
		const s = this.state;
		const {useSimplePagination} = s;
		let pageInput = parseInt(s.tableInputs.pageInput);
		let totalPages = parseInt(this.state.pageData.totalPages);
		let page;
		if((useSimplePagination && s.simplePagination.moreRowsAvailable) || (useSimplePagination === false && pageInput < totalPages)){
			//Get the next page if more rows available (using simple pagination)
			//OR
			//Current page is less than total pages
			page = pageInput+1;
		} else if(useSimplePagination === false && pageInput >= totalPages){
			//Get the last page of total pages when not using simple pagination
			page = totalPages;
		} else{
			//default to first page
			page = 1;
		}
		this.setPage(page);
	}
	setPage(page){
		if(this.state.tableRequests.dataPending){
			return; //Do nothing if waiting for response from server
		}
		//Update the state with the new page/pageInput
		let modified = this.state.tableInputs;
		modified.pageInput = parseInt(page); //Set the pageInput too
		this.setState({tableInputs: modified}, ()=>this.getData());
	}
	handleChange(e){
		let name, value;
		if(e.currentTarget === null) {
			name = e.target.name;
			value = e.target.value;
		} else{
			name = e.currentTarget.name;
			value = e.currentTarget.value
		}
		let tableInputs = this.state.tableInputs;
		tableInputs[name] = value;
		this.setState({tableInputs});
	}
	handleLiveFormChange(e){
		//same as handleChange
		//but gets data when called
		this.handleChange(e); //Same
		this.setPage(1); //Reset the page to the first one (which then gets the data)
	}
	handleDateChange(e, variableName) {
		const dateValue = e === null ? '' : moment(e._d).format('YYYY-MM-DD');
		let tableInputs = this.state.tableInputs;
		tableInputs[variableName] = dateValue;
		const validDate = dateValue !== 'Invalid date' && dateValue.substr(0,1) !== '0'
		this.setState({tableInputs},()=>{
			if(validDate){
				this.setPage(1); //getData
			}
		});
	}
	getStorageName(){
		return 'systemTableRows';
	}
	setRowsPerPage(e){
		//store rowsPerPage in localStorage - will be used on all other tables
		const rows = parseInt(e.target.value);
		localStorage.setItem(this.getStorageName(), rows);
		//Newer
		let tableInputs = this.state.tableInputs;
		tableInputs.rowsPerPage = rows;
		this.setState({tableInputs});
		this.setPage(1);
	}
	getRowsPerPage(){
		const rowsPerPage = localStorage.getItem(this.getStorageName());
		return !rowsPerPage || rowsPerPage < 1 ? 25 : rowsPerPage;
	}
	setSearch(){
		this.setPage(1); //setPage will getData()
	}
	getSearchTarget(){
		const tf = this.state.tableFormat;
		if(!tf.searchEnabled){
			//Search disabled: do nothing.
			return;
		}
		//Find the searchElement value
		const target = `#${this.getFormID()} input[name=${SEARCH_NAME}]`;
		return document.querySelector(target);
	}
	setSearchCriteria(reset = false){
		//Using this instead of onchange - loads before retrieving the data
		//Faster input with 100+ rows
		const tf = this.state.tableFormat;
		let ti = this.state.tableInputs;
		const searchElement = this.getSearchTarget();
		if(!tf.searchEnabled || searchElement == null){
			//Search disabled: do nothing.
			return;
		}


		//Original
		//let searchingFor = searchElement.value;
		//NEW
		let searchingFor = searchElement.value;
		if(reset) {
			//Get Element's Value
			searchingFor = '';
			searchElement.value = searchingFor;
		}
		//Apply value to state
		ti[SEARCH_NAME] = searchingFor;
		this.setState({tableInputs: ti});
	}
	formSubmit(e){
		e.preventDefault();
		this.setPage(1); //setPage will getData()
	}
	resetTable(){
		//0. Reset Search Criteria
		const {selectors} = this.state.tableFormat;
		this.setSearchCriteria(true);
		//1. Initialize all table inputs
		let ti = {...this.state.tableInputs};
		Object.keys(ti).forEach(k => {
			//Set default reset value
			let resetValue = '';
			if(!isEmpty(selectors)){
				//determine if value being reset is a selector
				const filtered = selectors.filter(item => item.variableName === k);
				if(filtered.length > 0){
					//selector found. apply first setting as reset value
					//options[0][0] => optionsList[first Setting][key]
					resetValue = filtered[0].options[0][0];
				}
			}
			ti[k] = resetValue;
		});
		//2. Reset Defaults
		const tableInputs = {
			...ti,
			pageInput: 1,
			rowsPerPage: this.getRowsPerPage(),
			sort: this.state.tableFormat.defaultSort,
			searchCriteria: '',
			direction: this.state.tableFormat.defaultDirection
		};
		//3. Reset State
		this.setState({tableInputs}, ()=>this.getData());
	}
	buildQueryString(keyValuePairs){
		return Object.keys(keyValuePairs).map(e =>
			`&${e}=${keyValuePairs[e]}`
		).join('');
	}
	getData(){
		const s = this.state;
		this.setSearchCriteria();
		const tf = s.tableFormat;
		if(!tf.url || s.tableRequests.dataPending){
			return;
		}
		if(tf.url && !s.tableRequests.dataPending) { //URL must be set and response not be pending
			let tableRequests = s.tableRequests;
			tableRequests.dataPending = true;
			this.setState({tableRequests, manageClientSideData: false}); //Set Request as Pending, turn off managing data client side
			const stateVarString = this.buildQueryString(s.tableInputs);
			const presets = this.props.presetVariables;
			let presetString = '';

			//PRESETS SET
			if(presets !== undefined && presets !== null){
				presetString =  this.buildQueryString(presets);
			}
			const formDataValues = `?action=${tf.action}${stateVarString}${presetString}`;
			const getURL = tf.url + formDataValues;
			const sc = new SecureConnect(getURL, tf.method);
			sc.setDisplayNotifications(true);
			sc.setDisplaySuccessMessages(false);
			sc.connect().then(json => {
				this.processServerData(json);
			});
		}
	}
	processServerData(json){
		const s = this.state;
		let {simplePagination, manageDataClientSide, tableRequests} = s;
		let sc = new SecureConnect();
		let tableData = sc.getData(json);
		const hasData = json.data != null;
		const useSimplePagination = hasData && json.data.hasOwnProperty('pageData') === false;
		const pageData = useSimplePagination || hasData === false ? {} : json.data.pageData;
		if(useSimplePagination){
			//Set pagination settings
			const hasMoreRows = !(tableData.length < s.tableInputs.rowsPerPage);
			simplePagination.moreRowsAvailable = hasMoreRows;
			simplePagination.buttonText = this.getButtonText();
			manageDataClientSide = hasMoreRows === false;
			//Apply Rows to table
			tableData = s.tableInputs.pageInput === 1 ? tableData : s.tableData.concat(tableData);
		}
		//tableRequests.dataPending = false;
		tableRequests.dataRequested = true;
		this.setState({
			useSimplePagination,
			manageDataClientSide,
			pageData,
			tableData,
			tableRequests
		}, ()=>{
			//Nprogress.done(); __AIT__
			this.setDataLoaded();

			//Delay in setting dataPending
			//Prevent loading duplicate data in table re-renders
			setTimeout(()=>{
				tableRequests.dataPending = false;
				this.setState({tableRequests})
			}, 50);
		});

	}
	getButtonText(){
		const texts = [
			'Get More Results',
			'Retrieve More Rows',
			'Gimme Gimme Gimme',
			'Get More Data',
			'Please Sir, May I Have Some Moar',
			'Gimme Moar Data!',
			'I Need Moar',
			'I Still Haven\'t Found What I\'m Looking For',
		];
		const key = parseInt(Math.random()*texts.length);
		return texts[key];
	}
	renderTableHeader(){
		const s = this.state;
		const headers = s.tableFormat.tableHeaders;
		const sortDirection = s.tableInputs.direction === 'up' ? 'asc' : 'desc';
		const sortColumn = s.tableInputs.sort;
		const sortEnabled = s.tableFormat.sortEnabled;
		return (
			<TableHead>
				<TableRow>
		            {headers.map((header,key) => {
		            	if(sortEnabled) {
				            return (
					            <TableCell
						            key={key}
						            align="left"
						            padding="default"
						            sortDirection="asc"
					            >
						            <Tooltip
							            title={`Sort By ${header[1]}`}
							            placement={'bottom-start'}
							            enterDelay={300}
						            >
										<span>
							            <TableSortLabel
								            active={header[0] === sortColumn}
								            direction={sortDirection}
								            onClick={this.setSort.bind(this)}
								            data-sort-type={header[0]}
							            > {header[1]}
							            </TableSortLabel>
										</span>
						            </Tooltip>
					            </TableCell>
				            )
			            } else{
		            		//Sort disabled - still show the desc
				            return (
					            <TableCell
						            key={key}
						            align="left"
						            padding="default"
					            > {header[1]}
					            </TableCell>
				            )
			            }
		            })}
		        </TableRow>
			</TableHead>
		);
	}
	renderTableElements(){
		let searchBar, dateRange, dataSection;
		const inputs = this.state.tableInputs;
		const tf = this.state.tableFormat;
		const tr = this.state.tableRequests;
		const disabledInput = tr.dataPending;
		const tableFormatLoaded = tr.tableFormatLoaded;
		let enableForm = false;
		const buttonStyle = {position: 'relative', top: getSpacing('iconAlignment')};
		const resetButton =
			<Tooltip
				title='Reset Filters'
				placement={'bottom-start'}
				enterDelay={300}
			>
				<span>
				<IconButton
					variant="contained"
					color="secondary"
					type="button"
					style={buttonStyle}
					disabled={disabledInput}
					onClick={this.resetTable.bind(this)}>
					<Backspace/>
				</IconButton>
				</span>
			</Tooltip>;

		//Enable Search field
		if(tf.searchEnabled && tableFormatLoaded){
			const searchInput =
				<TextField
					type="search"
					inputRef={ref =>{this.inputRef = ref}}
					name="searchCriteria"
					defaultValue={inputs.searchCriteria}
					onBlur={this.handleChange.bind(this)}
					placeholder="Search"
					helperText="Search The System"
					disabled={disabledInput}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<IconButton
									color={'primary'}
									disabled={disabledInput}
									type={'submit'}
								>
									<Search />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			enableForm = true;
			searchBar = <span>
					<Tooltip
						title={tf.searchTips}
					    placement={'bottom-start'}
					    enterDelay={300}
					>
						<span>
						{searchInput}
						</span>
					</Tooltip>
				<button type="submit" disabled={disabledInput} hidden="hidden">search</button></span>
		}
		//Date Range
		if(tf.dateRangeEnabled){
			enableForm = true;
			const maxDateString = tf.dateRange.max != null ? tf.dateRange.max : '2100-12-31';
			const minDateString = tf.dateRange.min != null ? tf.dateRange.min : '2000-01-01';
			const dateFormat = 'YYYY-MM-DD';
			const startDateVariable = 'startDateRange';
			const endDateVariable = 'endDateRange';
			const startDateValue = inputs.startDateRange === '' ? null : inputs.startDateRange;
			const endDateValue = inputs.endDateRange === '' ? null : inputs.endDateRange;

			const endDateMax = maxDateString;
			const startDateMin = minDateString;
			const startDateMax = endDateValue !== null ? inputs.endDateRange : maxDateString;
			const endDateMin = startDateValue !== null ? inputs.startDateRange : minDateString;
			const respectLeadingZeros = true;

			let startDatePicker =
				<FormControl>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							value={startDateValue}
							onChange={(e)=>this.handleDateChange(e, startDateVariable)}
							disabled={disabledInput}
							minDate={moment(startDateMin, dateFormat)}
							maxDate={moment(startDateMax, dateFormat)}
							shouldRespectLeadingZeros={respectLeadingZeros}
						/>
					</LocalizationProvider>
					<FormHelperText>From Start Date</FormHelperText>
				</FormControl>

				;
			let endDatePicker =
				<FormControl>
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<DatePicker
						value={endDateValue}
						onChange={(e) => this.handleDateChange(e, endDateVariable)}
						disabled={disabledInput}
						minDate={moment(endDateMin, dateFormat)}
						maxDate={moment(endDateMax, dateFormat)}
						shouldRespectLeadingZeros={respectLeadingZeros}
					/></LocalizationProvider>
				<FormHelperText>To End Date</FormHelperText>
				</FormControl>;
			//const startDatePicker = null;
			//const endDatePicker = null;
			dateRange = <span>{startDatePicker} {endDatePicker}</span>;
		}

		//Determine what to display in the data section of the table
		if(isEmpty(this.state.tableData)){
			//No Data to Display - Display NoData Row
			dataSection =
				<DynamicRows.NoData
					key={0}
					colSpan={tf.tableHeaders.length}
					cellText={'Nothing Here'}
				/>
		} else{
			//Data Available - Display the proper Row Component
			let RowComponent =  DynamicRows.getRowComponent(tf.rowComponent); //in DynamicRows.js
			dataSection = Object.keys(this.state.tableData).map(key =>
				<RowComponent
					key={key}
					rowData={this.state.tableData[key]}
				    rowFunctions={this.props.rowFunctions}
				/>
			);
		}
		//Form Selectors
		let formSelectors = null;
		if(!isEmpty(tf.selectors)){
			enableForm = true;
			formSelectors = tf.selectors.map((set,key)=>
				<span key={key}>
					<DynamicSelector
					key={key}
					label={set.label}
					variableName={set.variableName}
					options={set.options}
					handleChange={this.handleChange.bind(this)}
					dataPending={disabledInput}
					value={inputs[set.variableName]}
					handleLiveFormChange={this.handleLiveFormChange.bind(this)}
				/> </span>
			);
			/*
			formSelectors = tf.selectors.map((set,key)=>{
				console.log('SET OPTIONS', set.options);
				return (
					<span key={key}>
						<TextField
							select
							label={set.label}
							name={set.variableName}
							ref={set.variableName}
							handleChange={this.handleLiveFormChange.bind(this)}
							disabled={disabledInput}
							value={inputs[set.variableName]}
							style={{minWidth: '120px'}}
						>
							{
								set.options.map((option,key)=>{
									return (
										<MenuItem value={option[0]} key={key}>{option[1]}</MenuItem>
									)
								})
							}
						</TextField>
					</span>
				)
			});

			 */
		}
		//Build Form
		let form = null;
		const formID = this.getFormID();
		if(enableForm && tr.tableFormatLoaded){
			form = <form className="console-form" id={formID} onSubmit={this.formSubmit.bind(this)}>
				<span> {searchBar} {dateRange} {formSelectors} {resetButton}</span>
			</form>
			form = <Box
					id={formID}
					component={'form'}
					autoComplete={false}
					onSubmit={this.formSubmit.bind(this)}
				>
					{searchBar} {dateRange} {formSelectors}	{resetButton}
			</Box>;
		} else{
			if(tr.tableFormatLoaded === false){
				//Format not loaded. Create Empty form
				//Placeholder so spacing is semi consisent
				const fakeField =
					<TextField
						disabled={true}
					/>
				const fakeButton =
					<IconButton
						variant="contained"
						type="button"
						style={buttonStyle}
						disabled={true}
						><Backspace/>
					</IconButton>;
				form = <Box
					component={'form'}
				>
					<TextField disabled={true} placeholder='Just A Second' helperText={'Loading...'} style={{minWidth: '245px'}}/> {fakeField} {fakeField} {fakeButton}
				</Box>;
			}
		}
		return {data : dataSection, form}
	}
	renderPagination(){
		const s = this.state;
		const tableFormatLoaded = s.tableRequests.tableFormatLoaded;
		const paginationEnabled = s.tableFormat.paginationEnabled;
		let paginationSection;
		const pd = s.pageData;
		const rowsPerPage = pd.rowsPerPage != null ? pd.rowsPerPage : 25;
		const totalRows = pd.totalRows != null ? pd.totalRows : isEmpty(s.tableData) ? 0 : s.tableData.length;
		const page = pd.currentPage != null ? pd.currentPage - 1 : 1;
		if(paginationEnabled && tableFormatLoaded) {
			paginationSection =
				<TablePagination
				rowsPerPageOptions={[25, 50, 100, 200]}
				component="div"
				count={totalRows}
				rowsPerPage={rowsPerPage}
				page={page}
				labelRowsPerPage="Results"
				backIconButtonProps={{
					'aria-label': 'Previous Page',
					'onClick' : this.setPreviousPage.bind(this)
				}}
				nextIconButtonProps={{
					'aria-label': 'Next Page',
					'onClick' : this.setNextPage.bind(this)
				}}
				onPageChange={this.setPageInput.bind(this)}
				onRowsPerPageChange={this.setRowsPerPage.bind(this)}
			/>;
		}
		return paginationSection;
	}
	checkDisplayProgress(){
		const {dataPending} = this.state.tableRequests;
		//Data is pending wait 800 milliseconds
		return dataPending === true && new Promise((wait) => {
			setTimeout(() => {
				wait(true);
			}, 800);
		});
	}
	render(){
		const s = this.state;
		const tableStyles = paperStyles();
		const {dataPending} = s.tableRequests;
		const tableElements = this.renderTableElements();
		const {tableID} = s.tableFormat;
		const {moreRowsAvailable, buttonText} = s.simplePagination;
		const header = this.renderTableHeader();
		const pagination = s.useSimplePagination === false && !isEmpty(s.tableData) ? this.renderPagination() : null;
		const rowOptions =[25, 50, 100, 200];
		//Styles
		const divStyle={position: 'relative', ...tableStyles.divWrapper};
		const progressStyle={position: 'absolute', top: '144px', left: '50%', zIndex: 100, transitionDelay: dataPending ? '800ms' : '0ms'};
		const formPaddingStyle = tableElements.form != null ? {padding: getSpacing()} : null;
		const pendingStyle = dataPending ? {opacity: .25} : null;
		let propsStyle = this.props.style != null ? this.props.style : null;
		const finalStyle = {...propsStyle, ...tableStyles.table};
		const simplePaginationStyle = {marginTop: getSpacing('small')};
		const displayProgress = this.checkDisplayProgress();
		return (
			<div style={null}>
				<Grid container spacing={0}>
					<Grid item xs={12} style={null}>
						{tableElements.form}
					</Grid>
					<Grid item xs={12}>
						<Fade in={displayProgress}>
							<LinearProgress color={'primary'}/>
						</Fade>
					</Grid>
					<Grid item xs={12}>
						<Table
							id={tableID}
						    style={finalStyle}
							className={this.props.className}
						>
							{header}
							<TableBody style={pendingStyle}>
								{tableElements.data}
							</TableBody>
						</Table>
					</Grid>
					<Grid item xs={12}>
						<Collapse in={s.useSimplePagination === true}>
							<div style={{padding: getSpacing('small')}}>
								<Grid container spacing={8} direction={'row-reverse'}>
									<Grid item xs={4}>
										<Grid container spacing={8}>
											<Grid item xs={10} align={'right'}>
												<div style={simplePaginationStyle}>
													<Typography variant={'body1'}>
														Displaying <strong>{s.tableData.length}</strong> Results
													</Typography>
												</div>
											</Grid>
											<Grid item xs={2} align={'right'}>
												<Tooltip title={'Rows Per Page'} enterDelay={300} placement={'bottom-start'}>
													<span>
													<TextField
														select
														fullWidth
														size={'small'}
														margin={'dense'}
														value={s.tableInputs.rowsPerPage}
														onChange={this.setRowsPerPage.bind(this)}
														disabled={s.simplePagination.moreRowsAvailable === false}
													>{
														rowOptions.map((value, key)=> {
															return (
																<MenuItem key={key} value={value}>{value}</MenuItem>
															)
														}
													)}
													</TextField>
													</span>
												</Tooltip>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={8}>
										{/*Spacer*/}
									</Grid>
									<Grid item xs={12}>
										<Collapse in={s.simplePagination.moreRowsAvailable}>
											<Button
												fullWidth
												color={'primary'}
												variant={'contained'}
												onClick={this.setNextPage.bind(this)}
												disabled={dataPending || moreRowsAvailable === false}
											><ArrowDownward/> {buttonText} <Reorder/></Button>
										</Collapse>
									</Grid>
								</Grid>
							</div>
						</Collapse>
						{pagination}
					</Grid>
				</Grid>
				{/*
				<Fade in={displayProgress} style={progressStyle} unmountOnExit>
					<CircularProgress color={'secondary'}/>
				</Fade>
				*/}
			</div>
		);
	}
}

export default DynamicDataTable;