import React from 'react';
import {useState, useEffect } from 'react';
import {Typography, Grid, Paper} from "@mui/material";
import {PAPER_STYLE} from "fit/system/FITConstants";
import SecureConnect from "fit/system/SecureConnect";
import {getSpacing, isEmpty, setPageTitle} from "fit/system/UtilityFunctions";
import {TextField, MenuItem} from "@mui/material";
import {Table, TableHead, TableBody, TableCell, TableRow, Checkbox, Tooltip, Button} from "@mui/material";
import {DeviceSelectionList} from "fit/components/DynamicDataTable/DynamicRows";
import Alert from "fit/components/Alert";

const PUSH_ACTION = 'pushWorkoutToDevices';
const PULL_ACTION = 'pullWorkoutFromDevices';
const NO_ACTION = '__NO_ACTION__';



const DevicesList=()=>{
    const [deviceList, setDeviceList] = useState([]);
    const [deviceFilter, setDeviceFilter] = useState(-1);
    const [workoutList, setWorkoutList] = useState([]);
    const [action, setAction] = useState(NO_ACTION); //Push/Pull Workouts
    const [workoutListRequested, setWorkoutListRequested] = useState(false);
    const [deviceListRequested, setDeviceListRequested] = useState(false);
    const [activeWorkoutID, setActiveWorkoutID] = useState(null);
    const [selectedDeviceIDs, setSelectedDeviceIDs] = useState([]);

    //Component Did Mount
    useEffect(()=>{
        if(isEmpty(deviceList) && deviceListRequested === false){
            getDeviceList();
        }
        if(isEmpty(workoutList) && workoutListRequested === false) {
            getWorkouts();
        }
    });
    const calculateOverwriteWarning=()=>{
        return !isEmpty(deviceList.filter(d => parseInt(d.deviceAssigned) === 1 && selectedDeviceIDs.includes(d.userID))) && action === PUSH_ACTION ;
    }
    const setDeviceAction=(value)=>{
        setAction(value);
        if(value === PULL_ACTION){
            //PULLING WORKOUTS - SHOW ONLY DEVICES THAT ARE ASSIGNED
            setDeviceFilter(1);
        }
    }
    const commitAction=()=>{
        if(action === NO_ACTION){
            return; //Die
        }
        let sc = new SecureConnect('testapi.php', 'post');
        sc.setAction(action);
        let formData = {deviceIDList: selectedDeviceIDs};
        if(action === PUSH_ACTION){
            formData.workoutID = activeWorkoutID;
        }
        sc.setFormData(formData);
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                //Device Assignments changed. Update deviceList,
                getDeviceList();
                //might as well get workoutlist
                getWorkouts();
                //Reset selected DeviceIDs (previous job complete)
                setSelectedDeviceIDs([...[]]);
                //Reset Filter
                setDeviceFilter(-1);
            }
        })
    }
    const setActiveWorkout=(e)=>{
        const value = parseInt(e.target.value);
        setActiveWorkoutID(value);
    }
    const filterDevices=(e)=>{
        setDeviceFilter(e.target.value);
    }
    const toggleAllDevices=()=>{
        let newDeviceList = [];
        let devices = deviceList;
        if(deviceFilter === 1 || deviceFilter === 0){
            //Assigned or unassogined
            devices = deviceList.filter(d =>parseInt(d.deviceAssigned) ===deviceFilter);
        }
        if(!isEmpty(devices) && selectedDeviceIDs.length < devices.length){
            devices.forEach(item =>{
                newDeviceList.push(item.userID);
            })
        }
        setSelectedDeviceIDs([...newDeviceList]);
    }
    const toggleSelectedDevice=(deviceID)=>{
        let newDeviceList = selectedDeviceIDs;
        if(newDeviceList.includes(deviceID) === false){
            newDeviceList.push(deviceID);
        } else{
            const index = newDeviceList.indexOf(deviceID);
            if(index > -1){
                newDeviceList.splice(index, 1);
            }
        }
        setSelectedDeviceIDs([...newDeviceList]);
    }
    const getDeviceList=()=>{
        setDeviceListRequested(true);
        let sc = new SecureConnect('testapi.php?action=listDevices&getAllDevices=1');
        sc.setDisplaySuccessMessages(false);
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                setDeviceList(sc.getData(json));
            }
        })
    }
    const getWorkouts=()=>{
        //Return a list of today's workouts
        setWorkoutListRequested(true);
        let sc = new SecureConnect('testapi.php?action=listWorkouts&getToday=1');
        sc.setDisplaySuccessMessages(false);
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                setWorkoutList(sc.getData(json));
            }
        })
    }
    const overwriteAlert =
        <Alert
            status={'warning'}
            headline={'Overwriting Active Workout'}
            message={'One or more of your selected devices currently have an active workout. Assigning a new workout will reset the device to the new one.'}
            visible={calculateOverwriteWarning()}
            style={{marginTop: getSpacing('small'), marginBottom: getSpacing('small')}}
        />
    const noWorkouts = isEmpty(workoutList);
    const noDevices = isEmpty(deviceList);
    const emptyWorkoutItem = [{
        workoutBlockTitle: 'No Workout For Today'
    }]

    //Action Button Text
    let buttonText;
    let buttonIcon;
    if(action === PUSH_ACTION){
        buttonText = 'Activate Workout';
    } else if(action === PULL_ACTION){
        buttonText = 'Deactivate Workout';
    } else{
        buttonText = 'Select Action';
    }
    let buttonDisabled;
    const deviceSelected = !isEmpty(selectedDeviceIDs);
    if(deviceSelected && action === PULL_ACTION){
        buttonDisabled = false;
    } else if(deviceSelected && action === PUSH_ACTION && activeWorkoutID !== null){
        buttonDisabled = false;
    } else{
        buttonDisabled = true;
    }

    //Creating Device Tables
    let deviceRows = [];
    let rows = deviceList;
    if(deviceFilter === 1 || deviceFilter === 0){
        //Filtered to assigned or unassigned devices
        rows = deviceList.filter(d => parseInt(d.deviceAssigned) === deviceFilter)
    }
    const noRows = isEmpty(rows);
    if(noRows){
       const noDeviceText = noDevices ? 'No Devices Found' : noRows ? 'No Devices Fit Your Criteria' : 'No Devices';
       deviceRows = <TableRow hover><TableCell colSpan={5} align={'center'}>{noDeviceText}</TableCell></TableRow>;
    } else{
        deviceRows = rows.map((d,key)=>{
            return (
                <DeviceSelectionList
                    key={key}
                    assigned={d.deviceAssigned === 1}
                    blockTitle={d.blockTitle}
                    dayIndex={d.dayIndex}
                    firstName={d.firstName}
                    lastName={d.lastName}
                    selectedDeviceIDs={selectedDeviceIDs}
                    teamName={d.teamName}
                    toggleSelectedDevice={()=>toggleSelectedDevice(d.userID)}
                    userID={d.userID}
                    ownerName={d.ownerName}
                    ownerID={d.ownerID}
                />
            )
        })
    }
    const minWidthStyle = {minWidth: '240px'};
    setPageTitle('Weight Room Devices');
    return (
        <div>
            <Typography variant={'h1'}>Devices</Typography>
            <Paper style={PAPER_STYLE}>
                <Grid container spacing={0}>

                    <Grid item xs={12}>
                        <TextField
                            select
                            name={'action'}
                            helperText={'Device Action'}
                            value={action}
                            onChange={(e)=>setDeviceAction(e.target.value)}
                            disabled={noDevices}
                            style={minWidthStyle}
                        >
                            <MenuItem value={NO_ACTION}>Select Action</MenuItem>
                            <MenuItem value={PUSH_ACTION}>Activate/Assign Workout</MenuItem>
                            <MenuItem value={PULL_ACTION}>Deactivate Workout</MenuItem>
                        </TextField>
                        <span> </span>
                        <TextField
                            select
                            helperText={'Filter Devices'}
                            onChange={(e)=>filterDevices(e)}
                            disabled={noDevices}
                            value={deviceFilter}
                            style={minWidthStyle}
                        >
                            <MenuItem value={-1}>All Devices</MenuItem>
                            <MenuItem value={1}>Assigned Devices</MenuItem>
                            <MenuItem value={0}>Unassigned Devices</MenuItem>
                        </TextField>
                        <span> </span>
                        <TextField
                            select
                            name={'selectedWorkoutID'}
                            label={noWorkouts ? 'No Workouts' : 'Select Workout'}
                            helperText={'Today\'s Workouts'}
                            value={activeWorkoutID}
                            onChange={(e)=>setActiveWorkout(e)}
                            disabled={noWorkouts || action !== 'push'}
                            placeholder={'Select Workout'}
                            defaultValue={'Select Workout'}
                            style={minWidthStyle}
                        >
                            {
                                noWorkouts ? <MenuItem>No Active Workouts For Today</MenuItem> :
                                    workoutList.map((w,key)=>{
                                        return (
                                            <MenuItem key={key} value={w.workoutID}>{w.teamName}: {w.workoutBlockTitle}: Day {w.dayIndex+1}</MenuItem>
                                        )
                                    })
                            }
                        </TextField>
                        <span> </span>
                        <Button
                            color={'primary'}
                            style={{height: '53px'}}
                            size={'large'}
                            variant={'contained'}
                            disabled={buttonDisabled}
                            onClick={()=>commitAction()}
                        >
                            {buttonText}
                        </Button>

                    </Grid>
                    <Grid item xs={12}>
                        {overwriteAlert}
                    </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Tooltip title={'Toggle All Devices'} placement={'bottom'}>
                                            <Checkbox
                                                checked={selectedDeviceIDs.length === rows.length && rows.length > 0}
                                                onClick={()=>toggleAllDevices()}
                                                disabled={deviceList.length === 0}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>Device</TableCell>
                                    <TableCell>Assigned</TableCell>
                                    <TableCell>Team</TableCell>
                                    <TableCell>Current Workout</TableCell>
                                    <TableCell>Coach</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {deviceRows}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={12}>
                        {overwriteAlert}
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            color={'primary'}
                            variant={'contained'}
                            disabled={buttonDisabled}
                            onClick={()=>commitAction()}
                        >{buttonText}</Button>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default DevicesList;