import React from 'react';
import {useState, useEffect} from "react";
import {Paper, Grid, Typography} from '@mui/material'
import DynamicDataTable from "fit/components/DynamicDataTable";
import Alert from "fit/components/Alert";
import Modal from 'fit/components/Dialogs/Modal';
import {Table, TableCell, TableRow, TableBody, TableHead, Checkbox, Tooltip, Button} from "@mui/material";
import {isEmpty, getSpacing, setPageTitle} from "fit/system/UtilityFunctions";
import {PAPER_STYLE} from "fit/system/FITConstants";
import SecureConnect from "fit/system/SecureConnect";
import {DeviceSelectionList} from "fit/components/DynamicDataTable/DynamicRows";



const initModalData=()=>{
    return {
        display: false,
        method: 'push',
        workoutID: null,
        selectedDevices: []
    }
}

const WorkoutsList=(props)=>{
    const [loadData, setDataLoaded] = useState(true);
    const [modalData, setModalData] = useState(initModalData());
    const [deviceList, setDeviceList] = useState([]);
    const [selectedDeviceIDs, setSelectedDeviceIDs] = useState([]);
    useEffect(()=>{
        if(isEmpty(deviceList)){
            getDeviceList();
        }
    });
    const getDeviceList=()=>{
        let sc = new SecureConnect('testapi.php?action=listDevices&getAllDevices=1');
        sc.setDisplaySuccessMessages(false);
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                setDeviceList(sc.getData(json));
            }
        })
    }
    const calculateOverwriteWarning=()=>{
        return !isEmpty(deviceList.filter(d => parseInt(d.deviceAssigned) === 1 && selectedDeviceIDs.includes(d.userID))) && modalData.method === 'push';
    }
    const prepPushWorkout=(workoutID)=>{
        let data = initModalData();
        data.display = true;
        data.workoutID = workoutID;
        setModalData(data);
    }
    const prepPullWorkout=(workoutID)=>{
        const data = {
            display: true,
            method: 'pull',
            workoutID: workoutID,
            selectedDevices: []
        }
        setModalData(data);
    }
    const closeModal =()=>{
        setModalData(initModalData);
        setSelectedDeviceIDs([...[]]);
    }
    const toggleSelectedDevice=(deviceID)=>{
        //console.log('TOGGLING', deviceID);
        let newDeviceList = selectedDeviceIDs;
        if(newDeviceList.includes(deviceID) === false){
            newDeviceList.push(deviceID);
        } else{
            const index = newDeviceList.indexOf(deviceID);
            if(index > -1){
                newDeviceList.splice(index, 1);
            }
        }
        setSelectedDeviceIDs([...newDeviceList]);
    }
    const toggleAllDevices=()=>{
        let newDeviceList = [];
        if(!isEmpty(deviceList) && selectedDeviceIDs.length < deviceList.length){
            deviceList.forEach(item =>{
                newDeviceList.push(item.userID);
            })
        }
        setSelectedDeviceIDs([...newDeviceList]);
    }
    const toggleWorkout=()=>{
        //Either push (activate) or pull (deactivate) the workout from devices
        const pushing = modalData.method === 'push';
        const action = pushing ? 'pushWorkoutToDevices' : 'pullWorkoutFromDevices';
        let payload = {deviceIDList: selectedDeviceIDs};
        if(pushing){
            payload.workoutID = modalData.workoutID;
        }
        let sc = new SecureConnect('testapi.php','post');
        closeModal();
        sc.setAction(action);
        sc.setFormData(payload);
        sc.setDisplaySuccessMessages(true);
        sc.connect().then(json =>{
            if(sc.getCompleted(json)){
                //close the modal
                closeModal();
                //Reload the Table
                setDataLoaded(true);
                //Reload DeviceList (with new assignments)
                getDeviceList();
            }
        });
    }
    const overwriteAlert =
        <Alert
            status={'warning'}
            headline={'Overwriting Active Workout'}
            message={'One or more of your selected devices currently have an active workout. Assigning a new workout will reset the device to the new one.'}
            visible={calculateOverwriteWarning()}
            style={{marginTop: getSpacing('small'), marginBottom: getSpacing('small')}}
        />
    const dList = modalData.method === 'push' ? deviceList : deviceList.filter(d=> parseInt(d.workoutID) ===(modalData.workoutID));
    const getToday = props.getToday === true;
    const listPresets = getToday ? {getToday: 1} : {};
    const title = getToday ? "Today's Workouts" : 'Workouts';
    setPageTitle(title);
    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h1'}>Workouts</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={PAPER_STYLE}>
                        <DynamicDataTable
                            title={'Workouts Table'}
                            formatURL={'testapi.php?action=listWorkouts'}
                            loadData={loadData}
                            dataLoaded={()=>setDataLoaded(false)}
                            rowFunctions={{
                                prepPullWorkout: prepPullWorkout,
                                prepPushWorkout: prepPushWorkout
                            }}
                            presetVariables={listPresets}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <Modal
                open={modalData.display}
                title={modalData.method === 'pull' ? 'Deactivate Workout' : 'Activate Workout'}
                closeFunction={()=>closeModal()}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={'h2'}>{modalData.method === 'pull' ? 'Pull Workout From Devices' : 'Push Workout To Devices'}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {overwriteAlert}
                    </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Tooltip title={'Toggle All Devices'} placement={'bottom'}>
                                            <Checkbox
                                                checked={selectedDeviceIDs.length === deviceList.length && deviceList.length > 0}
                                                onClick={()=>toggleAllDevices()}
                                                disabled={deviceList.length === 0}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>Device</TableCell>
                                    <TableCell>Assigned</TableCell>
                                    <TableCell>Team</TableCell>
                                    <TableCell>Current Workout</TableCell>
                                    <TableCell>Coach</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                                dList.map((d,key) =>{
                                   const assigned = d.deviceAssigned === 1;
                                   return (
                                       <DeviceSelectionList
                                           key={key}
                                           assigned={assigned}
                                           blockTitle={d.blockTitle}
                                           dayIndex={d.dayIndex}
                                           firstName={d.firstName}
                                           lastName={d.lastName}
                                           selectedDeviceIDs={selectedDeviceIDs}
                                           toggleSelectedDevice={()=>toggleSelectedDevice(d.userID)}
                                           teamName={d.teamName}
                                           userID={d.userID}
                                           ownerName={d.ownerName}
                                           ownerID={d.ownerID}
                                       />
                                   )
                                })
                            }
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={12}>
                        {overwriteAlert}
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            color={'primary'}
                            variant={'contained'}
                            onClick={()=>toggleWorkout()}
                            disabled={isEmpty(selectedDeviceIDs)}
                        >{modalData.method === 'push' ? 'Activate' : 'Deactivate'} Workout</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            color={'secondary'}
                            variant={'contained'}
                            onClick={()=>closeModal()}
                        >Cancel</Button>
                    </Grid>
                </Grid>
            </Modal>
        </div>
    );
}

export default WorkoutsList;