import React from 'react';
import {Typography, Paper, Grid} from "@mui/material";
import {PAPER_STYLE} from "fit/system/FITConstants";
import DynamicDataTable from "fit/components/DynamicDataTable";
import {setPageTitle} from "fit/system/UtilityFunctions";
import User from 'fit/system/User';


class TeamsPage extends React.Component{
    state ={
        loadData: true,
    }
    componentDidMount() {

    }
    setDataLoaded(){
        this.setState({loadData: false});
    }
    render(){
        let user = new User();
        const teamCount = user.getTeams().length;
        const title = teamCount > 1 ? 'Teams' : 'Team';
        const {teamID} = this.props;
        let url = 'testapi.php?action=listTeam';
        if(teamID != null && user.checkTeamLevel(teamID,0)){
            url+=`&teamID=${teamID}`;
            console.log('TEAMID FOUND', teamID);
        }
        setPageTitle('Team/Athletes');
        return (
            <div>
                <Typography variant={'h1'}>{title} & Athletes</Typography>
                <Paper style={PAPER_STYLE}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DynamicDataTable
                                title={'Team Table'}
                                formatURL={url}
                                loadData={this.state.loadData}
                                dataLoaded={this.setDataLoaded.bind(this)}
                                rowFunctions={{}}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        )
    }
}

export default TeamsPage;