import User from "fit/system/User";
import {isEmpty} from "fit/system/UtilityFunctions";
import {SharedPermissions, RoutePermissions, LoggedOutPermissions, DevicePermissions} from "./PermissionsList";

/*
    -
    -
 */
//PermissionMenuTranslator Translates permissions into menu items
export const PermissionMenuTranslator = (permissionsList) =>{
    //Translate the RouteLists into datastructure comparable to menus
    const user = new User();
    let menu = [];
    let menuSectionIDs = [];
    permissionsList.forEach((item, j)=>{
        //Determine if the user is active within the department
        const {deptID, label} = item;
        const userInDept = user.checkDeptLevel(deptID,0);
        const hasRoutes = !isEmpty(item.routes);
        let menuKey; //The key of the menu array for translating variables
        //console.log(item.label, item, item.routes);
        //console.log(item.label, '| DEPT?',userInDept, '| ROUTES?', hasRoutes);
        if(userInDept && hasRoutes) {
            //User is active within dept and routes exist for the current dept
            item.routes.forEach((route, k) => {
                //Check whether the user is permitted to access each
                const hasPermission = user.checkDeptLevel(deptID, route.managerLevel);
                if(route.menuDisplay === true && hasPermission){
                    //Menu is permitted to be displayed.
                    //Create the menu entry if it hasn't been displayed already.
                    if(menuSectionIDs.includes(j) === false){
                        //Initialize Menu item Entry
                        menu.push({
                            label: label,
                            type: "section",
                            children: []
                        });
                        menuKey = menu.length-1;
                        //Add the entry to list of menu items
                        menuSectionIDs.push(j);
                    }
                    const routeIsFolder = route.subRoutes != null && !isEmpty(route.subRoutes);
                    const folderType = 'collapsible';
                    const linkType = 'nav-item'
                    const itemType = routeIsFolder ? folderType : linkType;

                    const navItemObject = {
                        uri: route.path,
                        label: route.label,
                        type: itemType,
                        icon: route.icon
                    }

                    const navFolderObject = {
                        label: route.label,
                        type: itemType,
                        icon: route.icon,
                        children: []
                    }

                    //Is a Collapsible folder with subroutes

                    //Create the entry for the child
                    const currentKey = menu[menuKey].children.length;
                    menu[menuKey].children[currentKey] = routeIsFolder ? navFolderObject : navItemObject;


                    if(routeIsFolder){
                        //Create Entries for subRoutes within folder//
                        let childrenItems = [];
                        route.subRoutes.forEach(sr =>{
                            const permitted = user.checkDeptLevel(deptID, sr.managerLevel);
                            if(sr.menuDisplay === true && permitted) {
                                childrenItems.push({
                                    uri: sr.path,
                                    label: sr.label,
                                    type: linkType
                                });
                            }
                        });
                        //Assign children Items to the children array of the folder
                        menu[menuKey].children[currentKey].children = childrenItems;
                    }
                }
            });
        }

    });
    return menu;
};

/*

const routesForPublic = [
    {
        path: "/",
        element: <Page component={Home} />
    },
];
*/
//PermissionRouteTranslator Translates permissions into routes for the app
const createRouteObj =(path, element)=>{
    return {
        path: path,
        /*element: <Page component={component} />*/
        element: element
    }
}

export const PermissionRouteTranslator = (authorized, deviceAccount) =>{
    const user = new User();
    let routes = [];
    let permissionsList;
    if(authorized && !deviceAccount){
        //Standard User
        permissionsList = RoutePermissions;
    } else if(authorized && deviceAccount){
        //Device Account - Restricted
        permissionsList = DevicePermissions;
    } else{
        //Not logged in
        permissionsList = LoggedOutPermissions;
    }
    if(authorized === false) {
        //Just pass permissions for logged out users
        return LoggedOutPermissions;
    } else{
        //AUTHORIZED USERS
        permissionsList.forEach((item)=>{
            const {deptID} = item;
            const hasRoutes = !isEmpty(item.routes);
            const userInDept = user.checkDeptLevel(deptID, 0);
            if(userInDept && hasRoutes){
                item.routes.forEach((route)=>{
                    const hasPermission = user.checkDeptLevel(deptID, route.managerLevel, route.strictLevel);
                    const hasSubRoutes = route.subRoutes != null && !isEmpty(route.subRoutes);
                    if(hasPermission && route.path != null){
                        routes.push({path: route.path, element: route.element});
                    }
                    if(hasSubRoutes){
                        route.subRoutes.forEach((sr)=>{
                            const permitted = user.checkDeptLevel(deptID, sr.managerLevel, sr.strictLevel);
                            if(permitted) {
                                routes.push({path: sr.path, element: sr.element});
                            }
                        });
                    }
                });
            }
        });
        //Add Shared Permissions for Authorized Users that aren't device accounts
        if(authorized && deviceAccount === false){
            SharedPermissions.forEach((route)=>{
                const {deptIDs} = route;
                if(Array.isArray(deptIDs)){
                    for(let k=0; k<deptIDs.length; k++){
                        const dept = deptIDs[k];
                        const permitted = user.checkDeptLevel(dept.deptID, dept.managerLevel, dept.strictLevel);
                        if(permitted){
                            routes.push({path: route.path, element: route.element});
                            //Terminate loop. User Is Permitted
                            break;
                        }
                    }
                } else{
                    //Automatically include
                    routes.push({path: route.path, element: route.element});
                }
            })
        }
    }
    return routes;
}