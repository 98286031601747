import React from 'react';
import ProfilePage from "fit/pages/shared/ProfilePage";


const MyProfileRoute =()=>{
    return (
        <ProfilePage self={true} userID={null}/>
    )
}

export default MyProfileRoute;