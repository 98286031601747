/*
	System settings for transitioning between different stages of development
		-Copy/Paste section into active section when transitioning
		-Terser will remove all other comments when transpiling
*/
import {getConfigColor} from './UtilityFunctions';
//URL Points based on staging status
/*
	//Development
	export const PRODUCTION_STATUS = false;
	export const API_ENDPOINT = 'https://localhost/fitdev/FitAPI/';
	export const LOG_CONNECTIONS = false;

	//LOCAL DEV
	export const PRODUCTION_STATUS = false;
	export const API_ENDPOINT = 'http://192.168.1.3/fitdev/FitAPI/';
	export const LOG_CONNECTIONS = false;


	//Staging
	export const PRODUCTION_STATUS = true;
	export const API_ENDPOINT = 'https://sabrejunkie.com/fitapi/';
	export const LOG_CONNECTIONS = true;

	//Production
	export const PRODUCTION_STATUS = true;
	export const API_ENDPOINT = 'https://placeholdername.net/api/';
	export const LOG_CONNECTIONS = false;


*/
//Imports for matching SnackBar to theme's status colors


/**************************************************/
// Interface Settings/URLs
/**************************************************/
export const PRODUCTION_STATUS = true;
export const API_ENDPOINT = 'https://sabrejunkie.com/fitapi/';
export const LOG_CONNECTIONS = true;




export const LOGIN_URL = '/login/';
export const ACTIVE_WORKOUT_URL = '/strength-and-conditioning/active-workout/';
export const MY_PROFILE_URL = '/my-profile/';

/**************************************************/
// Country Variables
/**************************************************/
export const USA = 'USA';
export const CANADA = 'Canada';
export const DATABASE_UNKNOWN_COUNTRY = 'UNKNOWN';
export const INTERFACE_OTHER_COUNTRY = 'other';

/**************************************************/
// Permission, User Settings etc
/**************************************************/

export const ATHLETE_LEVEL = 100;
export const COACHING_ASSISTANT_LEVEL = 200;
export const ASSISTANT_COACH_LEVEL = 1000;
export const COACH_LEVEL = 2000;
export const DIRECTOR_LEVEL = 3000;
export const OWNER_LEVEL = 4000;
export const OVERSEER_LEVEL = 5000;
export const GOD_LEVEL = 10000;

export const SESSION_IDLE_MINUTES = 15;
export const SESSION_TIME_STRING = 'lastActiveTime'; //For handling/limiting idling of users
export const GOD_LABEL = 'God Tier';
export const OVERSEER_LABEL = 'Director';
export const SESSION_TOKEN_KEY = 'CSRF_UUST';

//Manager Labels for permissions, change logs, etc
export const DEPT_SETTINGS = {
	ATHLETE: {},
	STRENGTH_AND_CONDITIONING: {
		deptID: ''
	},
	COACHING_STAFF: {

	},
	OPERATIONS: {

	},
}


export const DEPT_MANAGER_LABELS = [
	{label: 'Athlete', value: ATHLETE_LEVEL},
	{label: 'Coaching Assistant', value: COACHING_ASSISTANT_LEVEL},
	{label: 'Assistant Coach', value: ASSISTANT_COACH_LEVEL},
	{label: 'Coach', value: COACH_LEVEL},
	{label: 'Director', value: DIRECTOR_LEVEL},
	{label: 'Owner', value: OWNER_LEVEL},
	{label: GOD_LABEL, value: GOD_LEVEL}
];
//AIT manager labels for permissions, change logs, etc
//DELETE THIS EVENTUALLY IF NOT NECESSARY
/*
export const ACTOR_MANAGER_LABELS = [
	{label: 'Actor', value: 0},
	{label: 'Assistant Director', value: 1},
	{label: 'Director', value: DIRECTOR_LEVEL},
	{label: 'Regional Director', value: REGIONAL_DIRECTOR_LEVEL},
	{label: GOD_LABEL, value: GOD_LEVEL}
];
 */
/**************************************************/
//PERMISSION KEYS
export const PERM_SET = 'deptPermissions';
export const PERM_DEPT = 'depts';
export const PERM_TEAM = 'teams';
/**************************************************/
// DEPTS
/**************************************************/
export const ATHLETE_DEPT_ID = 4;
export const DEPT_PERMISSION_TYPE_ID = 1;
export const TEAM_PERMISSION_TYPE_ID = 2;
export const STRENGTH_AND_CONDITIONING_DEPT_ID = 1;
export const COACHING_STAFF_DEPT_ID = 2;
export const ADMIN_DEPT_ID = 10000;
/**************************************************/
// LABELS
/**************************************************/



/**************************************************/
// MISC -
/**************************************************/
export const GOOGLE_MAP_API_KEY = 'AIzaSyDSZYtyWHBkVRgWv4YDHoRaLTAtb2nqEHU'; //For Actor Show Details Map
export const DARK_THEME_MENU_COLOR = '#E0E0E0';
export const LIGHT_THEME_MENU_COLOR = '#1E1E1E';
/************************************************/
// STYLES
export const BUTTON_INPUT_HEIGHT_MATCH = {height: '53px'};
export const PAPER_STYLE = {padding: '24px'};
export const DISABLED_STYLE = {alpha: '50%'};
export const FUTURE_STYLE = {alpha: '80%'}

export const CAROLINA_BLUE = '#7aaed5';
export const UNCEDUBLUE = '#4b9cd3'; // #56a1d5;
export const CAROLINA_NAVY = '#16284c';

/*
const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
	'&.notistack-MuiContent-success': {
		backgroundColor: getConfigColor('success'),
	},
	'&.notistack-MuiContent-info': {
		backgroundColor: getConfigColor('info'),
	},
	'&.notistack-MuiContent-warning': {
		backgroundColor: getConfigColor('warning'),
	},
	'&.notistack-MuiContent-error': {
		backgroundColor: getConfigColor('error'),
	},
}));
*/

//MEASUREMENT SHIT

//Measurement Types

export const MASS_KEY = 51;
export const TIME_KEY = 52;
export const DISTANCE_KEY = 53;
//Rep Types
export const REP_KEY = 55;
export const REP_DISTANCE_KEY = 56;
export const REP_TIME_KEY = 57;



export const MEASUREMENT_TYPE_LABELS_KEY ={
	[MASS_KEY]: 'mass',
	[TIME_KEY]: 'time',
	[DISTANCE_KEY]: 'distance',
	[REP_KEY]: 'basicRepLabel',
	[REP_DISTANCE_KEY]: 'distance',
	[REP_TIME_KEY]: 'time'
}





export const SNACKBAR_DEFAULT_PROPS = {
	maxSnack: 4,
	anchorOrigin: {
		horizontal: 'left',
		vertical: 'bottom'
	},
	autoHideDuration: 3000,
}

export const SINGLE_FORM_FLEX_STYLE = {
	display: 'flex',
	flexDirection : 'column',
	justifyContent: 'center',
	alignItems: 'center !important',
	flexWrap: 'wrap',
	alignContent: 'center',
}