import React from 'react';
import {useState, useEffect} from "react";
import {Typography, Paper, Grid, Button} from "@mui/material";
import {Add} from "@mui/icons-material";
import DynamicDataTable from "fit/components/DynamicDataTable";
import ExerciseEditModal from "fit/components/Exercise/ExerciseEditModal";
import {getSpacing, setPageTitle} from "fit/system/UtilityFunctions";
import {PAPER_STYLE} from "fit/system/FITConstants";
import SecureConnect from "fit/system/SecureConnect";


const initExerciseModal=()=>{
     return {
        display: false,
        exerciseID: null,
        name: '',
        shortName: '',
        exerciseStyleID: null,
        measurementType: null,
        allowPercentage: 1,
        repUnitID: null,
        repTypeID: null,
        measurementUnitID: null,
        recordWeightDefault: 1,
        eachOnly: 0,
        unitLabelID: null,
        recordSingleWeight: 0,
        active: 1,
        exercisePreviouslyExecuted: 0,
     }
}


const ExerciseList=()=>{
    const [loadData, setLoadData] = useState(true);
    const [exerciseModalData, setExerciseModalData] = useState(initExerciseModal());
    const [exerciseStyles, setExerciseStyles] = useState([]);
    const [exerciseUnits, setExerciseUnits] = useState([]);

    useEffect(()=>{
        let sc = new SecureConnect('testapi.php?action=getExerciseVariables');
        sc.setDisplaySuccessMessages(false);
        sc.connect().then(json=>{
           if(sc.getCompleted(json)){
               console.log('DATA', sc.getData(json));
               const {exerciseStyles, exerciseUnits} = sc.getData(json);
               setExerciseStyles(exerciseStyles);
               setExerciseUnits(exerciseUnits);
           }
        });
    },[])

    const initExerciseCreation=()=>{
        let exerciseData = initExerciseModal();
        exerciseData.display = true;
        setExerciseModalData(exerciseData);
    }

    const loadExercise=(exerciseData)=>{
       //Receives the full row data from the exerciseList
       const ed = exerciseData;
       const modal = {
           display: true,
           exerciseID: ed.id,
           name: ed.name,
           shortName: ed.shortName,
           exerciseStyleID: ed.exerciseStyleID,
           allowPercentage: ed.allowPercentage,
           repTypeID: ed.repTypeID,
           repUnitID: ed.repUnitID,
           measurementTypeID: ed.measurementTypeID,
           measurementUnitID: ed.measurementUnitID,
           recordWeightDefault: ed.recordWeightDefault,
           recordSingleWeight: ed.recordSingleWeight,
           eachEnabled: ed.eachEnabled,
           active: ed.active,
           exercisePreviouslyExecuted: ed.exercisePreviouslyExecuted
       }
       setExerciseModalData(modal);
    }
    const closeExercise=()=>{
        setExerciseModalData(initExerciseModal());
    }
    const ed = exerciseModalData;
    setPageTitle('Exercises');
    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h1'}>Exercises</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        onClick={()=>initExerciseCreation()}
                        style={{marginBottom: getSpacing()}}
                    ><Add/> Create Exercise</Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper style={PAPER_STYLE}>
                    <DynamicDataTable
                        title={'Workouts Table'}
                        formatURL={'testapi.php?action=listExercises'}
                        loadData={loadData}
                        dataLoaded={()=>setLoadData(false)}
                        rowFunctions={{
                            loadExercise
                        }}
                    />
                </Paper>
            </Grid>
            <ExerciseEditModal
                display={ed.display}
                exerciseID={ed.exerciseID}
                name={ed.name}
                shortName={ed.shortName}
                exerciseStyleID={ed.exerciseStyleID}
                repTypeID={ed.repTypeID}
                repUnitID={ed.repUnitID}
                measurementTypeID={ed.measurementTypeID}
                measurementUnitID={ed.measurementUnitID}
                recordWeightDefault={ed.recordWeightDefault}
                recordSingleWeight={ed.recordSingleWeight}
                eachEnabled={ed.eachEnabled}
                active={ed.active}
                exercisePreviouslyExecuted={ed.exercisePreviouslyExecuted}

                exerciseStyles={exerciseStyles}
                exerciseUnits={exerciseUnits}

                closeFunction={closeExercise}
                loadDataFunction={()=>setLoadData(true)}
            />
        </div>
    )
}

export default ExerciseList;
