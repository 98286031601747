import React from "react";
import User from 'fit/system/User';
import {PermissionRouteTranslator} from "fit/system/FitJumboTranslators";
import {connect} from 'react-redux'
import {Routes, Route} from 'react-router-dom';
import DeniedRoute from "fit/routes/system/DeniedRoute";
/*
import TeamsRoute from "fit/routes/shared/TeamsRoute";
import AnalyticsRoute from "fit/routes/strength/AnalyticsRoute";
import ScheduleRoute from "fit/routes/strength/ScheduleRoute";
import WorkoutBlocksListRoute from "fit/routes/strength/WorkoutBlocksListRoute";
import WorkoutsListRoute from "fit/routes/strength/WorkoutsListRoute";
import WorkoutBlockEditorRoute from "fit/routes/strength/WorkoutBlockEditorRoute";
import DevicesRoute from "fit/routes/strength/DevicesRoute";
import ProfileRoute from 'fit/routes/shared/ProfileRoute';
import LoginRoute from 'fit/routes/system/LoginRoute'
import ResetPasswordRoute from "fit/routes/system/ResetPasswordRoute";
import ActiveWorkoutRoute from 'fit/routes/shared/ActiveWorkoutRoute';
import MyProfileRoute from "fit/routes/shared/MyProfileRoute";
import SwitchUserRoute from "fit/routes/system/SwitchUserRoute";
import {LoggedOutPermissions, RoutePermissions} from "../fit/system/PermissionsList";
*/
const FitAppRoutes = () => {
    let user = new User();
    const loggedIn = user.getLoggedInStatus();
    const deviceAccount = user.getDeviceAccount();
    const permittedRoutes = PermissionRouteTranslator(loggedIn, deviceAccount);
    /*
    console.log('====================fitAppRoutes.js====================');
    console.log('FITAPPROUTES, LOGGED IN', loggedIn, 'FITAPPROUTES DEVICE ACCOUNT', deviceAccount);
    console.log('PERMITTED ROUTES', permittedRoutes);
    console.log('===================/fitAppRoutes.js====================');

    */
    return (
        <React.Fragment>
            <Routes>
                {
                    permittedRoutes.map((route,key) =>{
                        return <Route path={route.path} element={route.element} key={key}/>
                 })
                }
                <Route path={'*'} element={<DeniedRoute/>}/>
            </Routes>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {FITUser: state.FITUser};
};
export default connect(mapStateToProps)(FitAppRoutes);

/*
let loggedInRoutes =
    <React.Fragment>
        <Route path={"/"} element={<Home/>}/>
        <Route path={"/strength-and-conditioning/teams/"} element={<TeamsRoute/>}/>
        <Route path={"/strength-and-conditioning/active-workout"} element={<ActiveWorkoutRoute/>}/>
        <Route path={"/strength-and-conditioning/teams/:teamID"} element={<TeamsRoute/>}/>
        <Route path={"/strength-and-conditioning/devices/"} element={<DevicesRoute/>}/>
        <Route path={"/strength-and-conditioning/analytics/"} element={<AnalyticsRoute/>}/>
        <Route path={"/strength-and-conditioning/create-workout-block/"} element={<WorkoutBlockEditorRoute/>}/>
        <Route path={"/strength-and-conditioning/workout-blocks/"} element={<WorkoutBlocksListRoute/>}/>
        <Route path={"/strength-and-conditioning/workout-blocks/:blockID/"} element={<WorkoutBlockEditorRoute/>}/>
        <Route path={"/strength-and-conditioning/workouts/"} element={<WorkoutsListRoute/>}/>
        <Route path={"/strength-and-conditioning/schedule/"} element={<ScheduleRoute/>}/>
        <Route path={"/strength-and-conditioning/schedule/:dateISO/"} element={<ScheduleRoute/>}/>

        <Route path={"/my-profile/"} element={<MyProfileRoute/>}/>
        <Route path={"/profiles/:userID/"} element={<ProfileRoute/>}/>
        <Route path={"/devices/:userID/"} element={<ProfileRoute/>}/>
        <Route path={"/reset-password/"} element={<ResetPasswordRoute/>}/>
        <Route path={"/switch-user/"} element={<SwitchUserRoute/>}/>

        <Route path={"/login/"} element={<LoginRoute/>}/>
        <Route path={"/reset-password/"} element={<ResetPasswordRoute/>}/>
        <Route path={"/reset-password/:resetKey/"} element={<ResetPasswordRoute/>}/>

    </React.Fragment>
*/