import React from 'react';
import {Typography, Button, Grid, Paper, TextField} from "@mui/material";
import {enqueueSnackbar } from 'notistack'
import {Navigate} from "react-router-dom";
import SecureConnect from "fit/system/SecureConnect";
import {Link} from 'react-router-dom';
import {Table, TableHead, TableRow, TableSortLabel,  TableBody, TableCell, IconButton} from "@mui/material/";
import {Edit} from "@mui/icons-material";
import {getSpacing} from "fit/system/UtilityFunctions";
import User from "fit/system/User";
import {connect} from 'react-redux';
import {LOGIN_URL} from "fit/system/FITConstants";
import InputMask from 'react-input-mask';
import MaskedInput from "fit/components/Form/MaskedInput";
import {PRODUCTION_STATUS, MY_PROFILE_URL} from "fit/system/FITConstants";
import SortableComponent from "fit/components/test/HOC2";
import HOCSort from "fit/components/test/HOCSort";

//DATE PICKER SHIT

import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers";
import {FormControl, FormHelperText} from "@mui/material";


class Home extends React.Component{
    constructor() {
        super();
        this.someRef = React.createRef();
    }
    state = {
        testData: {},
        dataLoaded: false,
        rowFunctions: null,
        startDate: '',
        repCount: 0,
        maskedTest: 1,
    }
    setDataLoaded(){
        this.setState({dataLoaded: true});
    }
    getAuthTest(){
        let sc = new SecureConnect('system.php?action=authUser','get');
        sc.setDisplayNotifications(true);
        sc.setLogConsole(false);
        sc.connect().then(json =>{
           if(sc.getCompleted(json)){

           }
        });
    }
    handleDate(e){
        console.log(e);
    }
    handleChange(e){
        console.log(e);
        const {name, value} = e.target;
        this.setState({[name]: value});
    }
    listRoutes(){
        const jsonString = localStorage.getItem('tempRoutes');
        const routes = jsonString !== '' ? JSON.parse(jsonString) : [];
        if(routes.length <= 0){
            return;
        }
        let listItems = [];
        routes.forEach(route =>{
            listItems.push(
                <li><Link to={route.uri}>{route.label} ({route.uri})</Link></li>
            )
        });
        return listItems;
    }
    render(){
        if(PRODUCTION_STATUS){
            return (<Navigate to={MY_PROFILE_URL}/>);
        }
        let user = new User();
        const userData = user.getUserData();
        let name = 'noName';
        if(user.getLoggedInStatus()){
            let info = userData.account.userInfo;
            name = `${info.firstName} ${info.lastName}`;
        }
        const s = this.state;
        const paperStyle = {padding: '24px'};
        const marginTop = {marginTop: getSpacing()};
        const editButton = <IconButton variant={'contained'}>
            <Edit/>
        </IconButton>;
        return (
          <div>
              <Typography variant = 'h1'>Heading. Hello {name},</Typography>
              <Grid container spacing={2}>
                  {/*
                  <Grid item xs={12}>
                      <Paper style={paperStyle}>

                              <DynamicDataTable
                                  title={'Team Table'}
                                  formatURL={'testapi.php?action=listTeam'}
                                  loadData={this.state.testData}
                                  dataLoaded={this.setDataLoaded.bind(this)}
                                  rowFunctions={{}}
                              />

                      </Paper>
                  </Grid>
                  */}
                  <Grid item xs={12}>
                      <Paper style={paperStyle}>
                          <Grid container spacing={2}>
                              <Grid item xs={3}>
                                  <Button
                                      fullWidth
                                      onClick={this.getAuthTest.bind(this)}
                                      color={'primary'}
                                      variant={'contained'}
                                  >
                                      Load Authorizations
                                  </Button>
                              </Grid>
                              <Grid item xs={3}>
                                  <Button
                                      fullWidth
                                      onClick={()=>{user.logout()}}
                                      color={'secondary'}
                                      variant={'contained'}
                                  >Logout</Button>
                              </Grid>
                              <Grid item xs={3}>
                                  <Button
                                      fullWidth
                                      onClick={()=>{
                                          console.log('-----------OUTPUTTING USER STORE()-----------');
                                          console.log('Logged In', user.getLoggedInStatus());
                                          console.log('Device Account', user.getDeviceAccount());
                                          console.log('permissions', user.getPermissions());
                                          console.log('----------/OUTPUTTING USER STORE()-----------');
                                      }}
                                      color={'secondary'}
                                      variant={'outlined'}
                                  >
                                      Output Store Permissions
                                  </Button>
                              </Grid>
                              <Grid item xs={3}>
                                <Link to={LOGIN_URL}>
                                    <Button
                                        fullWidth
                                        color={'primary'}
                                        variant={'outlined'}
                                    >Go To Login</Button>
                                </Link>
                              </Grid>
                              <Grid item xs={12}>
                                  <Button
                                      color={'primary'}
                                      variant={'contained'}
                                      fullWidth
                                      onClick={()=>{enqueueSnackbar('Have you thought about jumping off a bridge?',{autoHideDuration: 3000, variant: 'info'})}}
                                  >Click for snackbar</Button>
                              </Grid>
                              <Grid item xs={12}>
                                  <FormControl>
                                      <LocalizationProvider dateAdapter={AdapterMoment}>
                                          <DatePicker
                                            value={s.startDate}
                                            onChange={(e)=>{console.log(e)}}
                                            helperText={'Start Date'}
                                          />
                                      </LocalizationProvider>
                                      <FormHelperText>Start Date</FormHelperText>
                                  </FormControl>
                              </Grid>
                          </Grid>
                      </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <InputMask
                        mask={'99:99'}
                        value={s.repCount}
                        name={'repCount'}
                        maskChar={''}
                        onChange={(e)=>this.handleChange(e)}
                        label={'masked Input'}
                        placeholder={'--:--'}
                        helperText={'This is a masked input'}
                    >
                        {(inputProps) =>
                            <TextField
                                {...inputProps}
                            />}
                    </InputMask>
                  </Grid>
                  <Grid item xs={4}>
                      <MaskedInput
                          pattern={'zip'}
                          value={s.maskedTest}
                          name={'maskedTest'}
                          onChange={(e)=>this.handleChange(e)}
                          label={'masked Input'}
                          placeholder={'10'}
                          helperText={'This is another masked input'}
                      />
                  </Grid>
                  <Grid item xs={12}>
                      <input
                          type={'text'}
                          ref={this.someRef}
                      /> <button onClick={()=>this.someRef.current.focus()}>Focus Button</button>
                  </Grid>
                  <Grid item xs={6}>
                      <HOCSort/>
                  </Grid>
                  <Grid item xs={6}>
                  </Grid>
              </Grid>
          </div>
        );
    }
}
/*
const Home = () => {
    return (
        <div>
            <Typography variant={'h1'}>Balls</Typography>
            <h2>Sample blank page</h2>
            <p>Hahahaha go fuck yourself!</p>
            <p>This page is just to showcase the way you can add your own pages.</p>
            <p>Happy Coding!</p>
        </div>
    );
};
 */

const mapStateToProps = (state) => {
    return {FITUser: state.FITUser};
};

export default connect(mapStateToProps)(Home);