import React from 'react';
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import {SwapVert, Edit, DeleteForever, Lock} from "@mui/icons-material";
import {Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip} from "@mui/material";
import {IconButton} from "@mui/material";
import {arrayMoveImmutable} from 'array-move';
import {BORDER_COLOR, WBGStyles} from "../WorkoutBlockGenerator/WBGStyles";
import BlockExerciseCell from "../WorkoutBlockGenerator/BlockExerciseCell";

const containerStyle={
    display: 'grid',
    gap: '0',
    gridTemplateColumns: 'repeat(5, 1fr)',
    justifyItems: 'left',
    width: '100%'
}


//Get rid of this eventually
const SortableExercise = sortableElement(({obj, groupData}) => {

//    console.log('GROUP DATA', groupData.exerciseGroups);
//    console.log('OBJ', obj);
    const withinGroup = obj.groupIndex > 0;
    const borderColor = withinGroup ? WBGStyles.superSetStyles[obj.groupIndex] : 'transparent';
    let firstGroupItem = false;
    if(withinGroup){
        const {exerciseGroups} = groupData;
        //console.log(exerciseGroups, obj.groupIndex, obj.exerciseID);
        const index = groupData.exerciseGroups[obj.groupIndex].findIndex(ex => parseInt(ex) === obj.exerciseID);
        firstGroupItem = index === 0;
    }

    let primaryHandle, secondaryHandle;
    if(withinGroup === false || firstGroupItem){
        const primarySortMessage = withinGroup === false ? 'Reorder Exercise' : 'Reorder Group';
        primaryHandle =
        <Tooltip title={primarySortMessage} placement={'bottom'}>
            <IconButton>
                <Handle/>
            </IconButton>
        </Tooltip>;
    }
    if(withinGroup){
        secondaryHandle =
            <Tooltip title={'Reorder Exercise'} placement={'bottom'}>
                <IconButton>
                    <Handle/>
                </IconButton>
            </Tooltip>;
    }

    const handleWidth = '48px';
    const disabled = false;
    const borderStyle = {borderLeft: `5px solid ${borderColor}`, width: handleWidth};
    const handleStyle={width: handleWidth};
    const deleteMessage = disabled === false ? 'Delete Exercise' : 'Deletion Disabled';
    return (
        <li>
            <div style={containerStyle}>
                <div style={handleStyle}>
                    {primaryHandle}
                </div>
                <div style={borderStyle}>
                    {secondaryHandle}
                </div>
                <div style={{width: '100%'}}>
                    {obj.exerciseName}
                </div>
                <div>
                    <Tooltip title={deleteMessage} placement={'bottom'}>
                        <span>
                            <IconButton>
                                <DeleteForever/>
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
            </div>
            <Divider/>
        </li>
    );
});
const SortableExerciseGroup = sortableElement(({obj, groupData}) =>{
    const containerStyle={
        display: 'grid',
        gap: '0',
        gridTemplateColumns: 'repeat(5, 1fr)',
        justifyItems: 'left',
    }
//    console.log('GROUP DATA', groupData.exerciseGroups);
//    console.log('OBJ', obj);
    const withinGroup = obj.groupIndex > 0;
    const borderColor = withinGroup ? WBGStyles.superSetStyles[obj.groupIndex] : 'transparent';
    let firstGroupItem = false;
    if(withinGroup){
        const {exerciseGroups} = groupData;
        //console.log(exerciseGroups, obj.groupIndex, obj.exerciseID);
        const index = groupData.exerciseGroups[obj.groupIndex].findIndex(ex => parseInt(ex) === obj.exerciseID);
        firstGroupItem = index === 0;
    }

    let primaryHandle, secondaryHandle;
    if(withinGroup === false || firstGroupItem){
        primaryHandle = <IconButton>
            <Handle/>
        </IconButton>;
    }
    if(withinGroup){
        secondaryHandle =
            <IconButton>
                <Handle/>
            </IconButton>;
    }
    const borderStyle = {borderLeft: `5px solid ${borderColor}`};
    return (
        <li>
            <div style={containerStyle}>
                <div>
                    {primaryHandle}
                </div>
                <div style={borderStyle}>
                    {secondaryHandle}
                </div>
                <div>
                    ({obj.exerciseID}){obj.exerciseName}
                </div>
                <div>
                    <IconButton>
                        <Lock/>
                    </IconButton>
                </div>
                <div>
                    <IconButton>
                        <DeleteForever/>
                    </IconButton>
                </div>
            </div>
            <Divider/>
        </li>
    );
})
const SortableItem = sortableElement(({value}) =>
    <li style={{listStyleType: 'none'}}>
        <IconButton>
            <Handle/>
        </IconButton>
        {value}
    </li>
);

const handleWidth = '48px';
const borderColor = '#f00';
const borderStyle = {borderLeft: `5px solid ${borderColor}`, width: handleWidth};
const handleStyle={width: handleWidth};

const PrimaryExerciseGroup = sortableElement(({children}) => {
    return (
        <React.Fragment>
            <div style={containerStyle}>
                <div style={handleStyle}>
                    <IconButton>
                        <Handle/>
                    </IconButton>
                </div>
                <div>
                    {children}
                </div>
            </div>
            <Divider/>
        </React.Fragment>
    );
});

const ExerciseEntry =({obj, groupData})=>{
    const containerStyle={
        display: 'grid',
        gap: '0',
        gridTemplateColumns: 'repeat(5, 1fr)',
        justifyItems: 'left',
    }
    const withinGroup = obj.groupIndex > 0;
    const borderColor = withinGroup ? WBGStyles.superSetStyles[obj.groupIndex] : 'transparent';
    let innerGroupHandle = withinGroup ? <IconButton><Handle/></IconButton> : null;
    const borderStyle = {borderLeft: `5px solid ${borderColor}`};
    const handleSection ={display: 'block', width: '48px', border: '1px solid #aaa'};
    return (
        <div style={borderStyle}>
            <div style={handleSection}>{innerGroupHandle}</div>
            <div>__{obj.exerciseName}</div>
        </div>
    )
}


const translateExercises=(exercises)=>{
    let newList =[];
    let lastIndex = 0;
    exercises.foreach(ex =>{
       const groupIndex = parseInt(ex.groupIndex);
       const withinGroup = groupIndex > 0;
       const firstItem = withinGroup && groupIndex !== lastIndex;
       const subset = firstItem ? exercises.filter(exset => parseInt(exset.groupIndex) === groupIndex) : [];
       if(withinGroup === false || firstItem){
           ex.subset = subset;
           newList.push(ex);
       }
       lastIndex = groupIndex;
    });
    return newList;
}


//Useful
const Handle = sortableHandle(() => <SwapVert/>);



const SortList = sortableContainer(({children}) =>{
    return <List>{children}</List>
});


const SortableContainer = sortableContainer(({children}) => {
    return <div>{children}</div>;
});

class SortStart extends React.Component {
    state = {
        collections: [[0, 1, 2], [0, 1, 2, 3, 4], [0, 1, 2]],
        set: [
            {
                exerciseID: 1,
                exerciseName: 'Squat',
                groupIndex: 1,
            },
            {
                exerciseID: 2,
                exerciseName: 'Box Jump',
                groupIndex: 1,
            },
            {
                exerciseID: 3,
                exerciseName: 'Bench Press',
                groupIndex: 2,
            },
            {
                exerciseID: 4,
                exerciseName: 'Push Up',
                groupIndex: 2,
            },
            {
                exerciseID: 5,
                exerciseName: 'Pull Up',
                groupIndex: 3,
            },
            {
                exerciseID: 6,
                exerciseName: 'Chin up',
                groupIndex: 3,
            },
            {
                exerciseID: 7,
                exerciseName: 'DB Lunges',
                groupIndex: 0,
            },
            {
                exerciseID: 8,
                exerciseName: 'DB RDL',
                groupIndex: 0,
            },
        ]
    };




    onSortEnd = ({oldIndex, newIndex, collection}) => {
        const {collections} = this.state;
        let newCollections = [...this.state.collections];
        newCollections[collection] = arrayMoveImmutable(
            collections[collection],
            oldIndex,
            newIndex,
        );
        this.setState({collections: newCollections});
    };

    mySort=({oldIndex, newIndex})=>{
        const {set} = this.state;
        let newSet = arrayMoveImmutable(
            // Need to pass the original array, the old index and the new index
            set,
            oldIndex,
            newIndex,
        )
        this.setState({set: newSet});
    }
    groupedSort=({oldIndex, newIndex})=>{
        const {set} = this.state;
        console.log('OLD', oldIndex, 'NEW', newIndex);
        /*
        let newSet = arrayMoveImmutable(
            // Need to pass the original array, the old index and the new index
            set,
            oldIndex,
            newIndex,
        )
        this.setState({set: newSet});

         */
    }


    getGroupList(){
        let exerciseGroupIndexes = [];
        let groupObj = {};
        let exerciseGroups = {}
        const exercises = this.state.set;
        let formattedGroupList = [];
        let lastGroupIndex = -1;
        exercises.forEach(ex =>{
            const groupIndex = ex.groupIndex != null ? parseInt(ex.groupIndex) : null;
            if(groupIndex != null && groupIndex > 0){
                if(exerciseGroupIndexes.includes(groupIndex) === false) {
                    exerciseGroupIndexes.push(groupIndex);
                    groupObj[groupIndex] = {};
                    exerciseGroups[groupIndex] = [];
                }
                groupObj[groupIndex][ex.exerciseID] = ex.exerciseName;
                exerciseGroups[groupIndex].push(ex.exerciseID);
                if(lastGroupIndex !== groupIndex) {
                    //Push first element of the group
                    formattedGroupList.push(ex);
                    lastGroupIndex = groupIndex;
                } else{

                }
            } else{
                formattedGroupList.push(ex);
            }



        });
        return {indexes: exerciseGroupIndexes, grouped: groupObj, exerciseGroups, formattedGroupList}
    }


    render() {
        const {collections, set} = this.state;
        //console.log('SET', set);
        //console.log('TRANSLATED', this.translateGroupExercises(set));
        const groupData = this.getGroupList();
        const newSet = groupData.formattedGroupList;
        console.log(newSet);
        let lastGroupIndex = 0;
        let exerciseElements = [];
        let primaryGroupKey = 0;

        const deleteFn = ()=>alert('delete me');

        set.forEach((exercise, primaryIndex) =>{
            const currentGroup = parseInt(exercise.groupIndex);
            const withinGroup = currentGroup > 0;
            const firstGroupItem = withinGroup && currentGroup !== lastGroupIndex;
            if(withinGroup === false || firstGroupItem){
                //Within first set of sortable items
                if(firstGroupItem){
                    let subListElements = [];
                    //Filter all exercises based on the group
                    const filteredExercises = set.filter(exercise => parseInt(exercise.groupIndex) === currentGroup);
                    filteredExercises.forEach((sub, subIndex) =>{
                        subListElements.push(
                            <React.Fragment key={subIndex}>
                                {/*   <SortableExercise obj={sub} index={primaryIndex} groupData={groupData}/>  */}
                                <BlockExerciseCell exercise={sub} deleteFn={deleteFn}/>
                            </React.Fragment>
                        )
                    });
                    //Create subContainer
                    exerciseElements.push(
                        <SortableContainer onSortEnd={this.groupedSort} useDragHandle>
                            {subListElements}
                        </SortableContainer>
                    )
                } else{
                    //Standard item
                    exerciseElements.push(
                        <React.Fragment key={primaryGroupKey}>
                            <PrimaryExerciseGroup index={primaryGroupKey}>
                                <BlockExerciseCell exercise={exercise} deleteFn={deleteFn}/>
                            </PrimaryExerciseGroup>
                        </React.Fragment>
                    )
                }
                primaryGroupKey++;
            }
            //const next = set[index+1] != null ? set[index+1] : null;
            //const nextWithinGroup = next != null && next.groupIndex != null && next.groupIndex > 0;
            lastGroupIndex = currentGroup;
        });







        const testGroupStyle = {width: '350px'};

        const width100 = {width: '100%'};

        return (
            <div>







                <h1>Basic Style - Not Grouped</h1>
                <div style={testGroupStyle}>
                    <SortableContainer onSortEnd={this.mySort} useDragHandle>
                        {
                            set.map((exercise, index) =>{

                              if(true){
                                  return(
                                      <React.Fragment key={index}>
                                          <SortableExercise
                                              obj={exercise}
                                              index={index}
                                              groupData={groupData}
                                          />
                                      </React.Fragment>
                                  );
                              }


                            })
                        }
                    </SortableContainer>
                </div>
            {/*
            <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                {collections.map((items, index) => (
                    <React.Fragment key={index}>
                        <strong>LIST {index}</strong>
                        <ul>
                            {items.map((item, i) => (
                                <SortableItem
                                    key={item}
                                    value={`Item ${item}`}
                                    index={i}
                                    collection={index}
                                />
                            ))}
                        </ul>
                    </React.Fragment>
                ))}
            </SortableContainer>

            */}
            </div>
        );
    }
}

export default SortStart;

{/*

<h1>Ideal</h1>
<div style={{width: '500px'}}>
    <List>
        <ListItem
            disablePadding
            style={width100}
            secondaryAction={
                <Tooltip title={'Delete Exercise'} placement={'bottom'}>
                    <IconButton
                        edge="end"
                        aria-label="Delete Exercise"
                        onClick={deleteFn}
                    >
                        <DeleteForever />
                    </IconButton>
                </Tooltip>
            }
        >
            <ListItemIcon>
                <Tooltip title={'Change Order'} placement={'bottom'}>
                                    <span>
                                    <IconButton>
                                        <SwapVert/>
                                    </IconButton>
                                    <IconButton>
                                        <Edit/>
                                    </IconButton>
                                        </span>
                </Tooltip>
            </ListItemIcon>
            <ListItemText id={1} primary={'Thing'} />
        </ListItem>
        <ListItem
            disablePadding
            style={width100}
            secondaryAction={
                <Tooltip title={'Delete Exercise'} placement={'bottom'}>
                    <IconButton
                        edge="end"
                        aria-label="Delete Exercise"
                        onClick={deleteFn}
                    >
                        <DeleteForever />
                    </IconButton>
                </Tooltip>
            }
        >
            <ListItemIcon>
                <Tooltip title={'Change Order'} placement={'bottom'}>
                    <IconButton>
                        <SwapVert/>
                    </IconButton>
                </Tooltip>
            </ListItemIcon>
            <ListItemText id={1} primary={'Box Jump'} />
        </ListItem>
        <ListItem
            disablePadding
            style={width100}
            secondaryAction={
                <Tooltip title={'Delete Exercise'} placement={'bottom'}>
                    <IconButton
                        edge="end"
                        aria-label="Delete Exercise"
                        onClick={deleteFn}
                    >
                        <DeleteForever />
                    </IconButton>
                </Tooltip>
            }
        >
            <ListItemIcon>
                <Tooltip title={'Change Order'} placement={'bottom'}>
                    <IconButton>
                        <SwapVert/>
                    </IconButton>
                </Tooltip>
            </ListItemIcon>
            <ListItemText id={1} primary={'BackSquat'} />
        </ListItem>
    </List>



</div>




<hi>Grouped</hi>
<div style={{width: '500px', border: '1px solid #000'}}>
    <div style={containerStyle}>
        <SortableContainer onSortEnd={this.groupedSort} useDragHandle>
            {exerciseElements}
        </SortableContainer>
    </div>
</div>

*/}
