import React from 'react';
import Analytics from "fit/pages/strength/Analytics";

class AnalyticsRoute extends React.Component{
    render(){
        return (
            <Analytics/>
        )
    }
}

export default AnalyticsRoute;