
class ExerciseUnits{
    constructor(unitData = []) {
        this.unitData = unitData;
        this.repTypeID = 4;
        this.measurementTypeID = 6;
        this.measurementMassKey = 16; //For Calculating percentages calculation
        this.distanceRepTypeID = 11; //For labeling
        this.weightRepTypeID = 10; //For determining if the unit Type is a weight
        this.timeRepTypeID = 12; //For determining if unit is time
    }
    getMeasurementTypes(){
        if(this.hasNoExerciseUnits()){
            return [];
        }
        return this.unitData.filter(eu=>parseInt(eu.typeID) === parseInt(this.measurementTypeID));
    }
    getMeasurementUnitsByTypeID(unitTypeID){
        if(this.checkID(unitTypeID)) {
            return this.unitData.filter(eu => parseInt(eu.typeID) === parseInt(unitTypeID));
        }
        return [];
    }
    getMeasurementUnits(measurementTypeID){
        if(this.checkID(measurementTypeID)){
            const filtered = this.unitData.filter(item => parseInt(item.measurementTypeID) === parseInt(measurementTypeID));
            if(filtered.length > 0){
                return filtered;
            }
        }
        return [];
    }
    getRepSetting(id){
        if(this.checkID(id)){
            const index = this.unitData.findIndex(item => parseInt(item.id) ===  parseInt(id));
            if(index > -1){
                return this.unitData[index];
            }
        }
        return {};
    }
    getRepTypes(){
        if(this.hasNoExerciseUnits()){
            return [];
        }
        return this.unitData.filter(eu => parseInt(eu.typeID) === this.repTypeID);
    }
    getExerciseUnitData(repID){
        if(this.checkID(repID)){
            const index = this.unitData.findIndex(eu => parseInt(eu.id) === parseInt(repID));
            if(index > -1){
                return this.unitData[index];
            }
        }
        return {};
    }
    getActiveWorkoutRepLabel(repUnitID){
        //Return distance labels for the activeWorkout form
        //Standard Lifting reps and time reps return blank strings
        let repLabel = '';
        if(this.checkID(repUnitID)){
            const index = this.unitData.findIndex(units => parseInt(units.id) === parseInt(repUnitID));
            if(index > -1){
                const repData = this.unitData[index];
                //console.log('REP DATA', repData,'|', repData.repTypeID, this.distanceRepTypeID);
                repLabel = parseInt(repData.repTypeID) === this.distanceRepTypeID ? repData.activeWorkoutRepLabel : '';
            }
        }
        return repLabel;
    }
    getRepUnits(repTypeID){
        if(this.checkID(repTypeID)) {
            const filtered = this.unitData.filter(item => parseInt(item.repTypeID) === parseInt(repTypeID) && parseInt(item.rep) === 1);
            if (filtered.length > 0) {
                return filtered;
            }
        }
        return [];
    }
    validateExerciseInput(value, exUnitData, log = false){
        const {maskPattern} = exUnitData;
        const stringLength = `${value}`.length;
        const isTime = this.isTime(exUnitData);
        const isMeasurement = maskPattern.includes("'") || maskPattern.includes('"');
        const validateWeight = exUnitData.validateWeight != null && parseInt(exUnitData.validateWeight) === 1;
        if(log) {
            console.log(value, 'exUnitData', exUnitData);
            console.log('TIME?', isTime, 'MEASUREMENT?', isMeasurement, 'Weight?', validateWeight);
        }
        if(isTime || isMeasurement){
            if(log) {
                console.log('CHECKING TIME/MEASUREMENT', maskPattern.length, stringLength, 'VALID?', maskPattern.length === stringLength);
            }
            return maskPattern.length === stringLength; //STRING LENGTH MATCHES THE MASKED PATTERN
        } else{
            //Numeric - make sure the value isn't empty and number is greater than 0
            const testValue = stringLength > 0 && parseFloat(value) > 0;
            if(validateWeight){
                return parseInt(value)%5 === 0 && testValue;
            } else{
                return testValue;
            }
        }
    }
    isTime(exUnitData){
        return exUnitData.maskPattern.includes(':') || this.checkRepType(exUnitData, this.timeRepTypeID);
    }
    isWeight(exUnitData) {
        return this.checkRepType(exUnitData, this.weightRepTypeID);
    }
    isLength(exUnitData){
        return this.checkRepType(exUnitData, this.distanceRepTypeID);
    }
    getMeasurementMassKey(){
        return this.measurementMassKey;
    }
    hasNoExerciseUnits(){
        return this.unitData == null || this.unitData.length < 1
    }
    checkID(id){
        return id != null && parseInt(id) > 0;
    }
    getUnitData(){
        return this.unitData;
    }
    checkRepType(exUnitData, repTypeID){
        return parseInt(exUnitData.repTypeID) === parseInt(repTypeID);
    }
    getErrorMessage(inputValid, exUnitData, returnBlankString = false){
        const noErrorString = returnBlankString? ' ' : '';
        if(inputValid){
            return noErrorString;
        } else{
            const isTime = this.isTime(exUnitData);
            const isWeight = this.isWeight(exUnitData);
            const isDistance = this.isLength(exUnitData);
            //console.log('ExUnits', isWeight, exUnitData);
            if(isTime){
                return 'Please enter the correct time';

            } else if(isWeight){
                return 'Please enter the correct weight amount';
            } else if(isDistance){
                return 'Please enter the correct distance';
            } else{
                //Reps
                return 'Please enter the correct reps';
            }
        }
    }
}

export default ExerciseUnits;