import React from 'react';
import {Typography, Collapse} from '@mui/material';
import {CheckCircle, Info, PanoramaFishEye, Cancel, Close, Warning} from "@mui/icons-material";
import {getStatusAccentColor, getStatusColor, getSpacing, getConfigColor} from 'fit/system/UtilityFunctions';

class Alert extends React.Component{
	state = {
		closed: false
	};
	getIcon(status,style){
		switch(status){
			case 'success':
				return <CheckCircle style={style}/>;
			case 'info':
				return <Info style={style}/>;
			case 'warning':
				return <Warning style={style}/>;
			case 'error':
				return <Cancel style={style}/>;
			case 'noAction':
				return <PanoramaFishEye style={style}/>;
			default:
				return <Info style={style}/>;
		}
	};
	componentDidMount(){
		this.setCloseStatus(false);
	}
	closeAlert(){
		this.setCloseStatus(true);
	};
	setCloseStatus(closed){
		this.setState({closed: closed});
	}
	render(){
		let {status, headline} = this.props;
		const allowClose = !typeof(this.props.allowClose) === 'undefined';
		const color = getStatusAccentColor(status);
		const bgcolor = getStatusColor(status); //background color
		const iconSize = getSpacing();
		const iconAlignment = {position: 'relative', top: getSpacing('tiny'), color: color};
		const closeAlignment = {position: 'absolute', right: 0, top: 0, cursor: 'pointer', marginTop: '5px', marginRight: '5px', width: iconSize, height: iconSize};
		const radius = '5px';
		const headerStyle={color: color, fontWeight: 'normal'};
		const messageStyle={color: color};
		const alertStyle = {
			position: 'relative',
			background: bgcolor,
			color: color,
			padding: getSpacing('small'), //12px
			borderRadius: radius,
			WebkitBorderRadius: radius,
			width : '100%',
			display: 'block'
			 /* 'border': `1px solid ${color}` */
		};
		const finalStyle = {...alertStyle, ...this.props.style};
		const icon = this.getIcon(status,iconAlignment);
		const alertHeadlines = {
			success: 'A-OK',
			info: 'Notice',
			warning: 'Warning',
			error: 'Error',
			noAction: 'Hmmm',
		};
		headline = headline !== null ? headline : alertHeadlines[status];
		const visible = !this.state.closed && this.props.visible;
		const closeButton = allowClose ? <Close onClick={this.closeAlert.bind(this)} style={closeAlignment} /> : null ;

		let message = null;
		if(this.props.message != null){
			message = <Typography variant={'body1'} style={messageStyle}>
				{this.props.message}
			</Typography>
		}
		return (
			<Collapse in={visible}>
				<div style={finalStyle}>
					{closeButton}
					<Typography variant="h3" style={headerStyle}>
						{icon} {headline}
					</Typography>
					{message}
					{this.props.children}
				</div>
			</Collapse>
		)
	}
}

export default Alert;