import React from 'react';
import {connect} from 'react-redux';
import {TextField, Button, Grid, Paper, Typography, InputAdornment, Tooltip, IconButton, Collapse} from '@mui/material';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Link} from 'react-router-dom'
import {Navigate} from 'react-router-dom';
import Alert from "fit/components/Alert";
import {setPageTitle} from 'fit/system/UtilityFunctions'
import SecureConnect from "fit/system/SecureConnect";
import {Form, Formik} from "formik";
import * as yup from "yup";
import {PRODUCTION_STATUS ,PAPER_STYLE, ACTIVE_WORKOUT_URL, MY_PROFILE_URL} from "fit/system/FITConstants";
import User from 'fit/system/User';

const validationSchema = yup.object().shape({
	email: yup.string()
		.required('Email required')
		.email('Email required')
		.min('5','Email required'),
	password: yup.string()
		.required('Password required')
});

class Login extends React.Component {
	state ={
		email: PRODUCTION_STATUS ? '' : 'test@test.com',
		password: PRODUCTION_STATUS ? '' : 'test',
		hasError: false,
		displayPassword: false,
		errorMessage: '',
		displayConnectLog: false,
	};
	handleChange(e){
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		let state = this.state;
		state[name] = value;
		this.setState({state});
	}
	toggleDisplayPassword(){
		let displayPassword = !this.state.displayPassword;
		this.setState({displayPassword});
	}
	login(){
		const {email, password} = this.state;
		let sc = new SecureConnect('system.php','post');
		this.setState({hasError: false});
		sc.setAction('login');
		sc.setDisplaySuccessMessages(true);
		sc.setDisplayNotifications(true);
		sc.setFormData({login: email, password: password});
		sc.connect().then(json =>{
			const authorized = sc.getCompleted(json);
			if(!authorized){
				const responseMessage = sc.getResponseMessage(json);
				this.setState({hasError: true, errorMessage: responseMessage});
			}
		});
	}
	render() {
		let user = new User();
		const displayForgotPassWordLink = false
		let forgotPasswordLink = displayForgotPassWordLink ? <Link to="/reset-password/">Forgot Your Password?</Link> : null;
		const loggedIn = user.getLoggedInStatus();
		const deviceAccount = user.getDeviceAccount();
		let logDataButton = null;
		let connectLog = null;

		if(PRODUCTION_STATUS === false){
			logDataButton =
				<IconButton
					style={{position: 'absolute', right: 0, top: 0, zIndex: 10000, color:'#333'}}
					onClick={()=>this.setState({displayConnectLog: !this.state.displayConnectLog})}
				>
					{
						this.state.displayConnectLog === false ? <VisibilityOff/> : <Visibility/>
					}
				</IconButton>
		}


		if(loggedIn && deviceAccount === false){
			//Active users go to their profile on logging in
			const requestedPath = this.props.router.location.pathname;
			console.log('LOGIN :: REDIRECT TO =>', requestedPath);
			const redirect = requestedPath !== '' && requestedPath !== '/' ? requestedPath : MY_PROFILE_URL;
			return (
				<Navigate to={redirect}/>
			)
		}
		if(loggedIn && deviceAccount){
			//Logged in device accounts go to the active workout page.
			return <Navigate to={ACTIVE_WORKOUT_URL}/>
		}
		const {email, password, hasError, errorMessage, displayPassword} = this.state;
		setPageTitle('Sign In');
		return (
		<Paper style={PAPER_STYLE}>
			{logDataButton}
			<Formik
				initialValues={this.state}
				validationSchema={validationSchema}
				onSubmit={this.login.bind(this)}
				enableReinitialize={true}
			>
				{({ errors, touched}) => (
					<Form>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant={'h1'}>
									Sign In
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Alert
									visible={hasError}
									status={'error'}
									headline={'Login Error'}
									message={errorMessage}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									label="Email"
									fullWidth
									name="email"
									onChange={this.handleChange.bind(this)}
									defaultValue={email}
									margin="normal"
									className="mt-1 my-sm-3"
									error={(errors.email && touched.email) || hasError}
									helperText={errors.email || ''}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									type={displayPassword ? 'text' : 'password'}
									name="password"
									label="Password"
									fullWidth
									onChange={this.handleChange.bind(this)}
									defaultValue={password}
									margin="normal"
									className="mt-1 my-sm-3"
									error={(errors.password && touched.password) || hasError}
									helperText={errors.password || ''}
									InputProps={{
										endAdornment: <InputAdornment position="end">
											<Tooltip title={'Toggle Password Visibility'} placement={'bottom'}>
											<IconButton
												edge={'end'}
												onClick={()=>this.toggleDisplayPassword()}
											>
												{displayPassword ? <Visibility/> : <VisibilityOff/>}
											</IconButton>
											</Tooltip>
										</InputAdornment>
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Button
									fullWidth
									variant="contained"
									color="primary"
									type="submit"
								>Sign In
								</Button>
							</Grid>
							<Grid item xs={12}>
								{forgotPasswordLink}
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Collapse in={this.state.displayConnectLog}>
								Now you See me
							</Collapse>
						</Grid>
					</Form>
				)}
			</Formik>
		</Paper>
		)
	}
}


const mapStateToProps = (state) => {
	/*
	console.log('==============LOGIN.JS : MAPPING STATE TO PROPS==============');
	console.log(state);
	console.log(state.FITUser);
	console.log('=============/LOGIN.JS : MAPPING STATE TO PROPS==============');

	 */
	return {
		router: state.router,
		FITUser: state.FITUser
	};
};

export default connect(mapStateToProps)(Login);


/*
return (
	<div
		className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
		<div className="app-login-main-content">
			<div className="app-logo-content d-flex align-items-center justify-content-center">
				<Link className="logo-lg" to="/" title="American Immersion Theatre">
					<img src={''} alt="American Immersion Theater" title="American Immersion Theater" style={{width: '100%' }}/>
				</Link>
			</div>
			<div className="app-login-content">
				<div className="app-login-header mb-4">
					<h1>Login</h1>
				</div>
				<div className="app-login-form">
					<Formik
						initialValues={this.state}
						validationSchema={validationSchema}
						onSubmit={this.login.bind(this)}
						enableReinitialize={true}
					>
						{({ errors, touched}) => (
							<Form>

								<Grid container spacing={8}>
									<Grid item xs={12}>
										<Alert
											visible={hasError}
											status={'error'}
											headline={'Login Error'}
											message={errorMessage}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											label="Email"
											fullWidth
											name="email"
											onChange={this.handleChange.bind(this)}
											defaultValue={email}
											margin="normal"
											className="mt-1 my-sm-3"
											error={(errors.email && touched.email) || hasError}
											helperText={errors.email || ''}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											type="password"
											name="password"
											label="Password"
											fullWidth
											onChange={this.handleChange.bind(this)}
											defaultValue={password}
											margin="normal"
											className="mt-1 my-sm-3"
											error={(errors.password && touched.password) || hasError}
											helperText={errors.password || ''}
										/>
									</Grid>
									<Grid item xs={6}>
										<Button
											variant="contained"
											color="primary"
											type="submit"
										>Sign In
										</Button>
									</Grid>
									<Grid item xs={6}>
										<div style={{marginTop: getSpacing('iconAlignment')}}>
											<Link to="/reset-password/">
												Forgot Your Password?
											</Link>
										</div>
									</Grid>
								</Grid>
							</Form>

						)}
					</Formik>
				</div>
			</div>
		</div>
		{
			loader &&
			<div className="loader-view">
				{<CircularProgress/>}
			</div>
		}
	</div>
);

 */