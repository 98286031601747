import React, { Component } from "react";
import { render } from "react-dom";
import {
    SortableElement,
    SortableContainer,
    arrayMove,
    SortableHandle
} from "react-sortable-hoc";
import arrayMoveImmutable from 'array-move';

const SortableItem = SortableElement(({ name, index }) => (
    <li className="SortableItem">{name}</li>
));

const SortableItemList = SortableContainer(({ items, disabled }) => (
    <ul>
        {items.map((name, index) => (
            <SortableItem
                collection="item"
                key={`item-${index}`}
                name={name}
                index={index}
                disabled={disabled}
            />
        ))}
    </ul>
));

class SectionContainer extends React.Component {
    render() {
        const { section, index, items, onSortEnd } = this.props;

        return (
            <div className="SortableSection">
                <h5>Section: {section}</h5>
                <SortableItemList
                    lockAxis="y"
                    items={items}
                    onSortEnd={onSortEnd.bind(this, index)}
                    lockToContainerEdges
                />
            </div>
        );
    }
}

const SortableSection = SortableElement(
    ({ section, index, items, onSortEnd }) => (
        <SectionContainer
            section={section}
            index={index}
            items={items}
            onSortEnd={onSortEnd}
        />
    )
);
const SortableSectionList = SortableContainer(
    ({ sections, onSectionSortEnd }) => (
        <div>
            {sections.map(({ section, items }, index) => (
                <SortableSection
                    collection="section"
                    key={`item-${section}`}
                    section={section}
                    index={index}
                    items={items}
                    onSortEnd={onSectionSortEnd}
                />
            ))}
        </div>
    )
);

class SortableComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            sections: [
                { section: "A", items: ["A1", "A2", "A3"] },
                { section: "B", items: ["B1", "B2"] },
                { section: "C", items: ["C1", "C2"] }
            ]
        };
    }
    onSortEnd({ oldIndex, newIndex }) {
        this.setState({
            sections: arrayMove(this.state.sections, oldIndex, newIndex)
        });
    }
    onSectionSortEnd(sectionIndex, { oldIndex, newIndex }) {
        console.log('CALLED', sectionIndex, oldIndex, newIndex);
        let copiedSections = Object.assign([], this.state.sections);
        let section = copiedSections[sectionIndex];
        section.items = arrayMove(section.items, oldIndex, newIndex);

        this.setState({
            sections: copiedSections
        });
    }
    render() {
        return (
            <SortableSectionList
                sections={this.state.sections}
                lockAxis="y"
                lockToContainerEdges
                onSortEnd={this.onSortEnd.bind(this)}
                onSectionSortEnd={(sectionIndex,obj)=>this.onSectionSortEnd(sectionIndex, obj)}
            />
        );
    }
}
export default SortableComponent;
