import React from 'react';
import ResetPassword from "fit/components/System/ResetPassword";
import SoloFormWrapper from "fit/components/System/SoloFormWrapper";
import {SINGLE_FORM_FLEX_STYLE} from "fit/system/FITConstants";
import {useParams} from "react-router-dom";

const ResetPasswordRoute =()=>{
    const props = useParams();
    return (
        <SoloFormWrapper>
            <ResetPassword resetKey={props.resetKey}/>
        </SoloFormWrapper>
    )
}
export default ResetPasswordRoute;