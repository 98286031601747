import React from 'react';
import NewWorkoutBlockGenerator from "fit/components/WorkoutBlockGenerator/WBG";
import {useParams} from "react-router-dom";
import {isEmpty} from "fit/system/UtilityFunctions";

const WorkoutBlocksEditor =()=>{
    let props = useParams();
    const workoutBlockID = !isEmpty(props) && props.workoutBlockID != null ? props.workoutBlockID : null;
    const workoutID = !isEmpty(props) && props.workoutID != null ? props.workoutID : null;
    return (
        <div>
            <NewWorkoutBlockGenerator workoutBlockID={workoutBlockID} workoutID={workoutID}/>
        </div>
    );
}

export default WorkoutBlocksEditor;