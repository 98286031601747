import React from 'react';
import {memo} from "react";
import {Paper, Grid, Typography, Button} from "@mui/material/";
import {useTheme} from '@mui/system';
import {Table, TableHead, TableRow, TableSortLabel,  TableBody, TableCell, Checkbox} from "@mui/material/";
import {Tabs, Tab, Stepper, Step, StepLabel, Tooltip, IconButton, TextField} from "@mui/material/";
import {PAPER_STYLE, DISABLED_STYLE, FUTURE_STYLE, BUTTON_INPUT_HEIGHT_MATCH} from 'fit/system/FITConstants';
import {Edit, FitnessCenter, CheckCircle, Refresh, Replay, ArrowBack} from "@mui/icons-material/";
import Modal from 'fit/components/Dialogs/Modal';
import RPEModal from "fit/components/Dialogs/RPEModal";
import SecureConnect from "fit/system/SecureConnect";
import {isEmpty, getSpacing, calculateWeightMax, getConfigColor, setPageTitle} from "fit/system/UtilityFunctions";
import Alert from 'fit/components/Alert';
import User from 'fit/system/User';
import WBGStyles from "fit/components/WorkoutBlockGenerator/WBGStyles";
import ExerciseUnits from "fit/system/ExerciseUnits";
import InputMask from "react-input-mask";


const gridSpacing=1;

const exerciseHeaderStyle={
    marginTop: getSpacing('small'),
    marginBottom: 0,
    borderTop: '2px solid #000',
}

const debugLog = false;

const AthleteSelectionRow = memo(({athlete, toolTipTitle, checked, disabled, style, toggleAthleteFn, key})=>{
    const a = athlete;
    return (
        <TableRow key={key}>
            <TableCell>
                <Tooltip
                    title={toolTipTitle}
                    enterDelay={300}
                    placement={'bottom-start'}>
                                <span>
                                   <Checkbox
                                       checked={checked}
                                       disabled={disabled}
                                       onChange={toggleAthleteFn}
                                       color={'primary'}/>
                                </span>
                </Tooltip>
            </TableCell>
            <TableCell style={style}>{a.firstName} {a.lastName}</TableCell>
            <TableCell style={style}>{a.positionStatus}</TableCell>
            <TableCell style={style}>{a.yearStatus}</TableCell>
            <TableCell style={style}>{a.sexStatus}</TableCell>
        </TableRow>
    )
}, (prevProps, currentProps)=>{
    const checkedChanged = prevProps.checked !== currentProps.checked;
    const disabledChanged = prevProps.disabled !== currentProps.disabled;
    const failedMatch = checkedChanged || disabledChanged
    const reRender = !failedMatch;
    return reRender;
})

class ActiveWorkout extends React.Component{
    state = {
        availableWorkouts: [], //List of available workouts to select from. Get workoutID from this list
        availableWorkoutRequestCompleted : false,
        errorRequestingWorkout: false,
        loadAthletes: true,
        team: '',
        teamID: null,
        minStep: 0, //Minimum Step user can go back. Devices = 1, everyone else = 0
        previousStep: 0,
        activeStep: 0, //For Tab Container
        maxAthletesPerDevice: 3, //Limiter on the number of athletes per device. Disables athletes from selecting more
        singleAthlete: false, //If the user a single athlete? or is the component running on a tablet?
        singleWorkout: false, //Are there multiple workouts to choose from? or 1 workout available?
        ownerName: '', //Eventually use for feedback
        ownerUserID: null, //eventually user for feedback
        //RPE Values
        RPERequired: false,
        RPELowRange: 0,
        RPEHighRange: 0,
        activeWorkoutID: null, //use for checking if new workout gets pushed
        workoutName: '',
        athletes: {}, //list of athletes pulled from the DB ({userID: {userData}}, {userID: {userData}})
        activeWorkout: [], // JSON Loaded: List of exercises to be performed by the athlete.
        workoutHistory: [], //History gets updated as athletes complete exercises, also allows continuing from another device
        //App Specific Vars
        currentActiveUsers: [1,2,3], //list of users interacting with the device
        completedUsers: [], //users that have completed the activeWorkout
        RPEUsers: [], //Users that have submitted their RPE
        workoutStarted: false, //Determine if the workout has started
        workoutCompleted: false, //Set to true when final input has been completed
        dataPayload: { //form inputs for submitting athlete data
            action: '',
            userID: 0,
            exerciseID: 0,
            reps: 0,
            measurementUnitID: 0,
            repUnitID: 0,
            setNumber: 0,
            weightValue: 0,
            workoutMetricsID: null,
            copiedPrevious: false
        },
        exerciseUnits: [], //Units for managing reps/measurements (handles inputs)
        displaySubmissionModal: false,
        displayAthleteName: '',
        displayExerciseName: '',
        //For Recording RPE at end of workout
        RPEData: {
            userID: null,
            name: '',
            displayModal: false,
            score: null,
        },
    };
    componentDidMount() {
        /*
            -checkforActiveWorkout
                -if pull has data
                -load data into state (athletes, activeWorkout)
                -set workoutStarted = false, workoutCompleted, false,
                -Allow users to select which athletes are assigned to the tablet
                -Build/ready the device/forms for data entry
         */
        let user = new User();
        if(user.getDeviceAccount()) {
            this.checkActiveWorkout();
            this.devicePingForNewWorkout();
        } else{
            this.getAvailableWorkouts();
        }
    }
    componentWillUnmount() {
        clearInterval(this.deviceTimer);
    }
    getAvailableWorkouts(){
        let sc = new SecureConnect('testapi.php?action=getAvailableWorkouts');
        sc.setDisplaySuccessMessages(false);
        sc.connect().then(json=>{
            const completed = [1,2].includes(sc.getResponseCode(json)); //Info or success
            const data = completed ? sc.getData(json) : [];
            this.setState({
                availableWorkouts: data,
                availableWorkoutRequestCompleted: completed
            });
        })
    }
    selectWorkout(workoutID){
        this.setState({activeWorkoutID: workoutID}, this.checkActiveWorkout(workoutID, true));
        //this.setStepCompleted()
    }
    checkActiveWorkout(workoutID = null, workoutSelected = false){
        const s = this.state;
        const currentWorkoutID = workoutID == null ? s.activeWorkoutID : workoutID;
        const extraParameters = currentWorkoutID != null ? `&currentWorkoutID=${currentWorkoutID}` : '';
        const url = `testapi.php?action=getActiveWorkout${extraParameters}`;
        let user = new User();
        if(debugLog) {
            console.log('-------------CHECKACTIVEWORKOUT()-------------');
            console.log('CHECK ACTIVEWORKOUT', workoutID);
            console.log('WORKOUT ID', s.activeWorkoutID);
            console.log('URL =>', url);
            console.log('----------------------------------------------');
        }
        let errorRequestingWorkout = false;
        let sc = new SecureConnect(url);
        const deviceAccount = user.getDeviceAccount()
        sc.setDisplaySuccessMessages(deviceAccount === false);
        sc.connect().then(json=>{
            let singleAthlete = false;
            let singleWorkout = false;
            errorRequestingWorkout = [3,4].includes(sc.getResponseCode(json));
            let activeStep = s.activeStep;
            let minStep = s.minStep;
            let ownerName = '';
            let ownerUserID = null;
            let activeWorkout = {};
            let history = [];
            let exerciseUnits = [];
            let completedUsers = [];
            let workout = {};
            let activeWorkoutID = null;
            let RPERequired = false;
            let RPELowRange = 1;
            let RPEHighRange = 1;
            let workoutName = 'No Workout';
            let athletes = {};
            const completed = sc.getCompleted(json);
            const data = sc.getData(json);
            let deviceUpdate = false;
            if(completed && !isEmpty(data)) {
                console.log('SERVER DATA', data);
                history = data.workoutHistory != null ? data.workoutHistory : [];
                workout = data.workoutData;
                exerciseUnits = data.exerciseUnits;
                completedUsers = data.completedUsers;
                athletes = data.athleteData;
                activeWorkoutID = parseInt(workout.workoutID);
                if (debugLog) {
                    console.log('SERVER DATA');
                    console.log('WORKOUT', workout);
                    console.log('ATHLETES', athletes);
                    console.log('COMPLETED USERS', completedUsers);
                    console.log('HISTORY', history);
                    console.log('RPE', workout.RPERequired, workout.RPELowRange,'-', workout.RPEHighRange);
                }
                //Compare current (app) activeWorkoutID to serverWorkoutID
                //If a user selected the workout -OR-
                //There's no active workout -OR-
                //A deviceAccount has found a new workout that doesnt match the current one, Update
                deviceUpdate = deviceAccount === true && parseInt(currentWorkoutID) !== activeWorkoutID;
                if (workoutSelected === true || (currentWorkoutID === null || deviceUpdate)) {
                    //New workout has been pushed to app, update state with new workout
                    singleAthlete = athletes.length <= 1;
                    singleWorkout = workout.length <= 1;
                    ownerName = `${workout.ownerFirstName} ${workout.ownerLastName}`
                    ownerUserID = parseInt(workout.ownerUserID);
                    //Set workout Variables
                    activeWorkout = workout.activeWorkout;
                    RPERequired = workout.RPERequired;
                    RPELowRange = parseInt(workout.RPELowRange);
                    RPEHighRange = parseInt(workout.RPEHighRange);
                    workoutName = `${workout.title}: Day ${parseInt(workout.dayIndex)+1}`;
                    if(debugLog) {
                        console.log('WORKOUT ID', activeWorkoutID);
                        console.log('ACTIVE WORKOUT', activeWorkout);
                    }
                    //activeWorkout.exercises[0].active = true; //Set the first exercise to active
                    //Set first exercise as the active workout
                }
            }
            if(completed){
                //Move the step forward
                activeStep = 1;
            }
            if(deviceAccount){
                //Prevent the user from selecting a different workout.
                minStep = 1;
            }
            if(deviceAccount && completed === false && this.state.activeWorkoutID != null){
                //No workout
                activeStep = 0; //set to first step - waiting on workout
                minStep = 0;
                deviceUpdate = true; //update the state
            }
            if(workoutSelected || deviceUpdate) {
                //Only update the state if the workout has been selected or the criteria for a device update has been met
                this.setState({
                    activeStep,
                    minStep,
                    errorRequestingWorkout,
                    singleAthlete,
                    singleWorkout,
                    ownerName,
                    ownerUserID,
                    activeWorkoutID,
                    currentActiveUsers: [],
                    workoutStarted: false,
                    workoutCompleted: false,
                    RPERequired: RPERequired,
                    RPELowRange: RPELowRange,
                    RPEHighRange: RPEHighRange,
                    workoutName: workoutName,
                    athletes: athletes,
                    completedUsers,
                    activeWorkout,
                    workoutHistory: history,
                    exerciseUnits
                });
            }
        });
    }
    getNewWorkoutHistory(){
        const s = this.state;
        //User has moved from executing workout back to the athletes screen
        //Pull workoutHistory again for the workout
        let sc = new SecureConnect(`testapi.php?action=getWorkoutHistory&workoutID=${s.activeWorkoutID}`);
        sc.setDisplaySuccessMessages(false);
        sc.connect().then(json=>{
           if(sc.getCompleted(json)){
               //Successfully pulled data, update workoutHistory, completedUsers
               const data = sc.getData(json);
               let {completedUsers,workoutHistory} = data;
               let {currentActiveUsers} = this.state;
               //Consolidate history, filter client only data
               const clientHistory = this.state.workoutHistory.filter(item => item.dbData === false);
               //Remove completedUsers from CurrentActiveUser List (if any)
               currentActiveUsers.forEach((user,k) =>{
                   const index = completedUsers.indexOf(user);
                   if(index > -1){
                       //Remove the user from active users list
                       currentActiveUsers.splice(k, 1);
                   }
               });
               workoutHistory = workoutHistory.concat(clientHistory);
               //Update workoutHistory, completedUsers, currentActiveUsers
               this.setState({workoutHistory, completedUsers, currentActiveUsers});
           }
        });
    }
    handleModalChange(e){
        let dataPayload = this.state.dataPayload;
        let {name, value} = e.currentTarget;
        dataPayload[name] = value;
        dataPayload.copiedPrevious = false;
        this.setState({dataPayload});
    }
    handleRPEScore(e){
        //Update the score of the RPE Form
        let value = e.target.value;
        let {RPEData} = this.state;
        RPEData.score = value;
        this.setState({RPEData});
    }
    setLoadAthletes(){
        this.setState({loadAthletes: false});
    }
    devicePingForNewWorkout(){
        const refreshSeconds = 15;
        let user = new User();
        if(user.getDeviceAccount() === false){
            clearInterval(this.deviceTimer);
            return;
        }
        /*
            -Check server to see server-side activeWorkoutID is different from activeWorkoutID held in state
            -Run Every 10 seconds
            -Add Check if the workout gets pulled (incorporate into checkActiveWorkout)
         */

        this.deviceTimer = setInterval(()=>{
            this.checkActiveWorkout();
        }, refreshSeconds*1000);
    }
    initModalForm(){
        //Clear out the exercise and athlete's name after successfully submitting data
        const dataPayload = {
                action: '',
                userID: 0,
                exerciseID: 0,
                setNumber: 0,
                reps: 0,
                weightValue: 0,
                measurementUnitID: 0,
                repUnitID: 0,
                recordSingleWeight: false,
                copiedPrevious: false
        };
        this.setState({
            displayAthleteName: '',
            displayExerciseName: '',
            displaySubmissionModal: false,
            dataPayload
        })
    }
    submitExerciseData(){
        /*
            -Post Data to the system
            -initialize data payload
            -remove displayModal
         */
        let formPayload = this.state.dataPayload;
        formPayload.workoutID = this.state.activeWorkoutID;
        let sc = new SecureConnect('testapi.php', 'post');
        sc.setAction(formPayload.action);
        sc.setDisplaySuccessMessages(true);
        sc.setFormData(formPayload);
        sc.connect().then(json =>{
           const completed = sc.getCompleted(json);
           //If successful, hide modal. Keep displayed on failed
           let displaySubmissionModal = true;
           let dataPayload = this.state.dataPayload;
           if(completed) {
               const data = sc.getData(json);
               const historyPayload = {
                   exerciseID: formPayload.exerciseID,
                   reps: formPayload.reps,
                   setNumber: formPayload.setNumber,
                   measurementUnitID: dataPayload.measurementUnitID,
                   repUnitID: dataPayload.repUnitID,
                   userID: formPayload.userID,
                   weightValue: formPayload.weightValue,
                   workoutID: formPayload.workoutID,
                   workoutMetricsID : parseInt(data.workoutMetricsID)
               };
               //Reset the form
               this.updateWorkoutHistory(historyPayload, true);
               this.initModalForm();
           }
        });
    }
    displayLogWeightModal(userID, exerciseID, reps, inputAmount, setNumber, workoutMetricsID = null, recordSingleWeight = false){
        const {athletes, activeWorkout} = this.state;
        //Get the name of the athlete
        const athlete = athletes[userID];
        const athleteName = `${athlete.firstName} ${athlete.lastName}`;
        //Find the exercise name
        const exerciseIndex = activeWorkout.exercises.findIndex(exercise => parseInt(exercise.exerciseID) === parseInt(exerciseID));
        const {exerciseName, repUnitID, measurementUnitID} = activeWorkout.exercises[exerciseIndex];
        //Determine whether updating a previous entry or logging a new one
        const action = 'logWorkoutHistory';
        let dataPayload = {
            action,
            userID,
            exerciseID,
            reps,
            measurementUnitID,
            repUnitID,
            setNumber,
            weightValue: inputAmount,
            workoutMetricsID,
            recordSingleWeight,
            copiedPrevious: false,
        }
        if(workoutMetricsID != null){
            dataPayload.updateHistory = 1;
        }
        this.setState({
            displaySubmissionModal: true,
            displayAthleteName: athleteName,
            displayExerciseName: exerciseName,
            dataPayload
        });
    }
    closeLogWeightModal(){
        this.setState({displaySubmissionModal: false});
    }
    handleRPEModalDisplay(statusBool){
        let {RPEData} = this.state;
        RPEData.displayModal = statusBool;
        this.setState({RPEData});
    }
    submitRPEData(){
        const s = this.state;
        let sc = new SecureConnect('testapi.php','post');
        sc.setDisplaySuccessMessages(true);
        sc.setAction('logRPEScore');
        let {RPEUsers} = this.state;
        RPEUsers.push(s.RPEData.userID);
        const formData = {
            userID : s.RPEData.userID,
            RPEScore: s.RPEData.score,
            workoutID: s.activeWorkoutID
        };
        sc.setFormData(formData);
        sc.connect().then(json =>{
           if(sc.getCompleted(json)){
               //Update state. Reinitialize RPE Form
               const RPEData = {
                   userID: null,
                   name: '',
                   displayModal: false,
                   score: 0
               }
               //Update both the RPEData and list of RPEUsers
               this.setState({RPEData, RPEUsers});
           }
        });
    }
    checkCurrentExercise(exerciseKey, userID){
        //Determine if the current exercise is active based on workoutHistory
        //If the passed exercise is the first exercise, automatically return true.
        //Otherwise: Get the workoutHistory for the user and verify that the last set of the previous exercise has been completed.
        //If that set has been completed, return true. Otherwise return false (final set of previous exercise doesn't exist in workoutHistory)
        if(exerciseKey === 0){
            //First exercise, nothing before it.
            return true;
        } else{
            //Not the first exercise
            //Get the previous exercise
            const previousExercise = this.state.activeWorkout.exercises[exerciseKey-1];
            //Get the total number of sets for previous exercise
            const previousExerciseSetTotal = previousExercise.sets.length;
            //Get the exercise ID for filtering workoutHistory
            const previousExerciseID = previousExercise.exerciseID;
            //Load workout history
            const {workoutHistory} = this.state;
            //Does Workout History exist for last set of previous exercise for userID?
            //
            return workoutHistory.find(
                //Match ExerciseID
                set=>parseInt(set.exerciseID) === parseInt(previousExerciseID) &&
                //Match UserID
                parseInt(set.userID) === parseInt(userID) &&
                //Match setNumber (+1 (account for arrayindex offset)) to set total
                parseInt(set.setNumber)+1 === previousExerciseSetTotal
            ) != null; //Return whether workout history exists based on above parameters
        }
    }
    checkHistory(exerciseID, userID, setNumber){
        //determine if a specific exercise has already been recorded
        const wh = this.state.workoutHistory;
        const findID = wh.findIndex(h => parseInt(h.exerciseID) === parseInt(exerciseID) && parseInt(h.userID) === parseInt(userID) && parseInt(h.setNumber) === parseInt(setNumber));
        if(findID < 0){
            //NOT FOUND
            return false;
        } else{
            //FOUND IT
            const entry = wh[findID];
            return {reps: entry.reps, weightValue: entry.weightValue, workoutMetricsID: entry.workoutMetricsID, historyIndex: findID};
        }
    }
    copyPreviousSet(exerciseID, userID, setNumber){
        //Copy the data from the previous set.
        //Allows the athlete to click a button and will populate the weight/resistance & reps from their previous set
        //Speeds up input
        const history = this.checkHistory(exerciseID, userID, setNumber-1);
        if(history !== false && !isEmpty(history)){
            const {reps, weightValue} = history;
            let updatedPayload = this.state.dataPayload;
            updatedPayload.weightValue = weightValue;
            updatedPayload.reps = reps;
            updatedPayload.copiedPrevious = true;
            this.setState({dataPayload: updatedPayload});
        }
    }
    checkWorkoutComplete(userID){
        //After the user completes a set, check if the athlete is done.
        //If they're done, fire the RPE modal
        const {activeWorkout, workoutHistory, RPERequired, athletes} = this.state;
        //Calculate the total # of sets. Compare to the total # of sets within the workoutHistory
        //Caculating total sets
        let totalSets = 0;
        activeWorkout.exercises.forEach(a =>{
            totalSets+= a.sets.length;
        });
        //Calculating setsCompleted by the athlete
        const completedSets = workoutHistory.filter(wh=>parseInt(wh.userID) === parseInt(userID)).length;
        if(completedSets >= totalSets) {
            //User has completed the workout, display RPE Modal
            //Set User has completed the workout
            const workoutID = this.state.activeWorkoutID;
            //Log the workout as completed
            let sc = new SecureConnect('testapi.php', 'post');
            sc.setAction('logCompletedWorkout');
            sc.setDisplayNotifications(false);
            sc.setFormData({workoutID, userID: userID});
            sc.connect().then(json =>{
                const completed = sc.getCompleted(json);
                //Do anything here??!
            });
            let {RPEData, completedUsers, RPEHighRange, RPELowRange} = this.state;
            //Add the athlete to the list of completed users
            completedUsers.push(parseInt(userID));
            //Set the RPE Modal Active
            if (RPERequired && this.state.RPEUsers.includes(userID) === false) {
                const tempScore = parseInt((RPEHighRange+RPELowRange)/2)
                const athlete = athletes[userID];
                const athleteName = `${athlete.firstName} ${athlete.lastName}`;
                RPEData = {
                    userID: parseInt(userID),
                    name: athleteName,
                    displayModal: true,
                    score: tempScore,
                }
            }
            //Update completed Users, RPEData
            this.setState({completedUsers, RPEData}, this.checkEverybodyDone());

        } //end Completed sets check
    }
    checkEverybodyDone(){
        const {completedUsers, currentActiveUsers, activeStep} = this.state;
        let allComplete = true;
        //Find Active Users in completed Users list
        currentActiveUsers.forEach(userID =>{
            if(completedUsers.includes(userID) === false){
                allComplete = false;
            }
        })
        if(allComplete){
            //Advance to the next step
            const nextStep = activeStep+1;
            this.setState({activeStep: nextStep});
        }
    }
    advanceWorkout(exerciseID, userID, setNumber){
        //When a user clicks a check button to advance the workout.
        //Doesn't post to the server.
        const payload = {exerciseID, userID, setNumber}
        this.updateWorkoutHistory(payload, false);
    }
    newAdvanceWorkout(exerciseID, userID, setNumber, repCount){
        //SAVE THE EXERCISE AS HAVING BEEN COMPLETED ??
    }
    updateWorkoutHistory(historyPayload, databaseData){
        //update the history for the athletes if the record doesn't already exist.
        //advances the workout to the next item
        const history = this.checkHistory(historyPayload.exerciseID, historyPayload.userID, historyPayload.setNumber);
        const userID = historyPayload.userID;
        let workoutHistory = this.state.workoutHistory;
        //Set whether the history record is in the database or solely app
        historyPayload.dbData = databaseData;
        if(isEmpty(history)){
            workoutHistory.push(historyPayload);
            //GRID SYSTEM:
            //exerciseID=>setNumber=>userID=>{reps, weightValue, workoutMetricsID, dbData}
        } else {
            //UPDATE the current
            const findID = history.historyIndex;
            workoutHistory[findID] = historyPayload;
        }
        this.setState({workoutHistory}, this.checkWorkoutComplete(userID));
    }
    newExerciseRender(workoutItem, exerciseKey){
        const {currentActiveUsers, athletes} = this.state;
        const exerciseUnits = new ExerciseUnits(this.state.exerciseUnits);
        const w = workoutItem;
        const {exerciseName} = w;
        const {eachEnabled, recordSingleWeight} = w;
        const recordWeight = w.recordWeightDefault;
        const percentWidthStyle = {width: '10%'};
        const repCountWidthStyle = {width: '10%'};
        const exerciseID = w.exerciseID;
        const setKeys = Object.keys(workoutItem.sets);
        const hasPercentage = workoutItem.sets[0].percentage != null;
        const repLabel = exerciseUnits.getActiveWorkoutRepLabel(w.repUnitID);
        const eachLabel = eachEnabled ? 'each' : '';
        let rows = [];
        //TOGGLING BUTTONS TO ADVANCE THE WORKOUT
        /*
            -Determine if the current exercise is active
            -Determine if the current exercise can be recorded
                -If not able to record, create a button to advance the workout to the next exercise
                -If workout can be recorded, find location of set to record weight
        */
        //GET THE ATHLETE CELLS
        /*
            -If recordWeight is false. Provide Button to Advance the workout to the next exercise
            -Run through current Active Users, provide columns for reach user if the weight recorded
        */

        /*
            BUILD ROWS FOR EACH ATHLETE
            -IF NO RECORD WEIGHT, PROVIDE A CHECK TO ADVANCE THE WORKOUT
            -IF HAS PERCENTAGE AND ATHLETE HAS MAX, CALCULATE MAXES AND DISPLAY WEIGHT AMOUNTS FOR LIFTING
            -IF RECORD WEIGHT, NO PERCENTAGE. PROVIDE NO WEIGHT. ATHLETE WILL HAVE TO INPUT MANUALLY
         */
        //Build ROWS FOR EACH ATHLETE
        setKeys.forEach((set,setNumber) => {
            const s = workoutItem.sets[set];
            const percentageLabel = hasPercentage ? `${s.percentage}%` : '-';
            //Build Individual Cells for each active athlete
            let cellRows = [];
            currentActiveUsers.forEach((userID,key) => {
                //Is the current exercise the active one? (All previous exercises completed by athlete)
                const currentExerciseActive = this.checkCurrentExercise(exerciseKey, userID);
                //CHECK HISTORY. HISTORY WILL DETERMINE WHAT BUTTON TO DISPLAY
                const history = this.checkHistory(exerciseID, userID, setNumber);
                //History exists(bool)
                const hasHistory = !isEmpty(history);
                const previousExerciseIncomplete = isEmpty(this.checkHistory(exerciseID, userID, setNumber-1));
                const buttonVariant = hasHistory ? 'outlined' : 'contained';
                const buttonColor = hasHistory ? 'secondary' : 'primary';
                //Disable Future Buttons until the athlete has completed the current set/reps
                const disableCurrentButton = previousExerciseIncomplete && parseInt(setNumber) !== 0;
                const disabled = currentExerciseActive === false || disableCurrentButton || (hasHistory && recordWeight === false);
                let liftButton = null;
                if(recordWeight === false){
                    liftButton =
                        <Button
                            fullWidth
                            size={'large'}
                            disabled={disabled}
                            variant={buttonVariant}
                            color={buttonColor}
                            onClick={this.advanceWorkout.bind(this, exerciseID, userID, setNumber)}
                        ><CheckCircle/></Button>

                } else{
                    //Create a button for recording the weight
                    //Lift Value
                    //If has History: display record, else: hasPercentage? Display Percentage Value or display Edit Icon
                    let strLiftValue = hasHistory ? `(${history.weightValue})` : hasPercentage ? `((${s.percentage}%))` : <Edit/>; //Text label for button
                    let inputLiftValue = !hasHistory ? '' : history.weightValue; //Actual Value to pass
                    //Reps Value
                    let repsValue = !hasHistory ? s.reps : history.reps;
                    //Assign Previous workoutMetricsID if previously logged
                    let workoutMetricsID = hasHistory && history.workoutMetricsID != null ? history.workoutMetricsID : null;
                    const ath = athletes[userID];
                    const hasMaxes = ath.maxes.length > 0;
                    //User has maxes. find and calculate the amount for the user
                    if(hasMaxes && hasPercentage && !hasHistory){
                        //Calculate the max if percentage, max supplied and there's no former history
                        const percentageValue = parseInt(s.percentage);
                        const maxIndex = ath.maxes.findIndex(lifts => parseInt(lifts.exerciseID) === parseInt(exerciseID));
                        if(maxIndex > -1){
                            //Has a max.
                            const weightMax = ath.maxes[maxIndex].max;
                            //Update inputAmount based on calculated weight
                            inputLiftValue = calculateWeightMax(weightMax, percentageValue);
                            strLiftValue = `(${inputLiftValue})`;
                        }
                    }
                    liftButton =
                        <Button
                            fullWidth
                            size={'large'}
                            variant={buttonVariant}
                            color={buttonColor}
                            disabled={disabled}
                            onClick={this.displayLogWeightModal.bind(this, userID, exerciseID, repsValue, inputLiftValue, setNumber, workoutMetricsID, recordSingleWeight)}
                        >{strLiftValue}</Button>
                }
                //Build the Rows
                cellRows.push(
                    <TableCell key={key}>
                        {liftButton}
                    </TableCell>
                );
            });
            rows.push(
                <TableRow key={set}>
                    <TableCell style={percentWidthStyle}>{exerciseName}</TableCell>
                    <TableCell style={repCountWidthStyle}>{s.reps}{repLabel} {eachLabel}</TableCell>
                    {cellRows}
                </TableRow>
            );
        });

        //Build the Athlete Headers if record Weight
        let athleteHeaders = [];
        const athleteCols = this.state.currentActiveUsers.length;
        currentActiveUsers.forEach((userID,key) => {
            const ath = athletes[userID];
            athleteHeaders.push(
                <TableCell key={key}>{ath.firstName} {ath.lastName.substr(0,1)}.</TableCell>
            )
        });

        return (
            <React.Fragment key={exerciseKey}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={athleteCols+2} style={exerciseHeaderStyle}>
                                <Typography variant={'h4'}>
                                    {w.exerciseName}{
                                        w.notes !== '' ?
                                        <span>: <small>{w.notes}</small></span> : ''
                                    }
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Exercise</TableCell>
                            {/* PERCENTAGE */}
                            <TableCell>Reps</TableCell>
                            {athleteHeaders}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows}
                    </TableBody>
            </React.Fragment>
        );
    }
    toggleAthlete(userID){
        //Toggle an athlete to an activeWorkout interface
        let {currentActiveUsers} = this.state;
        const alreadyActive = currentActiveUsers.includes(userID);
        if(alreadyActive){
            //Remove user
            const id = currentActiveUsers.indexOf(userID);
            if(id > -1){
                currentActiveUsers.splice(id, 1);
            }
        } else {
            //Add user
            currentActiveUsers.push(userID);
        }
        this.setState({currentActiveUsers});
    }
    renderAthleteSelectionTable(){
        //List all athletes from the athletes array
        //users that are selected, their userIDs get added to currentUsers list
        const {athletes, currentActiveUsers} = this.state;
        const athleteKeys = Object.keys(athletes);
        const noAthletes = athleteKeys.length < 1;
        let noAthletesRows = noAthletes ? <TableRow><TableCell colSpan={5}>No Athletes Found</TableCell></TableRow> : null;
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Select</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Position</TableCell>
                        <TableCell>Year</TableCell>
                        <TableCell>Team</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {noAthletesRows}
                    {
                        athleteKeys.map(key=>{
                            const a = athletes[key];
                            const completedWorkout = this.state.completedUsers.includes(a.userID);
                            const checked = currentActiveUsers.includes(a.userID) || completedWorkout;
                            //Disable the checkbox if the athlete already completed the workout or the max # of users already selected
                            const maxSelected =  currentActiveUsers.length >= this.state.maxAthletesPerDevice;
                            const disabled = completedWorkout || (checked === false && maxSelected);
                            let toolTipTitle;
                            if(completedWorkout){
                                toolTipTitle = 'Athlete Completed Workout';
                            } else if(!completedWorkout && checked){
                                toolTipTitle = 'Athlete Selected';
                            } else if(!completedWorkout && maxSelected && !checked){
                                toolTipTitle = 'Maximum Athletes Already Selected';
                            } else{
                                toolTipTitle = 'Select Athlete';
                            }
                            const style= disabled ? DISABLED_STYLE : null;
                            return (
                                <AthleteSelectionRow
                                    athlete={a}
                                    key={key}
                                    toolTipTitle={toolTipTitle}
                                    checked={checked}
                                    disabled={disabled}
                                    style={style}
                                    toggleAthleteFn={()=>this.toggleAthlete(a.userID)}
                                />
                            )
                        })
                    }
                </TableBody>
            </Table>
        )
    }
    getSteppers(){
        const s = this.state;
        return [
            {title: 'Select Workout',stateCompletionValue: true},
            {title: 'Select Athletes',stateCompletionValue: s.currentActiveUsers.length > 0},
            {title: 'Execute',stateCompletionValue: s.workoutStarted},
            {title: 'Workout Completed',stateCompletionValue: s.workoutCompleted},
        ];
    }
    setStepCompleted(){
        let step = parseInt(this.state.activeStep);
        const newStep = step+1;
        this.setState({activeStep: newStep, previousStep: step});
    }
    setStepBack(){
        let step = parseInt(this.state.activeStep);
        const newStep = step-1;
        if(newStep === 1 && step === 2){
            //Going backwards from workout step to athlete step.
            //Pull new history
            this.getNewWorkoutHistory();
        }
        this.setState({activeStep: newStep, previousStep: step});
    }
    renderStepContent(){
        let user = new User();
        const deviceAccount = user.getDeviceAccount();
        const s = this.state;
        const headline = deviceAccount ? 'Waiting on Workout' : 'Select Your Workout';
        const headerBackButtonDisabled = s.activeStep <= s.minStep;
        const headerTipMessage = headerBackButtonDisabled ? "Can't Go Back" : 'Go Back';
        let refreshButton = null;
        if(deviceAccount){
            refreshButton =
                <Tooltip title={'Check For Active Workout'} placement={'bottom'}>
                    <IconButton
                        onClick={()=>this.checkActiveWorkout()}
                        color={'primary'}
                    >
                        <Refresh/>
                    </IconButton>
                </Tooltip>
        }
        const headerBackButton = <Tooltip title={headerTipMessage} placement={'bottom'}>
            <span>
            <IconButton
                color={'primary'}
                onClick={this.setStepBack.bind(this)}
                disabled={headerBackButtonDisabled}
            ><ArrowBack/>
            </IconButton></span>
        </Tooltip>



        switch(s.activeStep){
            case 0: //Select Workout
                return (
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Typography variant={'h2'}>
                                {headerBackButton} {headline}{refreshButton}
                            </Typography>
                        </Grid>
                        {
                            deviceAccount ?
                                <Grid item xs={12}>
                                    <Typography variant={'body1'}>
                                        Waiting on a workout. Checking.
                                    </Typography>
                                </Grid>

                                :

                                <Grid item xs={12}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Team</TableCell>
                                                <TableCell>Workout</TableCell>
                                                <TableCell colSpan={2}>Date Scheduled</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                isEmpty(s.availableWorkouts) ?
                                                    <TableRow hover>
                                                        <TableCell colSpan={3}>No Available Workouts</TableCell>
                                                    </TableRow>
                                                    :
                                                    s.availableWorkouts.map((w,key) =>{
                                                        return (
                                                            <TableRow hover key={key}>
                                                                <TableCell>{w.teamName}</TableCell>
                                                                <TableCell>{w.title}: Day {w.dayIndex+1}</TableCell>
                                                                <TableCell>{w.dateScheduled}</TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        color={'primary'}
                                                                        size={'large'}
                                                                        variant={'contained'}
                                                                        onClick={()=>this.selectWorkout(w.workoutID)}
                                                                    >
                                                                        <CheckCircle/> Select Workout
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                            }
                                        </TableBody>
                                    </Table>
                                </Grid>
                        }
                    </Grid>
                );
            case 1: //Select Athletes
                const athletesSelected = !isEmpty(s.currentActiveUsers);
                const startWorkoutButton = <Button
                    fullWidth
                    size={'large'}
                    variant={'contained'}
                    onClick={this.setStepCompleted.bind(this)}
                    color={'primary'}
                    disabled={athletesSelected === false}
                >Start Workout</Button>
                return(
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Typography variant={'h2'}>
                                {headerBackButton} Select Athletes {`${s.currentActiveUsers.length}/${s.maxAthletesPerDevice}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {startWorkoutButton}
                        </Grid>
                        <Grid item xs={12}>
                            {this.renderAthleteSelectionTable()}

                            {
                                /*
                              <DynamicDataTable
                              tableFormat={{
                                    tableID : 'selectAthletesTable',
                                    rowComponent: 'ActiveWorkoutAthleteSelectList',
                                    paginationEnabled: false,
                                    sortEnabled: true,
                                    dateRangeEnabled: false,
                                    defaultSort: 'name',
                                    defaultDirection: 'up',
                                    tableHeaders: [
                                        ['name', 'Name'],
                                        ['Year','yearStatus'],
                                        ['position','Position'],
                                        ['sex', 'Sex/Team'],
                                    ]
                                }}
                                loadData: {s.loadAthletes}
                                dataLoaded:{this.setLoadAthletes.bind(this)}
                                rowFunctions: {{
                                    toggleAthlete: this.toggleAthlete().bind(this)
                                }}
                                />
                            */}
                        </Grid>
                        <Grid item xs={12}>
                            {startWorkoutButton}
                        </Grid>
                    </Grid>
                );
            case 2: //Execute Workout
                const aw = s.activeWorkout.exercises != null ? s.activeWorkout.exercises : [];
                const warmupColSpan = this.state.currentActiveUsers.length+2; //Selected Athletes + 2 (Percentage/reps)
                let renderedWorkout = [];
                const workoutKeys = Object.keys(aw);
                workoutKeys.forEach((k, exerciseKey) =>{
                    renderedWorkout.push(this.newExerciseRender(aw[k], exerciseKey));
                });
                return (
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Typography variant={'h2'}>
                                {headerBackButton} Workout: {s.workoutName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={warmupColSpan} style={exerciseHeaderStyle}>
                                            <Typography variant={'h4'}>
                                                Warmup: <small>{s.activeWorkout.warmup}</small>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {renderedWorkout}
                            </Table>

                        </Grid>
                    </Grid>
                );
            case 3: //Workout Completed
                return (
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <Typography variant={'h2'}>
                                {headerBackButton} Workout Complete
                            </Typography>
                            <Alert
                                visible={true}
                                status={'success'}
                                headline={'Finished'}
                                message={"You're all done!"}
                            />
                        </Grid>
                    </Grid>
                );
            default:
                //No step - return nothing
                return null;
        }
    }
    validateWeightDataInput(value, weightValue = false){
        const testValue =  `${value}`.length > 0 && parseInt(value) > 0;
        if(weightValue){
            //Must be divisible by 5 increments
            return parseInt(value)%5 === 0 && testValue;
        } else{
            //rep value return standard testValue
            return testValue;
        }
    }
    render(){
        setPageTitle('Active Workout');
        let user = new User();
        const deviceAccount = user.getDeviceAccount();
        const s = this.state;
        const activeStep = s.activeStep;
        const steppers = this.getSteppers();
        const marginTop = {marginTop: getSpacing()};
        //RPE MODAL INFO
        const RPE = s.RPEData;
        let headline, message, mainAlertStatus;
        if(activeStep === 0){
            const availableWorkouts = !isEmpty(s.availableWorkouts);
            if(deviceAccount){
                message = s.errorRequestingWorkout ? 'An error occurred requesting a workout' : 'A workout has yet to be pushed to this device';
                mainAlertStatus = s.errorRequestingWorkout ? 'error' : 'info';
                headline = s.errorRequestingWorkout  ? 'An Error Occurred' : 'No Workout Yet';
            } else{
                headline = availableWorkouts ? 'Workouts Available' : 'No Workouts Available';
                mainAlertStatus = availableWorkouts ? 'success' :  s.availableWorkoutRequestCompleted? 'info' : 'error';
                message = availableWorkouts ? 'Select a workout from the list below' : s.availableWorkoutRequestCompleted? 'No workouts are presently available.' : 'An error occurred when requesting available workouts.';
            }

        } else if(activeStep === 1){
            headline = 'Selected Workout Ready'
            message = 'Select your athletes for this device';
            mainAlertStatus = 'success';
        } else if(activeStep === 2){
            headline='Execute!'
            message="You're all set up! Complete your workout."
            mainAlertStatus = 'success';
        } else{
            headline='Finished'
            message='Workout completed'
            mainAlertStatus = 'success';
        }


        //REP INPUT MODAL SETTINGS
        const eu = new ExerciseUnits(s.exerciseUnits);
        let hasPreviousSet = false;
        let repModalInputLabel = 'Reps';
        let repMaskPattern = '999';
        let repPlaceholder = '5';

        let measurementModalInputLabel = 'Weight';
        let measurementMaskPattern = '999';
        let measurementPlaceholder = '100';

        //Set Default Validation
        let repsValid = this.validateWeightDataInput(s.dataPayload.reps, false);
        let weightValid = !this.validateWeightDataInput(s.dataPayload.weightValue, true);
        //Error Messages
        let repHelperText = repsValid ? '' : 'Enter Correct Rep Count';
        let weightHelperText = weightValid ? '' : 'Enter Correct Weight Amount';
        if(s.dataPayload.userID != null && parseInt(s.dataPayload.userID) > 0 && parseInt(s.dataPayload.setNumber) > 0){
            hasPreviousSet = true;
        }
        const applyPreviousMessage = hasPreviousSet ? 'Apply data from previous set' : 'No previous set to copy';

        if(s.dataPayload.exerciseID != null && parseInt(s.dataPayload.exerciseID) > 0){
            //console.log('MODAL PAYLOAD', s.dataPayload);
            //Measurement Units
            const modalMeasurementData = eu.getExerciseUnitData(s.dataPayload.measurementUnitID);
            measurementModalInputLabel = modalMeasurementData.activeWorkoutRepLabel;
            measurementMaskPattern = modalMeasurementData.maskPattern;
            measurementPlaceholder = modalMeasurementData.placeholderText;
            weightValid = eu.validateExerciseInput(s.dataPayload.weightValue, modalMeasurementData);
            weightHelperText = eu.getErrorMessage(weightValid, modalMeasurementData, true);

            //Rep Units
            const modalRepData = eu.getExerciseUnitData(s.dataPayload.repUnitID);
            repModalInputLabel = modalRepData.activeWorkoutRepLabel;
            repMaskPattern = modalRepData.maskPattern;
            repPlaceholder = modalRepData.placeholderText;
            repsValid = eu.validateExerciseInput(s.dataPayload.reps, modalRepData);
            repHelperText = eu.getErrorMessage(repsValid, modalRepData, true);

        }
        //Labels

        const submitLiftDisabled = repsValid === false || weightValid === false;

        //EachEnabled, RecordSingleWeight
        let eachEnabled, recordSingleWeight = false;
        if(!isEmpty(s.activeWorkout) && !isEmpty(s.activeWorkout.exercises)) {
            const selectedExerciseIndex = s.activeWorkout.exercises.findIndex(ex => parseInt(ex.exerciseID) === parseInt(s.dataPayload.exerciseID));
            if (selectedExerciseIndex > -1) {
                const selectedExercise = s.activeWorkout.exercises[selectedExerciseIndex];
                eachEnabled = selectedExercise.eachEnabled;
                recordSingleWeight = selectedExercise.recordSingleWeight;
            }
        }
        return (
            <div>
                <Typography variant={'h1'}>Active Workout</Typography>
                <Paper style={PAPER_STYLE}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Alert
                                visible={true}
                                status={mainAlertStatus}
                                headline={headline}
                                message={message}
                            >
                                {/*
                                <Button
                                    color={'secondary'}
                                    size={'large'}
                                    onClick={this.setStepBack.bind(this)}
                                    variant={'outlined'}
                                    disabled={s.activeStep <= s.minStep}
                                ><ArrowBack/> Back</Button>
                                */}


                            </Alert>
                        </Grid>
                        {/*
                        <Grid item xs={12}>

                            <Stepper activeStep={activeStep} orientation={'horizontal'} style={marginTop}>
                                {
                                    steppers.map((item, key)=>{
                                        return (
                                            <Step key={key}>
                                                <StepLabel>
                                                    {item.title}
                                                </StepLabel>
                                            </Step>
                                        );
                                    })
                                }
                            </Stepper>
                        </Grid>
                        <Grid item xs={12} align={'right'}>
                            <Button
                                color={'secondary'}
                                size={'large'}
                                onClick={this.setStepBack.bind(this)}
                                variant={'outlined'}
                                disabled={s.activeStep <= s.minStep}
                            ><ArrowBack/> Back</Button>
                        </Grid>
                        */}
                        <Grid item xs={12}>
                            {this.renderStepContent()}
                        </Grid>
                    </Grid>

                </Paper>
                {/********************* MODAL **************************/}
                <Modal
                    open={s.displaySubmissionModal}
                    title={'Log Your Set'}
                    closeFunction={this.closeLogWeightModal.bind(this)}
                    modalActions={
                        <Button
                        color="secondary"
                        size={'large'}
                        variant="contained"
                        onClick={this.closeLogWeightModal.bind(this)}
                        >Cancel</Button>
                    }
                >
                    <div style={{marginTop: getSpacing('small')}}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                readOnly={true}
                                label={'Name'}
                                disabled
                                value={s.displayAthleteName}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label={'Exercise'}
                                name={'textName'}
                                readOnly={true}
                                disabled
                                value={s.displayExerciseName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'body1'}>
                                The reps and resistance are initially predetermined. If you reduced (or increased) either, update the fields with the correct values.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Alert
                                visible={s.dataPayload.workoutMetricsID != null}
                                status={'info'}
                                headline={'Updating Previous Set'}
                                style={WBGStyles.alertStyle}
                                message={"You're updating a previously completed set."}
                            />
                            <Alert
                                visible={eachEnabled && recordSingleWeight}
                                style={WBGStyles.alertStyle}
                                status={'warning'}
                                headline={'Log The Individual Resistance'}
                                message={'Record your resistance for the individual arm/leg.'}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <InputMask
                                fullWidth={true}
                                size={'large'}
                                mask={repMaskPattern}
                                label={repModalInputLabel}
                                placeholder={repPlaceholder}
                                maskChar={''}
                                name={'reps'}
                                value={s.dataPayload.reps}
                                onChange={this.handleModalChange.bind(this)}
                                error={repsValid === false}
                                helperText={repHelperText || ' '}
                            >
                                {(props)=> {return <TextField {...props} inputProps={{inputMode: 'decimal'}}/>}}
                            </InputMask>
                        </Grid>
                        <Grid item xs={5}>
                            <InputMask
                                fullWidth={true}
                                size={'large'}
                                mask={measurementMaskPattern}
                                label={measurementModalInputLabel}
                                placeholder={measurementPlaceholder}
                                maskChar={''}
                                name={'weightValue'}
                                value={s.dataPayload.weightValue}
                                onChange={this.handleModalChange.bind(this)}
                                error={weightValid === false}
                                helperText={weightHelperText || measurementMaskPattern}
                            >
                                {(props)=> {return (
                                    <TextField
                                        {...props}
                                        autoFocus
                                        inputProps={{inputMode: 'decimal'}}
                                    />
                                    )
                                }}
                            </InputMask>
                        </Grid>
                        <Grid item xs={2}>
                            <Tooltip placement={'bottom'} title={applyPreviousMessage}>
                                <span>
                                  <Button
                                      fullWidth
                                      color={'secondary'}
                                      variant={'contained'}
                                      onClick={()=>this.copyPreviousSet(s.dataPayload.exerciseID, s.dataPayload.userID, s.dataPayload.setNumber)}
                                      style={BUTTON_INPUT_HEIGHT_MATCH}
                                      disabled={hasPreviousSet === false}
                                  >
                                    <Replay/>
                                    </Button>
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                            <Alert
                                status={'info'}
                                style={{marginBottom: getSpacing()}}
                                visible={s.dataPayload.copiedPrevious === true}
                                headline={'Copying Previous Set'}
                                message={"You're repeating both sets and reps from the previous set."}
                            />
                            <Button
                                color={'primary'}
                                fullWidth
                                size={'large'}
                                disabled={submitLiftDisabled}
                                variant={'contained'}
                                onClick={this.submitExerciseData.bind(this)}
                            >Log Work</Button>
                        </Grid>
                    </Grid>
                    </div>
                </Modal>
                <RPEModal
                    open={RPE.displayModal}
                    name={RPE.name}
                    lowRange={s.RPELowRange}
                    highRange={s.RPEHighRange}
                    score={RPE.score}
                    handleScoreFunction={this.handleRPEScore.bind(this)}
                    closeFunction={null}
                    submitFunction={this.submitRPEData.bind(this)}
                />
            </div>
        );
    }
}

export default ActiveWorkout;
