import React from 'react'
import {setPageTitle} from "fit/system/UtilityFunctions";
import {useState, useEffect} from "react";
import {Typography, Grid, Paper} from "@mui/material";
import {PAPER_STYLE} from "fit/system/FITConstants";


const Schedule =(props)=>{
    setPageTitle('Schedule');
    const p = props;
    const date = p.date != null ? `FOR ${p.date}` : '';
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h1'}>Schedule</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={PAPER_STYLE}>
                        <Typography variant={'h2'}>Game Plan</Typography>
                        <ul>
                            <li>Have a calendar view of workouts.</li>
                            <li>Have team selectors so coach can see schedule of a single team</li>
                            <li>Workouts can be color coded for errors/warnings (maybe intensity?)</li>
                            <li>IDEA: have sport specific coach input their travel/practice schedule<ul>
                                <li>All coaches are operating by shared schedule</li>
                                <li>Coaches can see volume/intensity for both strength and conditioning and sport specific training</li>
                                <li>Coaches are aware of travel/training schedules and cycles</li>
                                <li>Also implement organization/school schedule: Show holidays, finals, end of class, etc</li>
                            </ul>
                            </li>
                            <li>IDEA: Use team schedule calendar for scheduling workout blocks as well</li>
                        </ul>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )

}

export default Schedule;