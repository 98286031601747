import React, {Suspense, useState, useEffect} from "react";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";
import JumboApp from "@jumbo/components/JumboApp";
import AppLayout from "./AppLayout";
import JumboTheme from "@jumbo/components/JumboTheme";
import {history} from './redux/store';
import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import {SnackbarProvider} from "notistack";
import AppProvider from "./AppProvider";
import {config} from "./config/main";
import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import Div from "@jumbo/shared/Div";
import {CircularProgress} from "@mui/material";
import JumboAuthProvider from "@jumbo/components/JumboAuthProvider";
import ClientStore from "fit/system/ClientStore";
import User from "fit/system/User";
import {PERM_SET} from "fit/system/FITConstants";
import {isEmpty} from "fit/system/UtilityFunctions";
import FitAppRoutes from "./FitAppRoutes";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});


const App =()=>{
    useEffect(()=>{
        //Onload: determine if the user is already logged in. Forward them to the requested route
        //Or display the login/public facing forms
        let user = new User;
        user.persistClientState();
    })
    let user = new User;
    let layout = user.getLoggedInStatus() ? config.authorizedLayout : config.defaultLayout;
    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={ClientStore}>
                <BrowserRouter history={history}>
                    <AppProvider>
                        <JumboApp activeLayout={layout}>
                            <JumboTheme init={config.theme}>
                                <JumboRTL>
                                    <JumboDialogProvider>
                                        <JumboDialog/>
                                        <SnackbarProvider>
                                            <AppLayout>
                                                <Suspense
                                                    fallback={
                                                        <Div
                                                            sx={{
                                                                display: 'flex',
                                                                minWidth: 0,
                                                                alignItems: 'center',
                                                                alignContent: 'center',
                                                                height: '100%',
                                                            }}
                                                        >
                                                            <CircularProgress sx={{m: '-40px auto 0'}}/>
                                                        </Div>
                                                    }
                                                >
                                                <div id={'mainContent'}>
                                                    <FitAppRoutes/>
                                                </div>
                                                </Suspense>
                                            </AppLayout>
                                        </SnackbarProvider>
                                    </JumboDialogProvider>
                                </JumboRTL>
                            </JumboTheme>
                        </JumboApp>
                    </AppProvider>
                </BrowserRouter>
            </Provider>
        </QueryClientProvider>
    );
}

export default App;
