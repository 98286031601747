import React from 'react';
import Modal from 'fit/components/Dialogs/Modal';
import {Grid, Typography, Button, Slider} from '@mui/material';

const valuetext=(value)=>{
    return `${value}`;
}
class RPEModal extends React.Component{
    render(){
        const {name, lowRange, highRange, open, closeFunction, score} = this.props;
        return (
            <Modal
                open={open}
                title={'Rate of Perceived Exertion'}
                closeFunction={closeFunction}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant={'h2'}>
                            {name}, How Hard Was That?
                        </Typography>
                        <Typography variant={'body1'}>
                            On a scale from <strong>{lowRange}</strong> to <strong>{highRange}</strong> please rate how much you exerted yourself.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Slider
                            aria-label="Temperature"
                            defaultValue={lowRange}
                            getAriaValueText={valuetext}
                            valueLabelDisplay="auto"
                            step={1}
                            marks
                            min={lowRange}
                            max={highRange}
                            onChange={this.props.handleScoreFunction}
                            value={score}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth={true}
                            variant={'contained'}
                            color={'primary'}
                            onClick={this.props.submitFunction}
                        >Submit Feedback</Button>
                    </Grid>
                </Grid>
            </Modal>
        );
    }
}

export default RPEModal;