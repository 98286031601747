import React from 'react';
import {useCallback, useState, useEffect} from "react";
import {Navigate} from 'react-router-dom';
import {
    Paper,
    Grid,
    Typography,
    TextField,
    MenuItem,
    Button,
    InputAdornment,
    Tooltip,
    List,
    IconButton, Box, Tabs, Tab, LinearProgress, useTheme
} from "@mui/material";
import {FormGroup, FormControl, FormControlLabel, Checkbox, Collapse, Fade} from "@mui/material";
import {Accordion, AccordionSummary, AccordionDetails, Drawer} from "@mui/material";
import {getSpacing, getStatusColor, isEmpty, hashString, setPageTitle} from "fit/system/UtilityFunctions";
import InputMask from 'react-input-mask';
import ExerciseSelector from "fit/components/Exercise/ExerciseSelector";
import {connect} from 'react-redux';
import User from 'fit/system/User';
import moment from 'moment';
import {
    Add,
    DeleteForever,
    Cancel,
    Upload,
    ExpandMore,
    CheckCircle,
    Pending,
    ViewModule,
    ViewCarousel,
    Close,
    FileCopy, ArrowBack, ArrowForward, Toc, SwapVert
} from "@mui/icons-material";
import SecureConnect from "fit/system/SecureConnect";
import Alert from "fit/components/Alert";
import {
    PAPER_STYLE,
    COACH_LEVEL,
    ASSISTANT_COACH_LEVEL,
    MASS_KEY,
    PRODUCTION_STATUS
} from "fit/system/FITConstants";
import Modal from "../Dialogs/Modal";
import {BORDER_COLOR, WBGStyles} from './WBGStyles';
//Date Picker Stuff
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers";
import {FormHelperText} from "@mui/material";
import ExerciseCell from './ExerciseCell';
import ExerciseUnits from "fit/system/ExerciseUnits";
import BlockExerciseCell from "./BlockExerciseCell";

//Block Exercises View Drag and Drop Sorting
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';





import {Form, Formik} from "formik";
import * as yup from "yup";

const validationSchema = yup.object().shape({
    title: yup.string()
        .required('Block title required')
        .min('6','Title requires a minimum of 6 characters')
        .max('60','Title may have a maximum of 60 characters'),
    teamID: yup.number()
        .nullable()
        .min(1, 'Team required')
        .required('Team required'),
    dayValues: yup.array()
        .min(1, 'At Least One Day Must Be Selected'),
    startDate: yup.string()
        .required('Start date required'),
    endDate: yup.string()
        .required('End date required')
});



const blockHeader = {
    marginTop: getSpacing(),
    marginBottom: getSpacing('small')
}

//MOMENTJS DATE FORMAT
const dateFormat = 'YYYY-MM-DD';
const workoutBlockDaysLimit = 41; //Maximum number of days within a workout block (just shy of 6 weeks)

//Interval for autosaves
let autoSaveTimer;

//Exercise Index for managing Re-Renders of Exercise Cells
//This is some hacky shit
let exDex = {};
const checkExDex =(dayIndex, exerciseID, exercise)=>{
    const str = !isEmpty(exercise) ? hashString(JSON.stringify(exercise)) : '';
    let propsAreEqual;
    if(exDex[dayIndex] == null){
       //initialize day index if it doesn't exist
       exDex[dayIndex] = {};
    }
    if(exDex[dayIndex][exerciseID] == null){
        //Initializes with a blank value for a non existing exerciseID
        //Tell react not to update (propsAreEqual = true)
        exDex[dayIndex][exerciseID] = str;
        propsAreEqual = true;
    } else {
        //Exercise Exists. Determine if the exercise data is different from last render
        propsAreEqual = exDex[dayIndex][exerciseID] === str;
    }
    if(propsAreEqual === false){
        //Update with new hashed string
        exDex[dayIndex][exerciseID] = str;
    }
    return propsAreEqual;
}
const initExDex=(workoutBlock)=>{
    //Create the exerciseIndex when loading a workoutBlock
    workoutBlock.forEach(day =>{
        const {exercises, dayIndex} = day;
        exDex[dayIndex] = {};
        exercises.forEach(ex =>{
            const {exerciseID} = ex;
            exDex[dayIndex][exerciseID] = hashString(JSON.stringify(ex));
        });
    });
}

//Modal Settings
const initDeletionModal =()=>{
    return {
        display: false,
        day: '',
        exerciseName: '',
        dayValue: null,
        exerciseID: null
    }
}

//Exercise Setup Modal (For Adding/Editing Exercises for blocks)
const initExerciseModalSetup =()=>{
    return {
        display: false,
        active:  0,
        allowPercentage: false,
        deptTeamID: null,
        eachEnabled: false,
        exerciseID: null,
        applyToGroup: false,
        groupIndex: 0,
        exerciseStyle: '',
        exerciseStyleID: null,
        measurementUnitID: null,
        measurementTypeID: null,
        measurementUnits: '',
        muscleGroup: '',
        muscleGroupID: null,
        name: '',
        organizationID: null,
        recordSingleWeight: false,
        recordWeightDefault: true,
        shortName: '',
        unitType: ''
    }
}

const getDayTableStyle =(weekCount, zIndex = 5)=>{
    const columns = parseInt(weekCount) + 1;
    return {
        display: 'grid',
        gap: '0',
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        border: `1px solid ${BORDER_COLOR}`,
        justifyItems: 'center',
        marginBottom: 0,
        zIndex: zIndex,
    }
}

//Finally WBG
const NewWorkoutBlockGenerator =(props)=>{
    const [newWorkout, setNewWorkout] = useState(true);
    const [workoutBlockID, setWorkoutBlockID] = useState(null);
    const [userID, setUserID] = useState(null);
    const [title, setTitle] = useState('');
    const [teamID, setTeam] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [workoutBlock, setBlock] = useState([]);
    const [workoutBlockHash, __setWorkoutBlockHash] = useState(hashString(JSON.stringify(workoutBlock)));
    //const [exerciseList, setExerciseList] = useState([]);
    const [exerciseUnitData, setExerciseUnitData] = useState([]);
    //const [measurementTypes, setMeasurementTypes] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [dayValues, setDayValues] = useState([]);
    const [blockDayValues, setBlockDayValues] = useState([]); //blockDayValues are for the current block prior to changes/modifications
    const [activeDay, setActiveDay] = useState(null);
    const [dayTabValue, setDayTabValue] = useState(0); //For Block View, Tab Value (mon 0, wed 1, fri 2)
    const [activeView, setActiveView] = useState(0);
    const [RPERequired, setRPERequired] = useState(true);
    const [RPELowRange, setRPELowRange] = useState(1);
    const [RPEHighRange, setRPEHighRange] = useState(20);
    const [hasErrors, setHasErrors] = useState(false);
    const [hasWarnings, setHasWarnings] = useState(false);
    const [blockErrors, setBlockErrors] = useState([]);
    const [blockWarnings, setBlockWarnings] = useState([]);
    //For Changing tabs and dealing with delays of re-renders
    const [statusPending, pendingWrap] = useState(false);
    const [displayBlockDeletionConfirmation, setDisplayBlockDeletionConfirmation] = useState(false);
    const [displayFinalSaveChangesModal, setDisplayFinalSaveChangesModal] = useState(false);
    //Block Completed disables all form inputs - provides archived record for the block
    const [blockCompleted, setBlockCompleted] = useState(false);
    const [blockDeleted, setBlockDeleted] = useState(false);
    const [blockSelectedMethod, setBlockSelectedMethod] = useState('');
    //Store a new block here to confirm overwriting of existing block
    //Necessary when new date/day settings will remove current existing dates
    //Require confirmation from the coach
    const [newTempBlock, setNewTempBlock] = useState([]);
    //Completed DayValues will apply warnings if the coach tries to change workout days after workouts have been completed
    const [completedDayValues, setCompletedDayValues] = useState([]);
    const [displayTempBlockModal, setDisplayTempBlockModal] = useState(false);

    //Prompt for deleting an exercise from a specific block
    const [exerciseDeletionModalData, setExerciseDeletionModalData] = useState(initDeletionModal);

    //Exercise Selector Drawer
    const [exerciseSelectorOpen, setExerciseSelectorOpen] = useState(false);
    //Sets to Copy to another day/exerciseID
    const [copyDayIndex, setCopyDayIndex] = useState(null);
    const [copyExerciseID, setCopyExerciseID] = useState(null);
    const [setsCopy, setSetsCopy] = useState([]);
    const [displayCopyConfirmation, setDisplayCopyConfirmation] = useState(false);

    //Active days that get locked from completed workouts. Prevent coaches from removing workout days after workouts have been completed
    const [lockedActiveDays, setLockedActiveDays] = useState([]);
    //For saving/sending data to the server
    const [unsavedChangesExist, setUnSavedChangesExist] = useState(false);

    //Apply Exercise Modal
    const [applyExerciseModalData, setApplyExerciseModalData] = useState(initExerciseModalSetup);



    //ComponentDidMount
    useEffect(()=>{
        //No loaded workout
        //Set UserID to current User
        let blockMethod = '';
        let user = new User();
        const userID = user.getUserID();
        const {workoutBlockID, workoutID} = props;
        const workoutBlockIDSet = workoutBlockID != null;
        const workoutIDSet = workoutID != null;
        if(!workoutBlockIDSet && !workoutIDSet){
            //Set the UserID - New Workout Block. Not pulled from database
            setUserID(userID);
            setNewWorkout(true);
            setPageTitle('Create Workout Block');
        } else{
            pendingWrap(true);
        }
        let additionalParameters;
        if(workoutBlockIDSet){
            additionalParameters = `&method=workoutBlockID&workoutBlockID=${workoutBlockID}`;
            blockMethod = 'workoutBlockID';
        } else if(workoutIDSet){
            additionalParameters = `&method=workoutID&workoutID=${workoutID}`
            blockMethod = 'workoutID';
        } else{
            additionalParameters = '';
        }
        setBlockSelectedMethod(blockMethod);
        let sc = new SecureConnect(`testapi.php?action=getWorkoutBlock${additionalParameters}`);
        sc.setDisplaySuccessMessages(false);
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                const data = sc.getData(json);
                const {teams, blockData} = data;
                //Set Block Form Fields
                let teamList = [{teamID: 0, name: 'Select Team'}];
                Object.keys(teams).forEach(key=>{
                    const team = teams[key];
                    const teamName = `${team.orgShortName} ${team.label}`
                    teamList.push({teamID: teams[key].section_ID, name: teamName});
                })
                setTeamList(teamList);
                setExerciseUnitData(data.exerciseUnits);
                //Set Block Data
                processBlockData(blockData, blockMethod);
            }
        }) //End Loading of workout block
        //ComponentWillUnmount
        return ()=>{
            killAutoSave(autoSaveTimer);
            if(checkWorkoutBlockChanges()){
                //alert('DISPLAY PROMPT HERE FOR UNSAVED CHANGES');
                //setDisplayFinalSaveChangesModal(true);
            }
        }
    },[]);

    //View Change
    /*
    useEffect(()=>{
        setStatusPending(false);
    },[activeView, activeDay])
    */


    //Day Change
    const setWorkoutBlockHash=(hash)=>{
        __setWorkoutBlockHash(hash);
    }
    const processBlockData=(blockData, changeView = false)=> {
        //Set Block Data
        let workoutsCompleted = false;
        if (!isEmpty(blockData)) {
            if (parseInt(blockData.status) === 2) {
                setBlockCompleted(true);
            }
            const bd = blockData;
            const wb = blockData.blockJSON.workoutBlock;
            const serverDayValues = JSON.parse(bd.dayValues);
            let completedDayValues = [];
            if (wb.length > 0) {
                wb.forEach((workout, k) => {
                    if (workout.completed != null && parseInt(workout.completed) === 1) {
                        //Workout completed
                        workoutsCompleted = true;
                        if (!completedDayValues.includes(workout.dayValue)) {
                            completedDayValues.push(workout.dayValue);
                        }
                    }
                });
            }
            //Updating existing workout. Not new workout
            setNewWorkout(false);
            //Set Data fields
            setWorkoutBlockID(bd.workoutBlockID);
            setUserID(bd.userID);
            setTeam(bd.teamID);
            setCompletedDayValues(completedDayValues);
            setStartDate(moment(bd.startDate, dateFormat));
            setEndDate(moment(bd.endDate, dateFormat));
            setBlock([...wb]);
            initExDex(wb); //Initialize ExerciseIndex;
            setWorkoutBlockHash(hashString(JSON.stringify(wb))); //Set the workoutBlockHash for autosaving
            autoSave(); //Start autosave
            //Set the day values
            setDayValues(serverDayValues);
            setBlockDayValues(serverDayValues); //blockDayValues are for the current block prior to changes/modifications
            setTitle(bd.title);
            setRPEHighRange(bd.RPEHighRange);
            setRPELowRange(bd.RPELowRange);
            setHasErrors(Boolean(bd.error));
            setHasWarnings(Boolean(bd.warning));
            setBlockErrors(bd.blockJSON.errors);
            setBlockWarnings(bd.blockJSON.warnings);
            //Turn off progress
            pendingWrap(false);
            //Set the page title based on the block title
            setPageTitle(`Workout Block: ${bd.title}`);
            //Set the View
            const {workoutID, workoutBlockID} = props;
            const workoutBlockIDSet = workoutBlockID != null;
            const workoutIDSet = workoutID != null;
            if (workoutBlockIDSet) {
                //Set to first day value of workoutblock
                setActiveDay(serverDayValues[0]); //set to first day
                setActiveView(1); //set to block view
            } else if (workoutIDSet) {
                //Find the dayValue (Mon, Wed, Fri) that the workoutID is linked to
                const filteredWorkout = wb.filter(day => parseInt(day.workoutID) === parseInt(props.workoutID))[0];
                setActiveDay(filteredWorkout.dayValue);
                setActiveView(2); //set to workout view
            } else if(changeView === true){
                //Move to BlockView
                setActiveView(1);
            } else {
                //Default to first day
                setActiveDay(serverDayValues[0]);
            }
        }
    }
    const setStatusPending=(value)=>{
        pendingWrap(value);
    }
    const checkWorkoutBlockChanges=()=> {
        const currentBlockHash = hashString(JSON.stringify(workoutBlock));
        if (false){
            console.log('======================================================');
            console.log('CHANGES MADE ?', workoutBlockHash !== currentBlockHash);
            console.log('CURRENT CALCULATED WB HASH');
            console.log(currentBlockHash);
            console.log('--------------------------------------------------------------------------------------------');
            console.log('--------------------------------------------------------------------------------------------');
            console.log('--------------------------------------------------------------------------------------------');
            console.log('SAVED Hash');
            console.log(workoutBlockHash);
        }
        return workoutBlockHash !== currentBlockHash;
    }
    const killAutoSave=()=>{
        if(false) {
            console.log('=================KILLING AUTOSAVE=================');
            console.log('=================KILLING AUTOSAVE=================');
            console.log('=================KILLING AUTOSAVE=================');
            console.log('=================KILLING AUTOSAVE=================');
            console.log('=================KILLING AUTOSAVE=================');
            console.log('=================KILLING AUTOSAVE=================');
            console.log('=================KILLING AUTOSAVE=================');
        }
        clearInterval(autoSaveTimer);
    }
    const handleDayChange=(e)=>{
        const {checked} = e.currentTarget;
        const value = parseInt(e.currentTarget.value);
        const inArray = dayValues.includes(value);
        let updateDayValues = false;
        if(!checked && inArray){
            //Remove from dayValues List
            const index = dayValues.indexOf(value);
            if(index > -1){
                dayValues.splice(index, 1);
                updateDayValues = true;
            }
        }
        if(checked && !inArray){
            //Box is Checked, but not yet in array : Add to List
            dayValues.push(value);
            updateDayValues = true;
        }
        //Update State
        if(updateDayValues){
            setDayValues([...dayValues]);
        }
    }
    const handleViewChange=(value)=>{
        setStatusPending(false);
        submitWorkoutBlock(true);
        //setTimeout(()=>{setActiveView(value)},150);
        setActiveView(value);
    }
    const handleTitleChange=(e)=>{
        const value = e.target.value;
        setTitle(value);
    }
    const handleRPE=(e)=>{
        const {name, value} = e.target;
        setRPERequired(value);
    }
    const handleSelectChange=(e)=>{
        const {name, value} = e.target;
        if(name === 'teamID'){
            setTeam(value);
        }
    }
    const handleDateChange =(e, variableName)=>{
        //const dateValue = e == null ? '' : moment(e._d).format(dateFormat);
        if(variableName === 'startDate'){
            setStartDate(e); //dateValue
        } else{
            setEndDate(e); // dateValue
        }
    }
    const getDayTabIcon=(dayValue)=>{
        //Return an error/Warning/CheckCircle with color for dayValue
        //Show coaches where the errors are
        //Find the significant/greatest issue
    }
    const autoSave=()=>{
        return;
        const saveSeconds = 7;
        if(workoutBlockID != null && workoutBlockID !== '' && parseInt(workoutBlockID) > 0){
            //WorkoutBlock Set
            autoSaveTimer = setInterval(()=>submitWorkoutBlock(true),saveSeconds*1000);
        } else{
            killAutoSave();
        }
    }
    const submitWorkoutBlock=(automatic = false, initialDayValues = null, initialWorkoutBlock = null, updateFullBlockData = false)=>{
        /*
            Update when:
                -Building dates (initially creating the workout)
                -Changing between tabs (Block View/Setup)
                -Changing between tabs (Days (Mon, Wed, Friday)
                -Pressing the Button to save
                -Automatically saving every minute ?
                -Need a state status - block updated and not uploaded ("unsavedChangesExist") - true
                    -Gets set to true when changes made to workoutBlock
                    -Gets set to false when data sent to server successfully.
         */
        const hasChanges = checkWorkoutBlockChanges();
        const updatingBlock = workoutBlockID != null || parseInt(workoutBlockID) > 0;
        let submitBlock = automatic === false || (automatic && hasChanges);
        /*
        console.log('SUBMITTING BLOCK? ', submitBlock, '::', 'AUTO =', automatic, 'Has Changes =', hasChanges);
        console.log('STARDATE', startDate);
        console.log('ENDDATE', endDate);
        console.log('WORKOUTBLOCKID', workoutBlockID);
        console.log('-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-^-');
        */
        if(!submitBlock){
            //Die
            return;
        }
        const stringBlock = initialWorkoutBlock != null ? JSON.stringify(initialWorkoutBlock) : JSON.stringify(workoutBlock);
        let submittingDayValues;
        if(initialDayValues != null){
            //Initially submitting - state not updated yet. Use dayValues passed as parameter
            submittingDayValues = initialDayValues;
        } else if(updatingBlock){
            //Use the block day values rather than the form day values
            submittingDayValues = blockDayValues;
        } else{
            //Use form day values
            submittingDayValues = dayValues
        }
        //Set Form payload
        let payload = {
            title,
            teamID,
            startDate: moment(startDate._d).format(dateFormat),
            endDate: moment(endDate._d).format(dateFormat),
            dayValues: submittingDayValues,
            RPERequired: RPERequired ? 1:0,
            RPELowRange,
            RPEHighRange,
            workoutBlock: stringBlock
        }
        if(updatingBlock){
            payload.workoutBlockID = workoutBlockID;
        }
        //Connect to server
        let sc = new SecureConnect('testapi.php', 'post');
        sc.setAction('submitWorkoutBlock');
        sc.setFormData(payload);
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                //UPDATE
                const data = sc.getData(json);
                const blockHash = hashString(stringBlock);
                //Update the hash for the workout block. Used for autosaves
                setWorkoutBlockHash(blockHash);
                if(!isEmpty(data)) {
                    if (workoutBlockID == null || workoutBlockID === '') {
                        //Created new block. Set the block ID from the server
                        setWorkoutBlockID(data.workoutBlockID);
                    }
                    if(updateFullBlockData){
                        processBlockData(data.blockData, true);
                    } else {
                        const {hasErrors, hasWarnings, errors, warnings} = data.blockData;
                        setBlockErrors(errors);
                        setBlockWarnings(warnings);
                        setHasErrors(hasErrors);
                        setHasWarnings(hasWarnings);
                    }
                }
            } else {
                //Error occurred
                console.log('----------------------------------ERROR SUBMITTING----------------------------------');
                console.log(json);
                console.log('------------------------------------------------------------------------------------');
            }
        });
    }
    const deleteWorkoutBlock=()=>{
        let sc = new SecureConnect('testapi.php', 'post');
        sc.setFormData({workoutBlockID});
        sc.setAction('deleteWorkoutBlock');
        sc.connect().then(json=>{
           if(sc.getCompleted(json)){
               //Block Deleted
               //Kill the autosave
               killAutoSave();
               //const navigate = useNavigate();
               //navigate back to workout_blocks
               setBlockDeleted(true);
           }
        });
    }
    const setDay=(dayValue, dayTabValue)=>{
        submitWorkoutBlock(true);
        setDayTabValue(dayTabValue);
        setActiveDay(dayValue);
    }
    const buildDates =()=>{
        const formattedStartDate = moment(startDate, dateFormat);
        const formattedEndDate = moment(endDate, dateFormat);
        const difference = formattedEndDate.diff(formattedStartDate, 'days');
        let startFound = false;
        let startDayValue = null;
        let dayIndex = 0;
        let block = [];
        const blockExists = !isEmpty(workoutBlock) && workoutBlockID != null && workoutBlockID > 0;
        for(let k=0; k<=difference; k++){
            let newDate = formattedStartDate;
            const currentDayValue = parseInt(newDate.format('d'));
            const currentDate = newDate.format(dateFormat);

            //Determine if a workout exists on this date
            const workoutBlockIndex = workoutBlock.findIndex(workout=>workout.date === currentDate);
            const workoutExists = workoutBlockIndex > -1;
            const workoutComplete = workoutExists && parseInt(workoutBlock[workoutBlockIndex].completed) === 1;
            //The Day falls within the Selected dayValues set
            const dayValueWithinSet = dayValues.includes(currentDayValue);

            if(workoutComplete || dayValueWithinSet){
                //Found Workout Day
                const initWorkout = {
                    date: currentDate,
                    dayIndex: dayIndex,
                    dayValue: currentDayValue,
                    warmup: '',
                    exercises: []
                };
                //Check if the day already exists within the current workoutBlock
                if(workoutComplete) {
                    let setWorkout = {...workoutBlock[workoutBlockIndex], dayIndex};
                    block.push(setWorkout);
                } else {
                    //Init (Create New Workout)
                    block.push(initWorkout);
                }
                dayIndex++;
                //Starting Day - Move the interface to this day when complete
                startDayValue = startFound === false ? currentDayValue : startDayValue;
            }
            newDate.add(1, 'day');
        } // End Date Increment Loop

        let updateWorkoutBlock = false;
        let setTempBlock = false;
        if(blockExists){
            //Check if dates exist within new block
            for(let k=0; k<workoutBlock.length; k++){
                const workoutIndex = block.findIndex(workout =>workout.date === workoutBlock[k].date);
                if(workoutIndex < 0){
                    //Not Found
                    setTempBlock = true;
                    break;
                }
            }
            //Check If dates are missing (days were truncated)
            //Check if workoutDays are truncated
        }
        if(setTempBlock){
            //Confirm with the user before updating the workoutBlock
            setNewTempBlock(block);
            setDisplayTempBlockModal(true);
        }

        if(!blockExists || updateWorkoutBlock){
            //Set the First Day of the block
            const firstDay = getFirstDay(dayValues);
            setDay(firstDay, 0);
            //Set the workoutBlock
            setBlock([...block]);
            setBlockDayValues([...dayValues]);
        }
        //Submit the block to the server
        submitWorkoutBlock(false, dayValues, block, true);
    };
    const getFirstDay=(dayValues)=>{
        const min = dayValues.reduce((a, b) => Math.min(a, b));
        console.log('GETTING FIRST DAY', dayValues, min);
        return min;
    }
    const checkDaySelected =(workoutID)=>{
        //Determine if a workout ID has been passed and it matches the current day's workout ID
        //Used for highlighting a specific workout day
        //Return t/f
        return blockSelectedMethod === 'workoutID' && parseInt(props.workoutID) === parseInt(workoutID);
    }
    const handleTempBlock=(confirmUpdate)=>{
        if(confirmUpdate){
            setBlock([...newTempBlock]);
        } else{
            setNewTempBlock([...[]]);
        }
        setDisplayTempBlockModal(false);
    }
    const getDayLabel=(value)=>{
        const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
        return days[value];
    }
    //Workout Block Data
    const addExercise =(exerciseObject, useDefaultPercentageSetting = true)=>{
        if(isEmpty(workoutBlock)){
            //Die. Can't add to empty workout block
            return;
        }
        let exObj = structuredClone(exerciseObject);
        const daySelected = activeDay
        if(useDefaultPercentageSetting) {
            //Percentages only allowed for mass/weight exercises
            const percentageEnabled = exObj.allowPercentage && parseInt(exObj.measurementUnitTypeID) === MASS_KEY;
            exObj.percentageEnabled = percentageEnabled;
        }
        //Format stuff
        exObj.exerciseName = exObj.name;
        //Remove unnecessary data
        //Delete List
        const deleteList = ['name','active','deptTeamID','exerciseStyle','exerciseStyleID','muscleGroup','muscleGroupID', 'organizationID','shortName'];
        deleteList.forEach(item =>{
            delete(exObj[item]);
        });
        //Apply to the workout block
        workoutBlock.forEach((workoutDay, index) =>{
            if(workoutDay.dayValue === daySelected){
                const found = workoutBlock[index].exercises.findIndex(ex => ex.exerciseID === exObj.exerciseID);
                if(found > -1) {
                    //Die Already exists
                    return;
                }
                //Only add workout if it doesn't already exist withing the day
                workoutBlock[index].exercises.push({
                    ...exObj,
                    sets: [],
                    notes: ''
                });
            }
        });
        setBlock([...workoutBlock]);
    }
    const addSet = useCallback((dayIndex, exerciseID)=>{
        let selectedDayIndex = workoutBlock.findIndex(days => days.dayIndex === dayIndex);
        let updatedDay = {...workoutBlock[selectedDayIndex]};
        //Get index of exercise based on exerciseID
        let exerciseIndex = updatedDay.exercises.findIndex(exercise =>exercise.exerciseID === exerciseID);
        //Pull percentage settings
        const {allowPercentage, percentageEnabled} = updatedDay.exercises[exerciseIndex];
        let percentageValue = 50;
        let setCount = 1;
        let repCount = '';
        const hasSets = !isEmpty(updatedDay.exercises[exerciseIndex].sets);
        const setKey = hasSets ? updatedDay.exercises[exerciseIndex].sets.length : 0;
        //Do Previous sets exist? Get the last Set and apply a new set with the same data
        if(hasSets){
            const lastSetIndex = updatedDay.exercises[exerciseIndex].sets.length-1;
            const previousPercent = updatedDay.exercises[exerciseIndex].sets[lastSetIndex].percentage;
            percentageValue = allowPercentage && percentageEnabled && previousPercent != null ? previousPercent : 0;
            repCount = updatedDay.exercises[exerciseIndex].sets[lastSetIndex].reps;
            setCount = updatedDay.exercises[exerciseIndex].sets[lastSetIndex].setCount;
        }
        //Create the set
        const set = allowPercentage && percentageEnabled ? {setKey, setCount, reps: repCount, percentage: percentageValue} : {setKey, setCount, reps: repCount}
        updatedDay.exercises[exerciseIndex].sets.push(set);
        //Replace existing selectedDay with new data
        workoutBlock[selectedDayIndex] = updatedDay;
        /*
        console.log('-------------------ADDING SET-------------------');
        console.log('dayIndex',dayIndex, 'exID', exerciseID, 'SELECTED DAY INDEX', selectedDayIndex, 'EXERCISE INDEX', exerciseIndex);
        console.log('UPDATED DAY', updatedDay);
        console.log('APPLYING EXID', exerciseID, 'SET', set, 'DAYINDEX', dayIndex, 'SELECTED DAY:', selectedDayIndex);
        console.log('------------------/ADDING SET-------------------');
         */
        setBlock([...workoutBlock]);
    }, [workoutBlock]);
    const updateSet =(e, field, dayIndex, exerciseID, setIndex)=>{
        //console.log('UPDATING SET', 'DAY:',dayIndex, 'EXID', exerciseID, 'SETINDEX', setIndex, 'VALUE =', e.target.value);
        const maxLimit = 100;
        const minLimit = 1;
        const isPercent = field === 'percentage';
        let value = e.target.value; //.replaceAll(pattern, '');
        const validateInt = false;

        if(validateInt && value !== '') {
            value = isPercent ? parseFloat(value) : parseInt(value);
            value = value < minLimit ? minLimit : value;
            value = value > maxLimit ? maxLimit : value;
        }
        //Find the exerciseID
        const exerciseIndex = workoutBlock[dayIndex].exercises.findIndex(exercise => exercise.exerciseID === exerciseID);
        //Update the section
        workoutBlock[dayIndex].exercises[exerciseIndex].sets[setIndex][field] = value;
        //update state
        setBlock([...workoutBlock]);
    }
    const deleteSet =(dayIndex, exerciseID, setKey)=>{
        //Find the exerciseID
        const exerciseIndex = workoutBlock[dayIndex].exercises.findIndex(exercise => exercise.exerciseID === exerciseID);
        //Remove the set
        const setIndex = workoutBlock[dayIndex].exercises[exerciseIndex].sets.findIndex(set => set.setKey === setKey);
        workoutBlock[dayIndex].exercises[exerciseIndex].sets.splice(setIndex, 1);
        //Update state
        setBlock([...workoutBlock]);
    }
    const updateExerciseNote = (e, dayValue, exerciseID)=>{
        const notes = e.target.value;
        let updateState = false;
        workoutBlock.forEach((item, k) =>{
            if(item.dayValue === dayValue && !isEmpty(item.exercises)){
                const exerciseKey = item.exercises.findIndex(ex => ex.exerciseID === exerciseID);
                if(exerciseKey > -1){
                    workoutBlock[k].exercises[exerciseKey].notes = notes;
                    updateState = true;
                }
            }
        });
        if(updateState){
            setBlock([...workoutBlock]);
        }
    }
    const updateWarmupNote=(e, dayIndex)=>{
        workoutBlock[dayIndex].warmup = e.target.value;
        setBlock([...workoutBlock]);
    }
    const copySets=(dayIndex, exerciseID, setData)=>{
        const index = workoutBlock.findIndex(days => days.dayIndex === dayIndex);
        const exerciseIndex = workoutBlock[index].exercises.findIndex(ex => ex.exerciseID === exerciseID);
        if(workoutBlock[index].exercises[exerciseIndex].sets.length > 0 ){
            //Already exist - set prompt
            //Set setData
            setCopyDayIndex(dayIndex);
            setCopyExerciseID(exerciseID);
            setSetsCopy(setData);
            setDisplayCopyConfirmation(true);
        } else{
            confirmCopySets(dayIndex, exerciseID, true, setData);
        }
    }
    const confirmCopySets =(dayIndex, exerciseID, confirmation, setData = [])=>{
        if(confirmation === true){
            //Clear Data. Close Modal
            const index = workoutBlock.findIndex(days => days.dayIndex === dayIndex);
            const exerciseIndex = workoutBlock[index].exercises.findIndex(ex => ex.exerciseID === exerciseID);
            const dataSet = setData.length > 0 ? setData : setsCopy;
            workoutBlock[index].exercises[exerciseIndex].sets = structuredClone(dataSet);
            setBlock([...workoutBlock]);
        }
        setSetsCopy([]);
        setCopyExerciseID(null);
        setCopyDayIndex(null);
        setDisplayCopyConfirmation(false);
    }
    const getDayValueExerciseGroups=()=>{
        let exerciseGroupIndexes = [];
        let groupObj = {};
        const exercises = getAllDayValueExercises(activeDay);
        exercises.forEach(ex =>{
            const groupIndex = ex.groupIndex != null ? parseInt(ex.groupIndex) : null;
            if(groupIndex != null && groupIndex > 0){
                if(exerciseGroupIndexes.includes(groupIndex) === false) {
                    exerciseGroupIndexes.push(groupIndex);
                    groupObj[groupIndex] = [];
                }
                groupObj[groupIndex].push(ex.exerciseName);
            }

        });
        return {indexes: exerciseGroupIndexes, grouped: groupObj}
    }
    const renderDayTabs=()=>{
        return (
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={activeDayTab}
                        textColor={'primary'}
                        indicatoryColor={'primary'}
                        variant={'fullWidth'}
                    >
                        {//Create Workout Tabs specific to days
                            blockDayValues.map((day, dayTabValue) =>{
                                return <Tab value={dayTabValue} label={getDayLabel(day)} onClick={()=>setDay(day, dayTabValue)} key={dayTabValue}/>;
                            })
                        }
                    </Tabs>
                    {/*<LinearProgress/>*/}
                </Box>
            </Box>
        )
    }

    const deleteExercisePrompt =(dayValue, exerciseID)=>{
        //Delete an Exercise from the workout block.
        //Check if exercises exist. Create modal to confirm before deleting if exercises exist.
        const filtered = workoutBlock.filter(day => day.dayValue === dayValue);
        let hasData = false;
        let exerciseName = '';
        if(isEmpty(filtered)){
            return;
        }
        filtered.forEach(day =>{
            const exerciseFiltered = day.exercises.filter(exercise => exercise.exerciseID === exerciseID);
            if(!isEmpty(exerciseFiltered)){
                exerciseFiltered.forEach(exercise =>{
                    if(!isEmpty(exercise.sets) || exercise.notes !== ''){
                        hasData = true;
                        exerciseName = exercise.exerciseName;
                    }
                })
            }
        })
        if(hasData){
            //Sets found. Prompt the user to
            setExerciseDeletionModalData({
                display: true,
                exerciseName,
                day: getDayLabel(dayValue),
                dayValue: dayValue,
                exerciseID: exerciseID
            });
        } else{
           //No sets found. Delete the exercise
           deleteExercise(dayValue, exerciseID);
        }
    }
    const deleteExercise = (dayValue, exerciseID)=>{
       workoutBlock.forEach((day, dayKey) =>{
           if(day.dayValue === dayValue){
               const {exercises} = day;
               for(let k = 0; k<exercises.length; k++){
                   if(exercises[k].exerciseID === exerciseID){
                       //Delete the exercise
                       workoutBlock[dayKey].exercises.splice(k,1);
                       //Terminate this loop. Move onto the next day.
                       break;
                   }
               }
           }
       });
       //Update workout block
       setBlock([...workoutBlock]);
       if(exerciseDeletionModalData.display){
           //Modal is being displayed. Hide it.
           setExerciseDeletionModalData(initDeletionModal);
       }
    }

    const getDayHighlighter=(weekCount, color)=>{

        const length = 0;
        const radius = 5;
        const spread = 3;
        const inset = ''; // '' or 'inset'

        if(isEmpty(workoutBlock) || blockSelectedMethod !== 'workoutID' || activeView !== 2){
            return null;
        }

        //Calculate location of the highlight
        const filtered = workoutBlock.filter(day => parseInt(day.dayValue) === parseInt(activeDay));
        const workoutIndex = filtered.findIndex(day => parseInt(day.workoutID) === parseInt(props.workoutID));
        if(workoutIndex < 0){
            //Not among the selected days
            return null;
        }
        /*
        console.log('---------------------------------');
        console.log('WEEKCOUNT', weekCount);
        console.log('workoutCount', workoutCount);
        console.log('INDEX', workoutIndex);
        console.log('SELECTED', filtered[workoutIndex]);
        */
        const offset = workoutIndex+2; //CSS starts on 1, Also Skip the Exercise Label and notes column
        const shadowString = `${inset} ${length}px ${length}px ${radius}px ${spread}px ${color}`;
        let container = {
            ...getDayTableStyle(weekCount, 1),
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none'
        };
        const highlight = {
            gridColumnSpan: 1,
            gridColumnStart: offset,
            width: '100%',
            '-webkit-box-shadow': shadowString,
            boxShadow: shadowString,
        }

        if(true){
            return (
                <div style={container}>
                    <div style={highlight}><span></span></div>
                </div>
            )
        }

    }
    const getAllDayValueExercises=(currentDay = null)=>{
        //Return a set of exercise objects for all days with a specific day value
        //Required if exercises get added or removed from workouts midway through the cycle
        if(isEmpty(workoutBlock)){
            return [];
        }
        let exerciseList = [];
        let exerciseIDList = [];
        const dayFilter = currentDay == null ? parseInt(activeDay) : parseInt(currentDay);
        const filtered = workoutBlock.filter(day => parseInt(day.dayValue) === dayFilter);
        filtered.forEach(day =>{
            const {exercises} = day;
            if(!isEmpty(exercises)){
                exercises.forEach(ex =>{
                    const exerciseID = parseInt(ex.exerciseID);
                    if(exerciseIDList.includes(exerciseID) === false){
                        exerciseList.push(ex);
                        exerciseIDList.push(exerciseID);
                    }
                })
            }
        });
        return exerciseList;
    }

    /******************************************************************************************/
    /******************************RENDERING VARIABLES/CALCULATIONS****************************/
    /******************************************************************************************/
    const emptyBlock = isEmpty(workoutBlock);
    const previouslyCreated = workoutBlockID != null && !emptyBlock;
    const blockSetupButtonText = previouslyCreated ? 'Update Workout Block Schedule' :'Create Workout Block';
    //Main Button at the bottom
    const setupViewMainButtonFn = previouslyCreated === false ? buildDates : ()=>submitWorkoutBlock(false);
    const hasCompletedWorkouts = !isEmpty(completedDayValues);
    const completedWorkouts = workoutBlock.filter(day => parseInt(day.completed) === 1);

    //Setup Form Values
    let deselectedDayWarning = false;
    let missingDays = [];
    let missingDaysText = '';
    let missingDaysVerb = 'have';
    if(!isEmpty(completedDayValues)){
        completedDayValues.forEach(day =>{
           if(dayValues.includes(day) === false){
               deselectedDayWarning = true;
               missingDays.push(day);
           }
        });
        if(deselectedDayWarning && missingDays.length > 0){
            if(missingDays.length === 1){
                missingDaysText = getDayLabel(missingDays[0]);
                missingDaysVerb = 'has';
            } else if(missingDays.length === 2){
                missingDaysText = `${getDayLabel(missingDays[0])} and ${getDayLabel(missingDays[1])}`
            } else{
                for(let d = 0; d < missingDays.length-1; d++){
                    missingDaysText += `${getDayLabel(missingDays[d])}, `;
                }
                //FINAL
                missingDaysText += `and ${getDayLabel(missingDays[missingDays.length-1])}`;
            }
        }
    }
    //Date Limiters
    const dayValuesError = !emptyBlock && isEmpty(dayValues);
    const startDateSet = startDate != null && startDate !== '';
    let minStartDate, minEndDate, maxEndDate = null;
    const respectLeadingZeros = true;
    if(newWorkout){
        minStartDate = moment(moment().format(dateFormat));
        maxEndDate = startDateSet ? moment(startDate, dateFormat).add(workoutBlockDaysLimit,'days') : null;
        minEndDate = startDateSet ? moment(startDate, dateFormat) : minStartDate;
    } else{
        //Already Created
        if(hasCompletedWorkouts && completedWorkouts.length > 0){
            const earliestDate = completedWorkouts[0].date;
            minStartDate = moment(earliestDate);
            maxEndDate = moment(earliestDate).add(workoutBlockDaysLimit, 'days');
        }
    }

    //Styling Selected Dates
    const theme = useTheme();
    const selectedBorderColor = theme.palette.primary.main;

    const exerciseUnits = new ExerciseUnits(exerciseUnitData);
    const setIcon = emptyBlock ? <Pending/> : <CheckCircle/>;
    //Workout Block Setup Section
    let filteredDays = [];
    let dayGridStyle = WBGStyles.dayTableStyle;
    let dayOfWeekLabel = '';
    let blockExercises = [];
    let noExercises = false;
    let warmupMissing = false;
    let exerciseSetMissing = false;
    let exerciseCompleted = false;
    let dayHighlighter = null;
    if(emptyBlock === false) {
        filteredDays = workoutBlock.filter(day => day.dayValue === activeDay);
        dayGridStyle = getDayTableStyle(filteredDays.length);
        dayHighlighter = getDayHighlighter(filteredDays.length, selectedBorderColor);
        //console.log('ACTIVE DAY', activeDay, 'DAY VALUES', dayValues);
        dayOfWeekLabel = !isEmpty(filteredDays) ? moment(filteredDays[0].date, dateFormat).format('dddd') : '';
        const completedDayIndex = filteredDays.findIndex(day => parseInt(day.completed) === 1);
        exerciseCompleted = completedDayIndex > -1;
        const {exercises} = !isEmpty(filteredDays) ? filteredDays[0] : [];
        blockExercises = exercises;
        warmupMissing = !isEmpty(filteredDays.filter(day => day.warmup === ''));
        noExercises = isEmpty(exercises);
    }

    const removeExerciseButtonText = exerciseCompleted ? "Can't Remove: Exercise Completed" : 'Remove Exercise';
    const buttonActive = !isEmpty(dayValues) && parseInt(teamID) > 0 && title.length > 4 && startDate != null && endDate != null;
    //Setting the TabValue based on the dayValue
    let activeDayTab = 0;
    if(!isEmpty(blockDayValues)){
        activeDayTab = blockDayValues.indexOf(activeDay);
    }
    //Deleting Block Settings
    let user = new User();
    const asstCoach = user.checkTeamLevel(teamID, ASSISTANT_COACH_LEVEL, false);
    const headCoach = user.checkTeamLevel(teamID, COACH_LEVEL, false);
    const disableBlockDeletion = ! ((parseInt(userID) === parseInt(user.getUserID()) && asstCoach) || headCoach);
    const deletionButtonText = disableBlockDeletion === false ? 'Delete Block' : 'Delete Block (Disabled)';






    //New Block View
    const dayBlockGridStyle = getDayTableStyle(blockDayValues.length-1);


    /*
    console.log('_____________RENDERING_______________');
    console.log('BLOCK', workoutBlock);
    console.log('BE', blockExercises);
    //console.log('JSON', JSON.stringify(workoutBlock));
    //console.log('EXDEX', exDex);
    //console.log('______________________________________');
    */
    if(blockDeleted){
        return (
            <Navigate to={'/strength-and-conditioning/workout-blocks/'}/>
        );
    }
    return (
        <div>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant={'h1'}>Workout Block Generator</Typography>
            </Grid>
            <Grid item xs={12}>
                <Fade in={statusPending} unmountOnExit>
                    <LinearProgress color={'primary'}/>
                </Fade>
                <Paper style={PAPER_STYLE}>
                    <Tabs
                        value={activeView}
                        variant={'fullWidth'}
                    >
                        <Tab value={0} icon={setIcon} label={'Setup'} onClick={()=>handleViewChange(0)}/>
                        <Tab value={1} icon={<ViewModule/>} label={'Block Exercises'} onClick={()=>handleViewChange(1)} disabled={emptyBlock}/>
                        <Tab value={2} icon={<ViewCarousel/>} label={'Workout View'} onClick={()=>handleViewChange(2)} disabled={emptyBlock}/>
                        <Tab value={3} icon={<DeleteForever/>} label={deletionButtonText} onClick={()=>handleViewChange(3)} disabled={emptyBlock || disableBlockDeletion || blockCompleted}/>
                    </Tabs>
                    <Alert
                        status={'success'}
                        visible={blockCompleted}
                        style={WBGStyles.topAlertStyle}
                        headline={'Workout Block Completed'}
                        message={'This workout block has been completed. The Block cannot be edited further.'}
                    />
                    <Alert
                        status={'info'}
                        visible={hasCompletedWorkouts && !blockCompleted}
                        style={WBGStyles.topAlertStyle}
                        headline={'Some Form Elements Are Disabled'}
                        message={'Workouts have been completed for this block. Some form elements have been disabled to prevent corruption of data collection.'}
                    />
                    {
                        activeView === 0 &&
                        <div>

                            <Formik
                                initialValues={{title, teamID, dayValues, startDate, endDate}}
                                validationSchema={validationSchema}
                                onSubmit={setupViewMainButtonFn}
                                enableReinitialize={true}
                            >
                                {({ errors, touched}) => (
                                    <Form>

                            <Typography variant={'h2'} style={blockHeader}>Setup</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={'Workout Block Title'}
                                        name={'title'}
                                        value={title}
                                        disabled={blockCompleted}
                                        onChange={(e)=>handleTitleChange(e)}
                                        placeholder={'Workout Title'}
                                        error={errors.title && touched.title}
                                        helperText={errors.title || ' '}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        select
                                        label={'Team'}
                                        value={teamID}
                                        name={'teamID'}
                                        disabled={hasCompletedWorkouts || blockCompleted}
                                        onChange={(e)=>handleSelectChange(e)}
                                        error={errors.teamID && touched.teamID}
                                        helperText={errors.teamID || ' '}
                                    >
                                        {teamList.map((team,key) =>{
                                            return <MenuItem key={key} value={team.teamID}>{team.name}</MenuItem>
                                        })}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant={'h3'}>Workout Days</Typography>
                                    <FormControl
                                        error={errors.dayValues}
                                    >
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                label={'Sunday'}
                                                control={<Checkbox
                                                    value={0}
                                                    onChange={(e)=>handleDayChange(e)}
                                                    checked={dayValues.includes(0)}
                                                    disabled={blockCompleted || previouslyCreated}
                                                />}
                                                labelPlacement={'end'}
                                            />
                                            <FormControlLabel
                                                label={'Monday'}
                                                control={<Checkbox
                                                    value={1}
                                                    onChange={(e)=>handleDayChange(e)}
                                                    checked={dayValues.includes(1)}
                                                    disabled={blockCompleted || previouslyCreated}
                                                />}
                                                labelPlacement={'end'}
                                            />
                                            <FormControlLabel
                                                label={'Tuesday'}
                                                control={<Checkbox
                                                    value={2}
                                                    onChange={(e)=>handleDayChange(e)}
                                                    checked={dayValues.includes(2)}
                                                    disabled={blockCompleted || previouslyCreated}
                                                />}
                                                labelPlacement={'end'}
                                            />
                                            <FormControlLabel
                                                label={'Wednesday'}
                                                control={<Checkbox
                                                    value={3}
                                                    onChange={(e)=>handleDayChange(e)}
                                                    checked={dayValues.includes(3)}
                                                    disabled={blockCompleted || previouslyCreated}
                                                />}
                                                labelPlacement={'end'}
                                            />
                                            <FormControlLabel
                                                label={'Thursday'}
                                                control={<Checkbox
                                                    value={4}
                                                    onChange={(e)=>handleDayChange(e)}
                                                    checked={dayValues.includes(4)}
                                                    disabled={blockCompleted || previouslyCreated}
                                                />}
                                                labelPlacement={'end'}
                                            />
                                            <FormControlLabel
                                                label={'Friday'}
                                                control={<Checkbox
                                                    value={5}
                                                    onChange={(e)=>handleDayChange(e)}
                                                    checked={dayValues.includes(5)}
                                                    disabled={blockCompleted || previouslyCreated}
                                                />}
                                                labelPlacement={'end'}
                                            />
                                            <FormControlLabel
                                                label={'Saturday'}
                                                control={<Checkbox
                                                    value={6}
                                                    onChange={(e)=>handleDayChange(e)}
                                                    checked={dayValues.includes(6)}
                                                    disabled={blockCompleted || previouslyCreated}
                                                />}
                                                labelPlacement={'end'}
                                            />
                                        </FormGroup>
                                        <FormHelperText>{errors.dayValues || ' '}</FormHelperText>
                                    </FormControl>
                                    <Alert
                                        status={'error'}
                                        visible={dayValuesError}
                                        style={WBGStyles.alertStyle}
                                        headline={'Day Required'}
                                        message={'At least one day is required for updating the workout block'}
                                    />
                                    <Alert
                                        status={'warning'}
                                        visible={deselectedDayWarning}
                                        style={WBGStyles.alertStyle}
                                        headline={'Deselected Days Have Completed Workouts'}
                                        message={`${missingDaysText} ${missingDaysVerb} workouts that have already been completed. Any dates with completed workouts will remain within the system. Other workouts on these days will be removed.`}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant={'h3'}>Date Range</Typography>
                                    <Typography variant={'body1'}>Select the dates for how long the block will run</Typography>
                                    <FormControl
                                        error={errors.startDate}
                                    >
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                value={startDate}
                                                onChange={(e)=>handleDateChange(e, 'startDate')}
                                                disabled={blockCompleted || previouslyCreated}
                                                minDate={minStartDate}
                                                shouldRespectLeadingZeros={respectLeadingZeros}
                                                error={errors.startDate}
                                            />
                                        </LocalizationProvider>
                                        <FormHelperText>
                                            {
                                                errors.startDate || 'From Start Date'
                                            }
                                        </FormHelperText>
                                    </FormControl>
                                    <span> </span>
                                    <FormControl
                                        error={errors.endDate}
                                    >
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                value={endDate}
                                                onChange={(e)=>handleDateChange(e, 'endDate')}
                                                disabled={blockCompleted || previouslyCreated || startDateSet === false}
                                                minDate={minEndDate}
                                                maxDate={maxEndDate}
                                                shouldRespectLeadingZeros={respectLeadingZeros}
                                                error={errors.endDate}
                                            />
                                        </LocalizationProvider>
                                        <FormHelperText>
                                            {
                                                errors.endDate ||
                                                startDateSet ? 'To End Date' : 'Select Start Date First'
                                            }
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Alert
                                        status={RPERequired === true ? 'success' : 'warning'}
                                        visible={true}
                                        headline={RPERequired === true ? 'RPE Surveys Active' : 'RPE Surveys Disabled'}
                                        message={
                                            RPERequired === true ?
                                                'Rate of Perceived Exertion surveys (ranging 1-20) will be solicited at the end of all workouts.' :
                                                'Rate of Perceived Exertion surveys will not be displayed upon workout completion.'
                                        }
                                    >
                                        <Paper style={WBGStyles.paperSelectStyle}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        select
                                                        value={RPERequired}
                                                        name={'RPERequired'}
                                                        readOnly={hasCompletedWorkouts}
                                                        disabled={hasCompletedWorkouts || blockCompleted}
                                                        label={'Rate of Perceived Exertion Surveys'}
                                                        onChange={(e)=>handleRPE(e)}
                                                    >
                                                        <MenuItem value={true}>Turn ON Rate of Perceived Exertion (RPE) Surveys</MenuItem>
                                                        <MenuItem value={false}>Turn OFF Rate of Perceived Exertion (RPE) Surveys</MenuItem>
                                                    </TextField>
                                                </Grid>
                                                {/*
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        readOnly={true || hasCompletedWorkouts}
                                                        value={RPELowRange}
                                                        disabled={!RPERequired || hasCompletedWorkouts || blockCompleted}
                                                        label={'RPE Lowest Possible Value'}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        value={RPEHighRange}
                                                        readOnly={true|| hasCompletedWorkouts || hasCompletedWorkouts}
                                                        disabled={!RPERequired || hasCompletedWorkouts || blockCompleted}
                                                        name={'RPEHighRange'}
                                                        label={'RPE Highest Possible Value'}
                                                    />
                                                </Grid>
                                                */}
                                            </Grid>
                                        </Paper>
                                    </Alert>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        type={'submit'}
                                        variant={'contained'}
                                        color={'primary'}
                                    >
                                        {blockSetupButtonText}
                                    </Button>
                                </Grid>
                            </Grid>


                                    </Form>
                                )}
                            </Formik>

                        </div>
                    }
                    {
                        activeView === 1 &&
                        <div>
                            <Typography variant={'h2'} style={blockHeader}>{title}: Block Exercises</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {renderDayTabs()}
                                </Grid>


                                <Grid item xs={12}>
                                    <div style={dayBlockGridStyle}>
                                        {
                                            blockDayValues.map(day =>{
                                                let list = [];
                                                const exercises = getAllDayValueExercises(day)
                                                exercises.forEach((ex,key) =>{
                                                    console.log('BLOCK VIEW', ex.exerciseID, ex);
                                                    list.push(
                                                        <BlockExerciseCell
                                                            style={{border: '1px solid #f00'}}
                                                            key={key}
                                                            exercise={ex}
                                                            deleteFn={()=>deleteExercisePrompt(day, ex.exerciseID)}
                                                        />
                                                    );
                                                })
                                                return (
                                                    <List style={{width: '100%'}}>
                                                        {list}
                                                    </List>
                                                )
                                            })
                                        }


                                        <div style={WBGStyles.singleDayColumnStyle}>
                                            <ul style={WBGStyles.groupUnorderList}>
                                                <li style={WBGStyles.groupListItem}>
                                                    Monday
                                                </li>
                                            </ul>
                                        </div>
                                        <div style={WBGStyles.singleDayColumnStyle}>
                                            Weds
                                        </div>
                                        <div style={WBGStyles.singleDayColumnStyle}>
                                            Fri
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        size={'large'}
                                        color={'primary'}
                                        variant={'contained'}
                                        onClick={()=>submitWorkoutBlock(false)}
                                        disabled={checkWorkoutBlockChanges() === false}
                                    ><Upload/> Save Changes</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        size={'large'}
                                        color={'primary'}
                                        onClick={()=>setExerciseSelectorOpen(true)}
                                        disabled={blockCompleted}
                                        variant={'contained'}
                                    ><Add/> Add Exercise</Button>
                                </Grid>
                            </Grid>
                        </div>
                    }


                    {
                        activeView === 2 &&
                        <div>
                            <Typography variant={'h2'} style={blockHeader}>{title}: Workout View</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {renderDayTabs()}
                                </Grid>
                                <Grid item xs={12}>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMore/>}>
                                            <CheckCircle/> Workout Block Errors go here
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Alert
                                                status={'error'}
                                                style={WBGStyles.alertStyle}
                                                visible={noExercises}
                                                headline={'No Exercises Currently Assigned'}
                                                message={'A workout requires exercises. Click on the "Add Exercise" button to apply exercises to your workout block.'}
                                            />
                                            <Alert
                                                status={'error'}
                                                visible={warmupMissing}
                                                style={WBGStyles.alertStyle}
                                                headline={'Warmup Missing'}
                                                message={'A warmup is missing for one of your workouts'}
                                            />
                                            <Alert
                                                visible={false}
                                                status={'error'}
                                                style={WBGStyles.alertStyle}
                                                headline={'Percentage Error'}
                                                message={'You currently have a set with an invalid percentage. This will cause improper calculations for the athlete. Be sure to correct this error.'}
                                            />
                                            <Alert
                                                visible={exerciseSetMissing}
                                                status={'warning'}
                                                headline={'Exercise Missing Sets'}
                                                message={'One of your workouts has an exercise that is missing sets. This exercise will not be published and executed by the athlete unless you add a set.'}
                                            />
                                            <Alert
                                                visible={false}
                                                status={'warning'}
                                                style={WBGStyles.alertStyle}
                                                headline={'Rep Error'}
                                                message={'There is a set with 0 reps. Are you sure you don\'t want the athlete executing this set?'}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        size={'large'}
                                        color={'primary'}
                                        variant={'contained'}
                                        onClick={()=>submitWorkoutBlock(false)}
                                        disabled={checkWorkoutBlockChanges() === false}
                                    ><Upload/> Save Changes</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        size={'large'}
                                        color={'primary'}
                                        onClick={()=>setExerciseSelectorOpen(true)}
                                        disabled={blockCompleted}
                                        variant={'contained'}
                                    ><Add/> Add Exercise</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{position: 'relative'}}>
                                    <div style={dayGridStyle} key={activeDay}>
                                        <div style={WBGStyles.dayLabelStyle}>
                                            {dayOfWeekLabel}
                                        </div>
                                        {//Date Labels 08-21-2023 etc
                                            filteredDays.map(day => {
                                                return (
                                                    <div
                                                        key={day.dayIndex}
                                                        style={WBGStyles.dateLabelColumn}>
                                                        {moment(day.date).format('MM-DD-YYYY')}
                                                    </div>
                                                )
                                            })
                                        }
                                        <div style={WBGStyles.exerciseLabelColumnStyle}>
                                            <strong>Warmup</strong>
                                        </div>
                                        { // WARMUPS
                                            filteredDays.map(item =>{
                                                return (
                                                    <div style={WBGStyles.singleDayColumnStyle}  key={`warmup${item.dayIndex}`}>
                                                        <TextField
                                                            fullWidth
                                                            multiline
                                                            style={WBGStyles.liftLabelCell}
                                                            placeholder={'Enter Your Warmup Here'}
                                                            defaultValue={item.warmup}
                                                            disabled={blockCompleted || parseInt(item.completed) === 1}
                                                            onBlur={(e) => updateWarmupNote(e, item.dayIndex)}
                                                            rows={4}
                                                            error={item.warmup === ''}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }

                                        {//Exercise Label Cells with notes
                                            blockExercises.map(exercise => {
                                                //console.log('EXERCISE', exercise);
                                                const exerciseID = exercise.exerciseID;
                                                return (
                                                    <React.Fragment key={exerciseID}>
                                                        <div
                                                            style={WBGStyles.exerciseLabelColumnStyle}
                                                            key={exerciseID}
                                                        >
                                                            <strong>{exercise.exerciseName}</strong>
                                                            <TextField
                                                                fullWidth
                                                                multiline
                                                                style={WBGStyles.liftLabelCell}
                                                                placeholder={'Enter Notes Here'}
                                                                defaultValue={exercise.notes}
                                                                disabled={blockCompleted}
                                                                onBlur={(e) => updateExerciseNote(e, activeDay, exerciseID)}
                                                                rows={4}
                                                            />
                                                            {/*
                                                            <Tooltip title={removeExerciseButtonText} placement={'bottom'}>
                                                                <span>
                                                                <Button
                                                                    fullWidth
                                                                    style={WBGStyles.liftLabelCell}
                                                                    size={'small'}
                                                                    color={'secondary'}
                                                                    variant={'contained'}
                                                                    disabled={blockCompleted || exerciseCompleted}
                                                                    onClick={()=>deleteExercisePrompt(activeDay, exerciseID)}
                                                                ><DeleteForever/> Remove</Button>
                                                            </span>
                                                            </Tooltip>
                                                            */}
                                                        </div>
                                                        {//Exercise Cells (Sets/Reps/Percentages)
                                                            filteredDays.map((day, count) => {
                                                                const previousDayIndex = count > 0 ? filteredDays[count-1].dayIndex : null;
                                                                const futureDayIndex = filteredDays[count+1] != null ? filteredDays[count+1].dayIndex : null;
                                                                const {exercises, dayIndex} = day;
                                                                const exerciseIndex = exercises.findIndex(dayExercise => dayExercise.exerciseID === exerciseID);
                                                                const exercise = exerciseIndex > -1 ? exercises[exerciseIndex] : []; //EMPTY?
                                                                return (
                                                                    <ExerciseCell
                                                                        key={dayIndex}
                                                                        dayIndex={dayIndex}
                                                                        exercise={exercise}
                                                                        exerciseID={exercise.exerciseID}
                                                                        addSetFunction={addSet}
                                                                        updateSetFunction={updateSet}
                                                                        deleteSetFunction={deleteSet}
                                                                        copySetFunction={copySets}
                                                                        blockCompleted={blockCompleted}
                                                                        workoutCompleted={day.completed}

                                                                        errorColor={getStatusColor('error')}
                                                                        previousDayIndex={previousDayIndex}
                                                                        futureDayIndex={futureDayIndex}
                                                                        exerciseUnitClass={exerciseUnits}
                                                                        WBGStyles={WBGStyles}
                                                                        checkExDexFn={checkExDex}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                    {dayHighlighter}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    }

                    {
                        activeView === 3 &&
                        <div>
                            <Typography variant={'h2'} style={blockHeader}>Workouts</Typography>
                            <Typography variant={'body1'}>Provide a list/tabs down the left side showing all of the possible workouts. Clicking on them will provide a grid view similar to the workout block</Typography>
                        </div>
                    }
                    {
                        activeView === 4 &&
                        <div>
                            <Typography variant={'h2'} style={blockHeader}>Delete Workout Block</Typography>
                            <Alert
                                status={'warning'}
                                visible={true}
                                headline={"Delete This Workout Block"}
                                message={'Proceed with caution. Is this something you really want to do?'}
                            />
                            <Button
                                fullWidth
                                variant={'contained'}
                                size={'large'}
                                color={'secondary'}
                                style={{marginTop: getSpacing(), marginBottom: getSpacing()}}
                                onClick={()=>setDisplayBlockDeletionConfirmation(true)}
                            ><DeleteForever/>Delete This Workout Block and All of its workouts
                            </Button>
                            <Collapse in={displayBlockDeletionConfirmation}>
                                <Alert
                                    status={'error'}
                                    visible={true}
                                    headline={'DELETE THIS WORKOUT BLOCK'}
                                    message={'Last Chance. Are you Sure?'}
                                >
                                    <Button
                                        fullWidth
                                        color={'primary'}
                                        size={'large'}
                                        variant={'contained'}
                                        onClick={()=>deleteWorkoutBlock()}
                                    ><DeleteForever/>Yes. I'm Sure. Delete It.</Button>
                                </Alert>
                            </Collapse>
                        </div>
                    }
                </Paper>
            </Grid>
        </Grid>
{/*****************************************EXERCISE SELECTOR DRAWER****************************************/}
        <Drawer
            anchor={'right'}
            open={exerciseSelectorOpen}
            onClose={()=>setExerciseSelectorOpen(false)}
            ModalProps={{keepMounted: false}}
        >
            <div style={{...PAPER_STYLE, width: '400px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={11}>
                        <Typography variant={'h2'}>Exercise Selector</Typography>
                    </Grid>
                    <Grid item xs={1} align={'right'}>
                        <Tooltip title={'Close'} placement={'bottom'}>
                            <IconButton
                                color={'secondary'}
                                style={{position:'relative', bottom: getSpacing('iconAlignment')}}
                                onClick={()=>setExerciseSelectorOpen(false)}
                            ><Close/></IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <ExerciseSelector
                            disabled={blockCompleted || activeDay === false || activeDay === null}
                            addExerciseFn={addExercise}
                        />
                    </Grid>
                </Grid>
            </div>
        </Drawer>
{/*****************************************MODAL: DELETE EXERCISE****************************************/}
            <Modal
            open={exerciseDeletionModalData.display}
            title={'Are You Sure?'}
            modalActions={
                <React.Fragment>
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        onClick={()=>deleteExercise(exerciseDeletionModalData.dayValue, exerciseDeletionModalData.exerciseID)}
                    >Yes, Remove It.</Button>
                    <Button
                        color={'secondary'}
                        variant={'contained'}
                        onClick={()=>setExerciseDeletionModalData(initDeletionModal)}
                    >Cancel</Button>
                </React.Fragment>
            }
        >
            Are you sure you want to remove <strong>{exerciseDeletionModalData.exerciseName}</strong> from <strong>{exerciseDeletionModalData.day}'s</strong> block?
            <br/>This will <strong>DELETE ALL</strong> sets, reps, and notes associated with this exercise across all dates.
        </Modal>
{/*****************************************MODAL: CONFIRM COPY SETS****************************************/}
        <Modal
            open={displayCopyConfirmation}
            title={'Overwrite Existing Sets?'}
            modalActions={
                <React.Fragment>
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        onClick={()=>confirmCopySets(copyDayIndex, copyExerciseID, true)}
                    ><CheckCircle/> Copy Sets</Button>
                    <Button
                        color={'secondary'}
                        variant={'contained'}
                        onClick={()=>confirmCopySets(copyDayIndex, copyExerciseID, false)}
                    ><Cancel/> Cancel</Button>
                </React.Fragment>
            }
        >
            Sets already exist for that day/exercise. Overwrite them?
        </Modal>
{/*****************************************MODAL: SAVE PROMPT: CURRENTLY DISABLED****************************************/}
        <Modal
            open={displayFinalSaveChangesModal}
            title={'Save Changes?'}
            modalActions={
                <React.Fragment>
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        onClick={()=>alert('clicked')}
                    >Save Changes</Button>
                    <Button
                        color={'secondary'}
                        variant={'contained'}
                        onClick={()=>alert('ignore')}
                    >Ignore</Button>
                </React.Fragment>
            }
        >
            Changes have been made to the workout block. Do you want to save these changes before leaving?
        </Modal>
{/*****************************************MODAL: CHANGE DATES PROMPT: CURRENTLY DISABLED****************************************/}
        <Modal
            open={displayTempBlockModal}
            title={'Eliminate Existing Workouts'}
            modalActions={
                <React.Fragment>
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        onClick={()=>handleTempBlock(true)}
                    ><CheckCircle/> Save Changes</Button>
                    <Button
                        color={'secondary'}
                        variant={'contained'}
                        onClick={()=>handleTempBlock(false)}
                    ><Cancel/> Cancel</Button>
                </React.Fragment>
            }
        >
            <Alert
                status={'warning'}
                visible={true}
                headline={'Wait A Minute!'}
                message={'Workouts may be lost.'}
                style={WBGStyles.alertStyle}
            />
            Your new days and/or dates selections will eliminate existing workouts from your current workout block.
            <br/>Do you want to proceed with these changes and delete existing workouts?
        </Modal>
    </div>
    );
}

const mapStateToProps=(state)=>{
    return {FITUser: state.FITUser}
}

export default connect(mapStateToProps)(NewWorkoutBlockGenerator);